import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import OpportunityDetailHero from './sections/OpportunityDetailHero';
import OpportunityOverview from './sections/OpportunityOverview';
import ApplicationProcess from './sections/ApplicationProcess';
import MeetTheInsighters from './sections/MeetTheInsighters';
import RedesignFooter from '../common/RedesignFooter';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 15,
  },
});

const OpportunitiesListPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunityDetailHero />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid} style={{ marginTop: -60 }}>
        <OpportunityOverview />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <ApplicationProcess />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <MeetTheInsighters />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default OpportunitiesListPage;
