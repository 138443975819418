import React from 'react';
import { Grid } from '@material-ui/core';
import FooterRectangle from '../../../assets/redesignAssets/staticImages/FooterRectangle.png';
import useStyles from '../constants/redesignStyles';
import LogoWhite from '../../../assets/LogoWhite.png';
import FooterSocialLinks from './FooterSocialLinks';
import FooterLinks from './FooterLinks';
import HoverButton from './HoverButton';
import FooterEmailSubmission from './FooterEmailSubmission';

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${FooterRectangle})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '500px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        paddingBottom: '5%',
        maxWidth: 1400,
        margin: 'auto',
      }}
    >
      <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={LogoWhite} alt="Logo White" style={{ width: '50%', height: 'auto' }} />
          </Grid>
          <Grid item xs={3} style={{ marginTop: 15 }}>
            <FooterSocialLinks />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
            <HoverButton text="Got a Question?" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} style={{ paddingRight: '5%' }}>
        <FooterEmailSubmission />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: -40 }}>
        <div className={classes.hrLine} style={{ width: '70%' }} />
      </Grid>
      <Grid item xs={12} style={{ position: 'absolute', bottom: '100px', width: '70%' }}>
        <FooterLinks />
      </Grid>
    </Grid>
  );
};

export default Footer;
