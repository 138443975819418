import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import OpportunityScroller from '../../common/OpportunityScroller';
import { Opportunity } from '../../../opportunities/model';
import axios from '../../../utils/axios.utils';

const ExplorePaidOpportunitiesSection: React.FC = () => {
  const classes = useStyles();

  const [generalOpportunities, setGeneralOpportunities] = useState<Opportunity[]>([]);
  const [professionalOpportunities, setProfessionalOpportunities] = useState<Opportunity[]>([]);

  useEffect(() => {
    axios.get('insighter_opportunities.json').then((response) => {
      const opportunities = response?.data?.result;

      setGeneralOpportunities(opportunities.filter((opportunity) => opportunity.audience === 'General'));
      setProfessionalOpportunities(opportunities.filter((opportunity) => opportunity.audience === 'Professionals'));
    });
  }, []);

  return (
    <Grid
      container
      spacing={3}
      style={{ paddingLeft: '8%', paddingRight: '8%', display: 'flex', justifyContent: 'center' }}
    >
      <Grid item xs={11} md={6} style={{ paddingLeft: '10%' }}>
        <MediumOrnament text="Unlocking Potential" />
      </Grid>
      <Grid item xs={1} md={6} />
      <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography className={classes.h2}>Explore Paid Opportunities</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ display: 'flex', justifyContent: 'center', paddingLeft: '5%', paddingRight: '5%' }}
      >
        <Typography className={classes.smallText}>
          We connect you to paid opportunities where you can share your story, your experience, and your insights
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
        <OpportunityScroller hoverButtonText="General Opportunities" opportunities={generalOpportunities} />
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <OpportunityScroller hoverButtonText="Healthcare Professionals" opportunities={professionalOpportunities} />
      </Grid>
    </Grid>
  );
};

export default ExplorePaidOpportunitiesSection;
