import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SeparatorBar from '../assets/CaseStudyBodySeparatorBar.png';
import { CaseStudy } from '../model';

const styles = {
  heading: {
    fontSize: '20px',
    fontWeight: 'bold' as const,
    fontFamily: 'Crimson Text',
  },
  body: {
    fontSize: '12px',
    fontFamily: 'Crimson Text',
    wordWrap: 'break-word' as const,
  },
};

let CaseStudyBodySeparatorBar: React.FC = () => {
  return (
    <Grid item xs={12} style={{ marginBottom: 5 }}>
      <img src={SeparatorBar} alt="Separator Bar" style={{ width: '100%' }} />
    </Grid>
  );
};

let HeadingAndBodySection: React.FC<{ heading: string; body: string }> = ({ heading, body }) => {
  return (
    <Grid container style={{ marginBottom: 12 }}>
      <Grid item xs={12} style={{ marginBottom: '-10px' }}>
        <Typography style={styles.heading}>{heading}</Typography>
      </Grid>
      {heading && body && <CaseStudyBodySeparatorBar />}
      <Grid item xs={12}>
        <Typography style={styles.body}>
          {/* eslint-disable-next-line */}
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Typography>
      </Grid>
    </Grid>
  );
};

interface CaseStudyBodySectionProps {
  caseStudy: CaseStudy;
}

const CaseStudyBodySection: React.FC<CaseStudyBodySectionProps> = ({ caseStudy }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        {/* Start at index 2 for the second picture (rather than 1) because 0 is the thumbnail */}
        <img
          src={caseStudy?.case_study_images[2]?.s3_image_url}
          alt={caseStudy?.title}
          style={{ maxHeight: '400px', marginBottom: '40px' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingLeft: '10%',
          paddingRight: '10%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <HeadingAndBodySection heading={caseStudy?.heading1} body={caseStudy?.body1} />
        <HeadingAndBodySection heading={caseStudy?.heading2} body={caseStudy?.body2} />
        <HeadingAndBodySection heading={caseStudy?.heading3} body={caseStudy?.body3} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          paddingLeft: '10%',
          paddingRight: '10%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <HeadingAndBodySection heading={caseStudy?.heading4} body={caseStudy?.body4} />
        <HeadingAndBodySection heading={caseStudy?.heading5} body={caseStudy?.body5} />
        <HeadingAndBodySection heading={caseStudy?.heading6} body={caseStudy?.body6} />
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={caseStudy?.case_study_images[3]?.s3_image_url}
          alt={caseStudy?.title}
          style={{ maxHeight: '400px', marginBottom: '40px' }}
        />
      </Grid>
    </Grid>
  );
};

export default CaseStudyBodySection;
