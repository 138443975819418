import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Layout, Loading, Page, Toast } from '@shopify/polaris';
import { ProjectsContext } from '../contexts/ProjectsContext';
import ProjectForm from '../components/ProjectForm';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const EditProject: React.FunctionComponent = () => {
  const { projectId } = useParams<Record<string, string>>();
  const [projectFetched, setProjectFetched] = React.useState(false);

  const { project, fetchProject, fetchFormOptions, projectLoading } = React.useContext(ProjectsContext);

  React.useEffect(() => {
    if (!projectFetched) {
      fetchFormOptions();
      fetchProject(projectId);
      setProjectFetched(true);
    }
  }, [projectFetched, fetchProject, projectId, fetchFormOptions]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;
  const loadingMarkup = projectLoading ? <Loading /> : null;

  const statusBadge = () => {
    if (project?.status === 'draft') {
      return <Badge status="warning">Draft</Badge>;
    }
    if (project?.status === 'published') {
      return <Badge status="success">Published</Badge>;
    }
    return <Badge status="critical">Inactive</Badge>;
  };

  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page
        fullWidth
        title={project?.name}
        titleMetadata={statusBadge()}
        additionalMetadata={project.created_at ? `Created on ${project?.created_at}` : ''}
      >
        <Layout>
          <Layout.Section>
            <ProjectForm project={project} />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = projectLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default EditProject;
