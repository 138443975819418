import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TitleBackgroundImage from '../../../../assets/redesignAssets/aboutPage/TitleBackgroundImage.png';
import SectionOrnament from '../../common/SectionOrnament';
import useStyles from '../../constants/redesignStyles';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  heroTitleContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${TitleBackgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
  },
  heroTextWrapper: {
    marginLeft: '20%',
  },
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-4.25px',
    width: '100%',
    margin: 'auto 15% auto auto',
  },
  h1AccentLineOverride: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.1em',
    width: '100%',
    letterSpacing: '-2.25px',
    marginLeft: '5%',
    background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    position: 'relative',
    top: '5px',
  },
}));

interface BlogPostDetailHeroProps {
  blogPost: any;
}

const BlogPostDetailHero: React.FC<BlogPostDetailHeroProps> = (props) => {
  const { blogPost } = props;
  const classes = styleOverrides();

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
          }}
        >
          <div style={{ paddingLeft: '10%', paddingTop: '5%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: '50%',
              }}
            >
              <SectionOrnament text={blogPost?.author_name} centerText={false} />
            </div>
            <h1
              className={classes.h1Override}
              style={{ position: 'relative', color: '#545D78', letterSpacing: '-2px', maxWidth: 600 }}
            >
              {blogPost?.title}
            </h1>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <img src={blogPost?.s3_image_url} alt="Hero" style={{ width: 'auto', height: '500px' }} />
      </Grid>
    </Grid>
  );
};

export default BlogPostDetailHero;
