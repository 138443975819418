import React from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Button, Card } from '@shopify/polaris';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Project } from '../model';
import { Payment } from '../../payments/model';
import todaysDate from '../../utils/dateFunctions';
import { projectPaymentColumns } from '../constants/projectPaymentColumns';

interface ProjectPaymentsProps {
  project: Project;
  payments: Payment[];
}

const ProjectPayments: React.FC<ProjectPaymentsProps> = (props) => {
  const { payments, project } = props;
  const history = useHistory();

  return (
    <>
      <Card sectioned>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Payments</h2>
            </div>
            <div className="Polaris-Stack__Item">
              <div className="Polaris-ButtonGroup">
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  <Button primary url={`/admin/projects/${project.id}/payments/new`}>
                    New Payment
                  </Button>
                </div>
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  <CSVLink
                    data={project?.payment_csv_data}
                    filename={`Project-${project?.project_number}-Payments-${todaysDate()}.csv`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button primary>Download Payments</Button>
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          onRowClicked={(payment: Payment) => history.push(`/admin/payments/${payment.id}`)}
          columns={projectPaymentColumns}
          data={payments}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          noHeader
        />
        <div className="Polaris-Card__Section">
          <p>
            <strong>Insighter Honoraria Total:</strong> $ {project.insighter_payments_sum}
          </p>
          <p>
            <strong>Insighter Travel Total:</strong> $ {project.insighter_travel_sum}
          </p>
          <p>
            <strong>Insighter Other Total:</strong> $ {project.insighter_other_sum}
          </p>
          <p>
            <strong>Invitation Program Total:</strong> $ {project.invitation_program_sum}
          </p>
          <p>
            <strong>External Recruiter Total:</strong> $ {project.recruiter_payments_sum}
          </p>
          <p>
            <strong>Qualtrics Programming Total:</strong> $ {project.qualtrics_programming_sum}
          </p>
        </div>
      </Card>
    </>
  );
};

export default ProjectPayments;
