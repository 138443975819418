import '@shopify/polaris/dist/styles.css';
import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import ContextsProvider from './contexts';
import { UserContext } from './modules/auth/contexts/UserContext';
import Routes from './routes';
import { APPLICATION_URL, AUTH_ZERO_DOMAIN, AUTH_ZERO_CLIENT_ID, AUTH_ZERO_AUDIENCE } from './modules/utils/env';

const App: React.FC = () => {
  const { user } = React.useContext(UserContext);

  return (
    <AppProvider i18n={enTranslations}>
      <Auth0Provider
        domain={AUTH_ZERO_DOMAIN}
        clientId={AUTH_ZERO_CLIENT_ID}
        authorizationParams={{
          audience: AUTH_ZERO_AUDIENCE,
          redirect_uri: `${APPLICATION_URL}/auth0_redirect`,
        }}
      >
        <BrowserRouter forceRefresh>
          <ContextsProvider>
            <Routes key={user ? user.id : null} />
          </ContextsProvider>
        </BrowserRouter>
      </Auth0Provider>
    </AppProvider>
  );
};

export default App;
