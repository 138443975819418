import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import useStyles from '../constants/redesignStyles';
// src/assets/redesignAssets/staticImages/HorizontalSmear.png
import HorizontalSmear from '../../../assets/redesignAssets/staticImages/HorizontalSmear.png';

interface SectionOrnamentProps {
  text: string;
  centerComponent?: boolean;
  centerText?: boolean;
}

const SectionOrnament: React.FC<SectionOrnamentProps> = (props) => {
  const { text, centerComponent = true, centerText = false } = props;
  const classes = useStyles();

  // Should we include the full path here?

  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${HorizontalSmear})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '50px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Typography
        className={classes.sectionBlurbText}
        style={{ lineHeight: '45px', textAlign: centerText ? 'center' : 'left', position: 'initial' }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default SectionOrnament;
