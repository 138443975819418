/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Button } from '@shopify/polaris';
import { Grid } from '@mui/material';
import { PaymentFormValues } from '../model';
import PaymentForm from './PaymentForm';
import RecipientNameLine from './RecipientNameLine';
import { User } from '../../auth/model';

import axios from '../../utils/axios.utils';

type Props = Record<string, unknown>;

const BulkPaymentContainer: React.FC<Props> = (props) => {
  const { search } = useLocation();
  const history = useHistory();

  let projectId = new URLSearchParams(search).get('project_id') || '';
  let phaseId = new URLSearchParams(search).get('phase_id') || '';

  const [formValues, setFormValues] = useState<PaymentFormValues>({ project_id: projectId, phase_id: phaseId });
  const [formError, setFormError] = useState<string>('');
  const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
  const [disableCreateButton, setDisableCreateButton] = useState<boolean>(true);
  const [userIds, setUserIds] = useState<string>();
  const [users, setUsers] = useState<User[]>([]);

  const shouldDisableCreateButton = () => {
    if (!formValues?.category) return true;
    if (!formValues?.status) return true;
    return false;
  };

  const fetchUsers = async () => {
    const storedUserIds = sessionStorage.getItem('selectedUserIds');
    setUserIds(storedUserIds);
    axios.get(`/payments/bulk_new`, { params: { user_ids: storedUserIds } }).then((response) => {
      setUsers(response?.data?.users);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setDisableCreateButton(shouldDisableCreateButton());
  }, [formValues]);

  const handleCreate = async () => {
    try {
      setPaymentSubmitting(true);
      axios
        .post<string, any>(`/payments/bulk_create`, {
          user_ids: userIds,
          payment: { ...formValues },
        })
        .then(() => {
          history.push(`/admin/payments`);
        })
        .catch((error) => {
          setFormError(error.response.data.error);
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  return (
    <Grid container>
      {users.map((user) => (
        <Grid item xs={12} style={{ marginTop: -10 }} key={user.last_name}>
          <RecipientNameLine user={user} />
        </Grid>
      ))}
      <Grid container spacing={3} style={{ padding: '20px' }}>
        <Grid item xs={12}>
          <PaymentForm formValues={formValues} setFormValues={setFormValues} />
        </Grid>
        <Grid item xs={12}>
          {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
        </Grid>
        <Grid item xs={12} style={{ padding: '20px' }}>
          <Button primary onClick={handleCreate} loading={paymentSubmitting} disabled={disableCreateButton}>
            Create Payment
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BulkPaymentContainer;
