import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactQuill from 'react-quill';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { CaseStudy } from '../model';
import 'react-quill/dist/quill.snow.css';

const caseStudyFields = [
  {
    sectionTitle: 'Header Section',
    fields: [
      { label: 'Title', key: 'title' },
      { label: 'Subtitle', key: 'subtitle' },
      { label: 'Ornament Text', key: 'ornament_text' },
      { label: 'Thumbnail Description', key: 'thumbnail_description' },
      { label: 'Overview Text', key: 'overview_text', quill: true },
    ],
  },
  {
    sectionTitle: 'Right Content Section',
    fields: [
      { label: 'Heading 1', key: 'heading1' },
      { label: 'Body 1', key: 'body1', quill: true },
      { label: 'Heading 2', key: 'heading2' },
      { label: 'Body 2', key: 'body2', quill: true },
      { label: 'Heading 3', key: 'heading3' },
      { label: 'Body 3', key: 'body3', quill: true },
    ],
  },
  {
    sectionTitle: 'Left Content Section',
    fields: [
      { label: 'Heading 4', key: 'heading4' },
      { label: 'Body 4', key: 'body4', quill: true },
      { label: 'Heading 5', key: 'heading5' },
      { label: 'Body 5', key: 'body5', quill: true },
      { label: 'Heading 6', key: 'heading6' },
      { label: 'Body 6', key: 'body6', quill: true },
    ],
  },
  {
    sectionTitle: 'Conclusion Section',
    fields: [{ label: 'Conclusion', key: 'conclusion_text', quill: true }],
  },
];

interface CaseStudyTextFormProps {
  formValues: CaseStudy;
  setFormValues: Dispatch<SetStateAction<CaseStudy>>;
  formErrors: string;
  setImageFileData: Dispatch<SetStateAction<FormData>>;
}

const CaseStudyTextForm: React.FC<CaseStudyTextFormProps> = (props) => {
  const { formValues, setFormValues, formErrors } = props;

  useEffect(() => {
    if (formValues?.ornament_text === undefined) {
      setFormValues({ ...formValues, ornament_text: 'Your Insights in Action' });
    }
  }, [formValues]);

  return (
    <Grid container style={{ padding: '2%' }}>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Card style={{ padding: 15 }}>
          <Grid container style={{ width: '50%' }}>
            <Grid item xs={12}>
              <SelectInput
                label="Status"
                choices={[
                  { label: 'Active', value: 'active' },
                  { label: 'Inactive', value: 'inactive' },
                ]}
                value={formValues?.status}
                onChange={(value) => setFormValues({ ...formValues, status: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label="Featured"
                choices={[
                  { label: 'Featured', value: 'true' },
                  { label: 'Not Featured', value: 'false' },
                ]}
                value={formValues?.featured}
                onChange={(value) => setFormValues({ ...formValues, featured: value })}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {caseStudyFields.map((section) => (
        <Grid item xs={12} key={section.sectionTitle} style={{ marginBottom: 15 }}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h1 className="Polaris-Header-Title">{section.sectionTitle}</h1>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {section.fields.map((field) => (
                  <Grid item xs={12} key={field.label} style={{ marginBottom: 5, width: '100%' }}>
                    {field.quill ? (
                      <>
                        <Typography variant="h6">{field.label}</Typography>
                        <ReactQuill
                          theme="snow"
                          value={formValues?.[field.key]}
                          onChange={(value) => setFormValues({ ...formValues, [field.key]: value })}
                        />
                      </>
                    ) : (
                      <FormInput
                        label={field.label}
                        value={formValues?.[field.key]}
                        onChange={(value) => setFormValues({ ...formValues, [field.key]: value })}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};

export default CaseStudyTextForm;
