import React from 'react';
import { Button, Card, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import { AddNewsletterFormValues } from '../model';
import { NewslettersContext } from '../contexts/NewslettersContext';

const AddNewsletterForm: React.FunctionComponent = () => {
  const { createNewsletter, newsletterSubmitting } = React.useContext(NewslettersContext);
  const [formValues, setFormValues] = React.useState<AddNewsletterFormValues>({});

  React.useEffect(() => {
    setFormValues({
      name: '',
      url: '',
      image_url: '',
      sent_date: '',
    });
  }, [setFormValues]);

  const handleCreateNewsletter = () => {
    createNewsletter(formValues);
  };

  return (
    <FormLayout>
      <Card sectioned title="Add Newsletter">
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <FormInput
              label="Name"
              value={formValues?.name}
              onChange={(value) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
          <Grid item xs={8}>
            <FormInput
              label="URL"
              value={formValues?.url}
              onChange={(value) => setFormValues({ ...formValues, url: value })}
            />
          </Grid>
          <Grid item xs={8}>
            <FormInput
              label="Image URL"
              value={formValues?.image_url}
              onChange={(value) => setFormValues({ ...formValues, image_url: value })}
            />
          </Grid>
          <Grid item xs={8}>
            <FormInput
              type="date"
              label="Date Sent"
              value={formValues?.sent_date}
              onChange={(value) => setFormValues({ ...formValues, sent_date: value })}
            />
          </Grid>
        </Grid>
      </Card>
      <Button primary onClick={handleCreateNewsletter} loading={newsletterSubmitting}>
        Add Newsletter
      </Button>
    </FormLayout>
  );
};

export default AddNewsletterForm;
