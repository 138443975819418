import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { InternalUser, InternalUserFormValues } from '../model';

interface InternalUsersContextInterface {
  fetchInternalUsers?: (params?: string) => Promise<void>;
  internalUsers?: InternalUser[];
  fetchInternalUser?: (internal_user_id: string) => Promise<void>;
  internalUser?: InternalUser;
  internalUserLoading?: boolean;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, any[]>;
  updateInternalUser?: (internal_user_id: string, formValues: InternalUserFormValues) => Promise<void>;
  completeInternalUser?: (internal_user_id: string, formValues: any) => Promise<void>;
  createInternalUser?: (formValues: InternalUserFormValues, onSuccess?: () => void) => Promise<void>;
  formError?: string;
  internalUserSubmitting?: boolean;
}

const InternalUsersContext = React.createContext<InternalUsersContextInterface>({});

const InternalUsersContextConsumer = InternalUsersContext.Consumer;
const InternalUsersContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [internalUsers, setInternalUsers] = useState<InternalUser[]>([]);
  const [internalUserLoading, setInternalUserLoading] = useState<boolean>(false);
  const [formOptions, setFormOptions] = useState<Record<string, any[]>>({});
  const [formError, setFormError] = useState<string>(undefined);
  const [internalUserSubmitting, setInternalUserSubmitting] = useState<boolean>(false);
  const [internalUser, setInternalUser] = useState<InternalUser>({});

  const fetchInternalUsers = async (params = '') => {
    setInternalUserLoading(true);
    const response = await axios.get<string, any>(`internal_users?${params}`);

    setInternalUserLoading(false);
    setInternalUsers(response?.data?.result);
  };

  const fetchInternalUser = async (internal_user_id: string) => {
    setInternalUserLoading(true);
    const response = await axios.get<string, any>(`internal_users/${internal_user_id}`);

    setInternalUserLoading(false);
    setInternalUser(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`internal_users/new`);

    setFormOptions(response?.data);
  };

  const updateInternalUser = async (internal_user_id: string, formValues: InternalUserFormValues) => {
    setInternalUserSubmitting(true);

    await axios.put<string, any>(`internal_users/${internal_user_id}`, {
      user: { ...formValues },
    });

    setInternalUserSubmitting(false);
    history.push(`/admin/users`);
  };

  const createInternalUser = async (formValues: InternalUserFormValues) => {
    try {
      setInternalUserSubmitting(true);
      axios
        .post<string, any>(`internal_users`, {
          user: { ...formValues },
        })
        .then(() => {
          history.push(`/admin/users`);
        })
        .catch((error) => {
          setInternalUserSubmitting(false);
          setFormError(error.response.data.message);
        });
    } catch {
      setInternalUserSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  return (
    <InternalUsersContext.Provider
      value={{
        fetchInternalUsers,
        internalUsers,
        fetchInternalUser,
        internalUser,
        internalUserLoading,

        fetchFormOptions,
        formOptions,

        updateInternalUser,
        createInternalUser,
        formError,
        internalUserSubmitting,
      }}
    >
      {children}
    </InternalUsersContext.Provider>
  );
};

export { InternalUsersContextProvider, InternalUsersContextConsumer, InternalUsersContext };
