import React, { useState, useEffect, useContext } from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import { Grid, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import ButtonWrapper from '../../form/components/ButtonWrapper';
import { Opportunity } from '../model';
import FormInput from '../../form/components/FormInput';
import { InsighterOpportunitiesContext } from '../contexts/InsighterOpportunitiesContext';
import { InvitationOpportunitiesContext } from '../../invitationOpportunities/contexts/InvitationOpportunitiesContext';
import siteWideStyles from '../../styles/siteWideStyles';

interface InvitationOpportunityCardProps {
  opportunity: Opportunity;
}

const InvitationOpportunityCard: React.FC<InvitationOpportunityCardProps> = (props) => {
  const { opportunity } = props;
  const { registerUser, formErrors } = useContext(InsighterOpportunitiesContext);
  const { fetchInvitationOpportunity, invitationOpportunity } = useContext(InvitationOpportunitiesContext);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const [urlParamsUpdated, setUrlParamsUpdated] = useState(false);

  const { search } = useLocation();
  const invitationCode = new URLSearchParams(search).get('invitation_code');

  useEffect(() => {
    if (!urlParamsUpdated) {
      setUrlParamsUpdated(true);

      if (invitationCode?.match(/[A-Z2-9]{10}/) && invitationCode?.length === 10) {
        setFormValues({ ...formValues, invitation_code: invitationCode, invitation_url: window.location.href });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamsUpdated, invitationCode]);

  useEffect(() => {
    if (opportunity.id.toString() === '39') {
      fetchInvitationOpportunity('13');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity]);

  const handleApply = (formValues) => {
    registerUser(formValues, () =>
      window.location.replace(
        `${opportunity.screener_url}?invitation=true&${invitationOpportunity.custom_query_parameter}`,
      ),
    );
  };

  return (
    <div className="Polaris-Card">
      <Modal open={openDetailsModal} onClose={() => setOpenDetailsModal(false)} title={opportunity.name}>
        <Modal.Section>
          <TextContainer>
            <p style={{ whiteSpace: 'break-spaces' }}>{opportunity.learn_more}</p>
          </TextContainer>
        </Modal.Section>
        <hr style={{ height: 1, backgroundColor: '#ddd', border: 'none' }} />
        <Grid container spacing={1} justify="flex-end" style={{ paddingRight: 15, paddingBottom: 10 }}>
          <Grid item>
            <ButtonWrapper onClick={() => setOpenDetailsModal(false)} label="Close" />
          </Grid>
        </Grid>
      </Modal>
      <div className="Polaris-MediaCard">
        <div className="Polaris-MediaCard__MediaContainer" style={{ display: 'flex', alignItems: 'center' }}>
          <img
            alt=""
            width="100%"
            height="auto"
            src={opportunity?.s3_image_url}
            style={{ objectFit: 'cover', objectPosition: 'center center' }}
          />
        </div>
        <div className="Polaris-MediaCard__InfoContainer" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="Polaris-Card__Section" style={{ paddingBottom: '0' }}>
            <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingTight">
              <div className="Polaris-Stack__Item">
                <div className="Polaris-MediaCard__Heading">
                  <h2 className="Polaris-Heading" style={{ fontSize: 24 }}>
                    {opportunity?.name}
                  </h2>
                </div>
              </div>
              <br />
              <div className="Polaris-Stack__Item">
                <p style={{ whiteSpace: 'break-spaces' }}>{opportunity?.description}</p>
              </div>
              <div className="Polaris-Stack__Item">
                <span className="Polaris-Navigation__Indicator">
                  <span className="Polaris-Indicator Polaris-Indicator--pulseIndicator" />
                </span>
                <p style={{ display: 'inline-block', marginLeft: 10, position: 'relative', top: -4, fontWeight: 600 }}>
                  {opportunity.status === 'prescreen' ? 'Pre-Apply Now' : 'Apply Now'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Grid container justifyContent="center">
          <Grid item md={10} xs={10}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <FormInput
                  type="text"
                  label="First Name"
                  value={formValues.first_name}
                  onChange={(value) => setFormValues({ ...formValues, first_name: value })}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormInput
                  type="text"
                  label="Last Name"
                  value={formValues.last_name}
                  onChange={(value) => setFormValues({ ...formValues, last_name: value })}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormInput
                  type="email"
                  label="Email"
                  value={formValues.email}
                  onChange={(value) => setFormValues({ ...formValues, email: value })}
                />
              </Grid>
              {formValues.invitation_code && (
                <Grid item xs={12}>
                  <FormInput type="text" label="Invitation Code" value={formValues.invitation_code} disabled />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} style={{ padding: '6px 16px' }}>
                <p style={{ opacity: 0.7, fontSize: '11px' }}>
                  By clicking Apply, you agree to our{' '}
                  <a target="_blank" href="/terms-of-use">
                    Terms of Use
                  </a>
                  ,{' '}
                  <a target="_blank" href="/pay-portal-terms">
                    Pay Portal Terms
                  </a>
                  ,{' '}
                  <a target="_blank" href="/privacy-policy">
                    Privacy Policy
                  </a>
                  , and{' '}
                  <a target="_blank" href="/cookie-policy">
                    Cookie Policy.
                  </a>
                </p>
              </Grid>
              <Grid item style={{ marginBottom: '20px' }}>
                <p style={{ color: 'red' }}>{formErrors?.base}</p>
                <Button
                  variant="contained"
                  style={siteWideStyles.authButton}
                  type="button"
                  onClick={() => handleApply(formValues)}
                >
                  Start Application
                </Button>
                &emsp;
                {opportunity?.learn_more && (
                  <Button
                    variant="contained"
                    style={siteWideStyles.secondaryButton}
                    type="button"
                    onClick={() => setOpenDetailsModal(true)}
                  >
                    Learn More
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default InvitationOpportunityCard;
