import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import InsighterRoleForm from '../components/InsighterRoleForm';
import Page from '../../shared/components/Page';
import { InsighterRolesContext } from '../contexts/InsighterRolesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const EditInsighterRole: React.FunctionComponent = () => {
  const { insighterRoleId } = useParams<Record<string, string>>();
  const [insighterRoleFetched, setInsighterRoleFetched] = React.useState<boolean>(false);
  const { insighterRole, fetchInsighterRole, insighterRolesLoading } = React.useContext(InsighterRolesContext);

  React.useEffect(() => {
    if (!insighterRoleFetched) {
      fetchInsighterRole(insighterRoleId);
      setInsighterRoleFetched(true);
    }
  }, [insighterRoleFetched, fetchInsighterRole, insighterRoleId]);

  const loadingMarkup = insighterRolesLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title={`${insighterRole?.name} `}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InsighterRoleForm insighterRole={insighterRole} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = insighterRolesLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditInsighterRole;
