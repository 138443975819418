import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { HomepageResource } from '../model';
import HomepageResourceViewer from './HomepageResourceViewer';

interface HomepageResourcesGalleryProps {
  homepageResources: HomepageResource[];
}

const HomepageResourcesGallery: React.FC<HomepageResourcesGalleryProps> = (props) => {
  const { homepageResources } = props;

  return (
    <Grid container style={{ paddingLeft: '12%', paddingRight: '12%', minHeight: '400px' }}>
      {homepageResources?.map((homepageResource) => (
        <Grid item xs={6} key={homepageResource?.id} style={{ marginBottom: 10 }}>
          <HomepageResourceViewer homepageResource={homepageResource} />
        </Grid>
      ))}
      {homepageResources?.length === 0 && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
          <Typography variant="h4">
            We couldn&apos;t find any resources with that search term. Please clear the search field or try a different
            term.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default HomepageResourcesGallery;
