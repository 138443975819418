import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import useStyles from '../constants/redesignStyles';
import SmearMedium from '../../../assets/redesignAssets/staticImages/SmearMedium.png';

interface MediumOrnamentProps {
  text: string;
  centerComponent?: boolean;
  centerText?: boolean;
}

const MediumOrnament: React.FC<MediumOrnamentProps> = (props) => {
  const { text, centerComponent = true, centerText = false } = props;
  const classes = useStyles();

  return (
    <Grid container style={{ justifyContent: centerComponent ? 'center' : 'flex-start' }}>
      <Grid
        item
        style={{
          backgroundImage: `url(${SmearMedium})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '50px',
          width: '200px',
          justifyContent: centerComponent ? 'center' : 'flex-start',
          alignItems: 'center',
          position: 'relative',
          padding: '17px 8px',
        }}
      >
        <Typography
          className={classes.sectionBlurbText}
          style={{ textAlign: centerText ? 'center' : 'left', position: 'initial' }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MediumOrnament;
