import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ExampleOpportunity from '../assets/ExampleOpportunity.png';

interface OpportunitiesGalleryProps {
  redesignOpportunities: any[];
}

const OpportunitiesGallery: React.FC<OpportunitiesGalleryProps> = (props) => {
  const { redesignOpportunities } = props;
  const history = useHistory();

  return (
    <Grid container spacing={4} style={{ paddingLeft: '12%', paddingRight: '12%' }}>
      {redesignOpportunities?.map((opportunity) => (
        <Grid
          item
          xs={6}
          key={opportunity?.id}
          style={{ marginBottom: 10, cursor: 'pointer' }}
          onClick={() => history.push('/redesign/opportunities/1')}
        >
          <img
            src={ExampleOpportunity}
            alt="opportunity"
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OpportunitiesGallery;
