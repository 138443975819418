import React, { useState } from 'react';
import { Grid, Button, IconButton, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import FilterBarPaintSmear from '../../resourcesPage/assets/FilterBarPaintSmear.png';

const filterLinks = [
  { label: 'All', category: undefined },
  { label: 'Lupus', category: 'blog_post' },
  { label: 'Cancer', category: 'event' },
  { label: "Women's Health", category: 'case_study' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    float: 'right',
    '& .MuiFilledInput-root': {
      borderRadius: '20px',
      backgroundColor: '#407667',
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
  },
}));

interface HomepageResourcesFilterBarProps {
  fetchOpportunities: (category?: string, query?: string) => void;
}

const HomepageResourcesFilterBar: React.FC<HomepageResourcesFilterBarProps> = (props) => {
  const { fetchOpportunities } = props;
  const classes = useStyles();

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleCategoryClicked = (category: string | undefined) => {
    setSelectedCategory(category);
    fetchOpportunities(category, searchQuery);
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${FilterBarPaintSmear})`,
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: 80,
        maxWidth: 1200,
        margin: 'auto',
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          position: 'relative',
          paddingLeft: '5%',
          paddingRight: '5%',
        }}
      >
        {filterLinks.map((filterLink) => (
          <Grid
            item
            key={filterLink.label}
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {selectedCategory === filterLink.category ? (
              <>
                <Button
                  style={{ backgroundColor: '#F3C66B' }}
                  onClick={() => handleCategoryClicked(filterLink.category)}
                >
                  <p
                    style={{
                      color: 'black',
                      fontSize: 14,
                      fontFamily: 'Rethink Sans, sans-serif',
                      textTransform: 'capitalize',
                      fontWeight: 700,
                    }}
                  >
                    {filterLink.label}
                  </p>
                </Button>
              </>
            ) : (
              <Button onClick={() => handleCategoryClicked(filterLink.category)}>
                <p
                  style={{
                    color: 'white',
                    fontSize: 14,
                    fontFamily: 'Rethink Sans, sans-serif',
                    textTransform: 'capitalize',
                    fontWeight: 700,
                  }}
                >
                  {filterLink.label}
                </p>
              </Button>
            )}
          </Grid>
        ))}
        <Grid item xs={1} />
        <Grid item xs={3}>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            defaultValue=""
            variant="filled"
            size="small"
            className={classes.root}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomepageResourcesFilterBar;
