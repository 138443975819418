import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ResearchCompany from './ResearchCompany';
import useStyles from '../../constants/redesignStyles';
import TitleBackgroundImage from '../../../../assets/redesignAssets/aboutPage/TitleBackgroundImage.png';
import SectionOrnament from '../../common/SectionOrnament';

const HowWeBecameCloud: React.FC = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
      }}
    >
      <img
        src={TitleBackgroundImage}
        style={{ position: 'absolute', width: '100%', height: 'auto', top: 0, left: 0, zIndex: 0, objectFit: 'cover' }}
        alt="title background"
      />
      <div style={{ paddingLeft: '10%', paddingTop: '5%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '50%',
          }}
        >
          <SectionOrnament text="Uncover Our Story" />
        </div>
        <h1 className={classes.h1} style={{ position: 'relative' }}>
          How We Became a Leading Healthcare Market
        </h1>
        <ResearchCompany />
      </div>
    </div>
  );
};

export default HowWeBecameCloud;
