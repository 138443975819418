import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { UserContext } from '../contexts/UserContext';
import Page from '../../shared/components/Page';

const LogOutPage: React.FC = () => {
  const { logout } = React.useContext(UserContext);
  const history = useHistory();

  return (
    <Page fullWidth title="">
      <br />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} style={{ marginLeft: 20, marginRight: 20 }}>
          <h2
            className="Polaris-Header-Title"
            style={{ marginTop: 40, fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10 }}
          >
            Sign Out
          </h2>
          <br />
          <p style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '24px' }}>
            Thanks for visiting The Insighters®. Come back soon for more opportunities!
          </p>
          <br />
          <Grid container spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#E7AC34',
                  height: 40,
                  width: 'auto',
                  fontSize: '16px',
                  borderRadius: '4px',
                  marginTop: 15,
                  textTransform: 'capitalize',
                  fontFamily: 'Cooper',
                }}
                onClick={logout}
              >
                Sign Out
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#ddd',
                  height: 40,
                  width: 'auto',
                  fontSize: '16px',
                  borderRadius: '4px',
                  marginTop: 15,
                  textTransform: 'capitalize',
                  fontFamily: 'Cooper',
                }}
                onClick={() => history.push('/dashboard')}
              >
                Back to Dashboard
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default LogOutPage;
