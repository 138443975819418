import React, { useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Badge, Card, Layout, Page, Loading, Toast, DropZone, Modal, Button, TextContainer } from '@shopify/polaris';
import axios from '../../utils/axios.utils';
import InvitationPartners from '../../invitationPartners/components/InvitationPartners';
import InvitationsCreated from '../components/InvitationsCreated';
import { InvitationOpportunitiesContext } from '../contexts/InvitationOpportunitiesContext';
import { InvitationsContext } from '../../invitations/contexts/InvitationsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import SocialShareCard from '../../invitations/components/SocialShareCard';
import BackButton from '../../navigation/components/BackButton';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';

const InvitationOpportunityDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const {
    invitationOpportunity,
    fetchInvitationOpportunity,
    invitationOpportunityLoading,
    updateInvitationOpportunity,
    archiveInvitationOpportunity,
    invitationOpportunitySubmitting,
  } = React.useContext(InvitationOpportunitiesContext);
  const { currentUserHasRole } = React.useContext(UserContext);
  const { fetchFormOptions } = React.useContext(InvitationsContext);
  const { invitationOpportunityId } = useParams<Record<string, string>>();
  const [invitationOpportunityFetched, setInvitationOpportunityFetched] = React.useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emailsSending, setEmailsSending] = useState(false);

  React.useEffect(() => {
    if (!invitationOpportunityFetched) {
      fetchInvitationOpportunity(invitationOpportunityId);
      setInvitationOpportunityFetched(true);
      fetchFormOptions(invitationOpportunityId);
    }
  }, [
    invitationOpportunityFetched,
    fetchInvitationOpportunity,
    invitationOpportunityId,
    setInvitationOpportunityFetched,
    fetchFormOptions,
  ]);

  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Emails successfully delivered" />
  ) : null;
  const loadingMarkup = !invitationOpportunityFetched ? <Loading /> : null;

  const handleUpload = (files: File[]) => {
    const data = new FormData();
    const attachedFile = files[0];

    data.append(`file`, attachedFile, attachedFile.name);

    axios.post(`/invitation_opportunity_images?id=${invitationOpportunity.id}`, data).then(() => {
      history.push(`/admin/invitation-opportunities/${invitationOpportunity.id}`);
    });
  };

  const sendPartnerEmail = () => {
    setSendEmailModalOpen(false);
    setEmailsSending(true);
    axios.post(`/invitation_opportunities/${invitationOpportunity.id}/send_emails`, {}).then(() => {
      setToastActive(true);
    });
  };

  const pageMarkup = invitationOpportunityLoading ? (
    <DetailPageSkeleton />
  ) : (
    <>
      <BackButton pageAction />
      <Page
        fullWidth
        title={invitationOpportunity?.name}
        titleMetadata={
          invitationOpportunity?.status === 'active' ? (
            <Badge status="success">Active</Badge>
          ) : (
            <Badge status="critical">Inactive</Badge>
          )
        }
        primaryAction={{
          content: 'Edit Invitation Opportunity',
          accessibilityLabel: 'Edit Invitation Button',
          url: `/admin/invitation-opportunities/${invitationOpportunity?.id}/edit`,
        }}
        secondaryActions={[
          {
            content: 'Duplicate Invitation',
            accessibilityLabel: 'Duplicate Invitation Button',
            url: `/admin/invitation-opportunities/${invitationOpportunity?.id}/duplicate`,
          },
        ]}
        additionalMetadata={`Created on ${invitationOpportunity?.created_at}`}
      >
        <Layout>
          <Layout.Section>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={8}>
                <Card sectioned title="Invitation Details">
                  <Grid container>
                    <Grid item xs={12}>
                      <Card.Section title="Invitation Name">
                        <p>{invitationOpportunity?.name}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Card.Section title="Description">
                        <p>{invitationOpportunity?.description}</p>
                      </Card.Section>
                    </Grid>
                    {invitationOpportunity?.learn_more && (
                      <Grid item xs={12} sm={12} md={12}>
                        <Card.Section title="Learn More">
                          <p style={{ whiteSpace: 'break-spaces' }}>{invitationOpportunity?.learn_more}</p>
                        </Card.Section>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                      <Card.Section title="Sample Message">
                        <p style={{ whiteSpace: 'break-spaces' }}>{invitationOpportunity?.sample_message}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Invitation Type">
                        <p>{invitationOpportunity?.invitation_type}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Project Name">
                        <p>{invitationOpportunity?.project_name}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Phase Name">
                        <p>{invitationOpportunity?.phase_names}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Opportunity Name">
                        <p>{invitationOpportunity?.opportunity_name}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Invitations Completed">
                        <p>{invitationOpportunity?.invitations_completed}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Invitation Quota">
                        <p>{invitationOpportunity?.invitation_quota}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Default Invitation Amount">
                        <p>${invitationOpportunity?.default_inviter_amount}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ textTransform: 'capitalize' }}>
                      <Card.Section title="Inviter Award Type">
                        <p>{invitationOpportunity?.inviter_award_type}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Default invitee Amount">
                        <p>${invitationOpportunity?.default_invitee_amount}</p>
                      </Card.Section>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card sectioned title="Invitation Status">
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Invitation Start Date">
                        <p>{invitationOpportunity?.start_date}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Invitation End Date">
                        <p>{invitationOpportunity?.end_date}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Status">
                        <p style={{ textTransform: 'capitalize' }}>{invitationOpportunity?.status}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Card.Section title="Automatically Update Status">
                        <p>{invitationOpportunity?.update_status ? 'Yes' : 'No'}</p>
                      </Card.Section>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <br />
            <InvitationPartners invitationOpportunity={invitationOpportunity} />
            <br />
            <InvitationsCreated invitationOpportunity={invitationOpportunity} />
            <Card title="Invitation Opportunity Image" sectioned>
              <DropZone type="image" label="" onDrop={handleUpload} allowMultiple={false}>
                <DropZone.FileUpload />
              </DropZone>
            </Card>
            <Card title="Invitation Opportunity Preview" sectioned>
              <SocialShareCard invitationOpportunity={invitationOpportunity} />
            </Card>
            <br />
            {currentUserHasRole('superadmin') && (
              <>
                <Modal
                  instant
                  open={showArchiveModal}
                  onClose={() => setShowArchiveModal(false)}
                  title="Archive Confirmation"
                  primaryAction={{
                    content: 'Archive Invitation Opportunity',
                    destructive: true,
                    onAction: () => archiveInvitationOpportunity(invitationOpportunity?.id),
                  }}
                  secondaryActions={[
                    {
                      content: 'Cancel',
                      onAction: () => setShowArchiveModal(false),
                    },
                  ]}
                >
                  <Modal.Section>
                    <TextContainer>
                      <p>
                        Are you sure you want to archive this invitation opportunity? This will hide the invitation
                        opportunity by default. It will also set all of its associated invitations to &quot;Opportunity
                        Closed&quot;
                      </p>
                    </TextContainer>
                  </Modal.Section>
                </Modal>
                <Modal
                  instant
                  open={sendEmailModalOpen}
                  onClose={() => setSendEmailModalOpen(false)}
                  title="Send Partner Emails"
                  primaryAction={{
                    content: 'Send Emails',
                    onAction: sendPartnerEmail,
                  }}
                  secondaryActions={[
                    {
                      content: 'Cancel',
                      onAction: () => setSendEmailModalOpen(false),
                    },
                  ]}
                >
                  <Modal.Section>
                    <TextContainer>
                      <p>Send Emails to all newly-added Invitation Partners?</p>
                    </TextContainer>
                  </Modal.Section>
                </Modal>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Button onClick={() => setSendEmailModalOpen(true)} disabled={emailsSending}>
                      {emailsSending ? 'Emails Sent' : 'Send Emails'}
                    </Button>
                  </Grid>
                  <Grid xs={5} style={{ marginTop: 12 }}>
                    {invitationOpportunity?.status === 'archived' ? (
                      <Grid container>
                        <Grid item xs={6}>
                          <Button
                            loading={invitationOpportunitySubmitting}
                            onClick={() => {
                              updateInvitationOpportunity(invitationOpportunity?.id, { status: 'inactive' });
                            }}
                          >
                            Unarchive Invitation Opportunity
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={6}>
                          <Button
                            destructive
                            onClick={() => {
                              setShowArchiveModal(!showArchiveModal);
                            }}
                          >
                            Archive Invitation Opportunity
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <br />
              </>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InvitationOpportunityDetail;
