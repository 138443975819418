import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { SurveyMapping, SurveyMappingFormValues } from '../model';
import { SelectOption } from '../../form/model';

interface SurveyMappingContextInterface {
  fetchSurveyMapping?: (surveyMapping_id: string) => void;
  surveyMappingsLoading?: boolean;
  surveyMapping?: SurveyMapping;

  updateSurveyMapping?: (phase_id: string, formValues: SurveyMappingFormValues) => Promise<void>;
  surveyMappingSubmitting?: boolean;
  addToHiddenAttributes?: (phase_id: string, attribute: string) => Promise<void>;
  removeFromHiddenAttributes?: (phase_id: string, attribute: string) => Promise<void>;
}

const SurveyMappingContext = React.createContext<SurveyMappingContextInterface>({});

const SurveyMappingContextConsumer = SurveyMappingContext.Consumer;
const SurveyMappingContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [surveyMapping, setSurveyMapping] = React.useState<SurveyMapping>({});
  const [surveyMappingsLoading, setSurveyMappingLoading] = React.useState<boolean>(false);
  const [surveyMappingSubmitting, setSurveyMappingSubmitting] = React.useState<boolean>(false);

  const fetchSurveyMapping = async (phase_id: string) => {
    setSurveyMappingLoading(true);

    const response = await axios.get<string, any>(`survey_mappings/${phase_id}`);

    setSurveyMappingLoading(false);
    setSurveyMapping(response?.data?.result);
  };

  const updateSurveyMapping = async (phase_id: string, formValues: SurveyMappingFormValues) => {
    setSurveyMappingSubmitting(true);

    await axios.put<string, any>(`survey_mappings/${phase_id}`, {
      survey_mapping: { ...formValues },
    });

    setSurveyMappingSubmitting(false);
    history.push(`/admin/phases/${phase_id}/survey-mapping`);
  };

  const addToHiddenAttributes = async (phase_id: string, attribute: string) => {
    await axios.put<string, any>(`/survey_mappings/${phase_id}/add_hidden_attribute`, {
      attribute,
    });
    const updatedHiddenAttributes = [...surveyMapping.hidden_attributes, attribute];
    setSurveyMapping({ ...surveyMapping, hidden_attributes: updatedHiddenAttributes });
  };

  const removeFromHiddenAttributes = async (phase_id: string, attribute: string) => {
    await axios.put<string, any>(`/survey_mappings/${phase_id}/delete_hidden_attribute`, {
      attribute,
    });
    const updatedHiddenAttributes = surveyMapping.hidden_attributes.filter((item) => item !== attribute);
    setSurveyMapping({ ...surveyMapping, hidden_attributes: updatedHiddenAttributes });
  };

  return (
    <SurveyMappingContext.Provider
      value={{
        fetchSurveyMapping,
        surveyMappingsLoading,
        surveyMapping,
        updateSurveyMapping,
        surveyMappingSubmitting,
        addToHiddenAttributes,
        removeFromHiddenAttributes,
      }}
    >
      {children}
    </SurveyMappingContext.Provider>
  );
};

export { SurveyMappingContextProvider, SurveyMappingContextConsumer, SurveyMappingContext };
