import React, { useState, Dispatch, SetStateAction } from 'react';
import { Grid, Button, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import FilterBarPaintSmear from '../assets/FilterBarPaintSmear.png';
import HighlightPaintSmear from '../assets/HighlightPaintSmear.png';

import axios from '../../../utils/axios.utils';

import { HomepageResource } from '../model';

const filterLinks = [
  { label: 'All', category: undefined },
  { label: 'Blogs', category: 'blog_post' },
  { label: 'Events', category: 'event' },
  { label: 'Case Studies', category: 'case_study' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    float: 'right',
    '& .MuiFilledInput-root': {
      borderRadius: '20px',
      backgroundColor: '#407667',
      fontWeight: 700,
      fontSize: 14,
      color: 'white',
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
  },
}));

interface HomepageResourcesFilterBarProps {
  setHomepageResources: Dispatch<SetStateAction<HomepageResource[]>>;
}

const HomepageResourcesFilterBar: React.FC<HomepageResourcesFilterBarProps> = (props) => {
  const { setHomepageResources } = props;
  const classes = useStyles();

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const fetchFilteredHomepageResources = async (category?: string, query?: string) => {
    try {
      const response = await axios.get('/homepage_resources', { params: { category, query } });
      setHomepageResources(response.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryClicked = (category: string | undefined) => {
    setSelectedCategory(category);
    fetchFilteredHomepageResources(category, searchQuery);
  };

  const handleSearchIconClicked = () => {
    fetchFilteredHomepageResources(selectedCategory, searchQuery);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      fetchFilteredHomepageResources(selectedCategory, searchQuery);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${FilterBarPaintSmear})`,
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: 80,
        maxWidth: 1200,
        margin: 'auto',
      }}
      onKeyDown={handleKeyPress}
      role="button"
      tabIndex={0}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          position: 'relative',
          paddingLeft: '5%',
          paddingRight: '5%',
        }}
      >
        {filterLinks.map((filterLink) => (
          <Grid
            item
            key={filterLink.label}
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {selectedCategory === filterLink.category ? (
              <>
                <Button
                  style={{ backgroundColor: '#F3C66B' }}
                  onClick={() => handleCategoryClicked(filterLink.category)}
                >
                  <p
                    style={{
                      color: 'black',
                      fontSize: 14,
                      fontFamily: 'Rethink Sans, sans-serif',
                      textTransform: 'capitalize',
                      fontWeight: 700,
                    }}
                  >
                    {filterLink.label}
                  </p>
                </Button>
              </>
            ) : (
              <Button onClick={() => handleCategoryClicked(filterLink.category)}>
                <p
                  style={{
                    color: 'white',
                    fontSize: 14,
                    fontFamily: 'Rethink Sans, sans-serif',
                    textTransform: 'capitalize',
                    fontWeight: 700,
                  }}
                >
                  {filterLink.label}
                </p>
              </Button>
            )}
          </Grid>
        ))}
        <Grid item xs={1} />
        <Grid item xs={3}>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            defaultValue=""
            variant="filled"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={classes.root}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" onClick={handleSearchIconClicked} style={{ cursor: 'pointer' }}>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HomepageResourcesFilterBar;
