import React from 'react';
import {
  BlogMajor,
  CustomersMajor,
  CircleInformationMajor,
  OrdersMajor,
  TeamMajor,
  JobsMajor,
  QuestionMarkMajor,
} from '@shopify/polaris-icons';
import { Navigation } from '@shopify/polaris';
import Logo from '../../../assets/logo.png';

interface SideNavigationProps {
  toggleIsLoading?: () => void;
}

const SideNavigation: React.FC<SideNavigationProps> = (props) => {
  const { toggleIsLoading } = props;

  const [navItemActive] = React.useState<string>('all');

  return (
    <Navigation location="/">
      <a href="/" className="Polaris-TopBar__NavigationIcon">
        <img alt="logo" src={Logo} style={{ height: '40px', margin: '10px 20px' }} />
      </a>
      <Navigation.Section
        items={[
          {
            label: 'About',
            url: '/about',
            icon: CircleInformationMajor,
            onClick: () => {
              toggleIsLoading();
            },
            matches: navItemActive === 'home',
            badge: null,
          },
          {
            label: 'Find Opportunities',
            url: '/public-opportunities',
            icon: OrdersMajor,
            onClick: () => {
              toggleIsLoading();
              // setNavItemActive('orders');
            },
            matches: navItemActive === '',
          },
          {
            label: 'Meet the Team',
            url: '/meet-the-team',
            icon: TeamMajor,
            onClick: () => {
              toggleIsLoading();
            },
            matches: navItemActive === '',
          },
          // {
          //   label: "We're Hiring",
          //   url: '/hiring-info',
          //   icon: JobsMajor,
          //   onClick: () => {
          //     toggleIsLoading();
          //   },
          //   matches: navItemActive === '',
          // },
          {
            label: 'FAQ',
            url: '/frequently-asked-questions',
            icon: QuestionMarkMajor,
            onClick: () => {
              toggleIsLoading();
            },
            matches: navItemActive === '',
          },
          {
            label: 'Log In',
            url: '/login',
            icon: CustomersMajor,
            onClick: () => {
              toggleIsLoading();
              // setNavItemActive('orders');
            },
            matches: navItemActive === '',
          },
          {
            label: 'Sign Up',
            url: '/sign-up',
            icon: BlogMajor,
            onClick: () => {
              toggleIsLoading();
              // setNavItemActive('orders');
            },
            matches: navItemActive === '',
          },
        ]}
      />
    </Navigation>
  );
};

export default SideNavigation;
