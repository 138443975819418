import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';
import MediumOrnament from '../../common/MediumOrnament';
import PreHeaderContainerImage from '../assets/PreHeaderContainer.png';
import TitleBackgroundImage from '../../../../assets/redesignAssets/aboutPage/TitleBackgroundImage.png';
import TitleSubBackgroundImage from '../assets/TitleSubBackground.png';
import HeroImageOther from '../../../../assets/redesignAssets/staticImages/HeroImageOther.png';
import HowWeBecameCloud from '../components/HowWeBecameCloud';
import AboutHero from '../assets/AboutHero.png';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  heroTitleContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${TitleBackgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
  },
  heroTextWrapper: {
    marginLeft: '20%',
  },
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-4.25px',
    width: '100%',
    margin: 'auto 15% auto auto',
  },
  h1AccentLineContainerOverride: {
    backgroundImage: `url(${TitleSubBackgroundImage})`,
    margin: 'auto 15% auto -5%',
    height: '7em',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  h1AccentLineOverride: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.1em',
    width: '100%',
    letterSpacing: '-2.25px',
    marginLeft: '5%',
    background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    position: 'relative',
    top: '5px',
  },
}));

const AboutLandingSection: React.FC = () => {
  const classes = styleOverrides();

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <HowWeBecameCloud />
      </Grid>
      <Grid item xs={12} md={5}>
        <img src={AboutHero} alt="Hero" style={{ width: '100%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
};

export default AboutLandingSection;
