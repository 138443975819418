import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Page from '../../shared/components/Page';
import BackButton from '../../navigation/components/BackButton';
import { SurveyMappingContext } from '../contexts/SurveyMappingContext';
import WarningIcon from '../../insighterPayments/components/WarningIcon';

const SurveyMappingDetail: React.FunctionComponent = () => {
  const { phaseId } = useParams<Record<string, string>>();
  const [surveyMappingFetched, setSurveyMappingFetched] = React.useState(false);
  const { fetchSurveyMapping, surveyMapping, addToHiddenAttributes, removeFromHiddenAttributes } =
    React.useContext(SurveyMappingContext);

  React.useEffect(() => {
    if (!surveyMappingFetched) {
      fetchSurveyMapping(`${phaseId}`);
      setSurveyMappingFetched(true);
    }
  }, [phaseId, fetchSurveyMapping, surveyMappingFetched, setSurveyMappingFetched]);

  const excludeFields = (key: string) => {
    if (['id', 'hidden_attributes'].includes(key)) {
      return false;
    }
    return true;
  };

  const isHiddenField = (key: string) => {
    if (surveyMapping.hidden_attributes.includes(key)) {
      return true;
    }
    return false;
  };

  const handleAddToHiddenAttributes = (attribute: string) => {
    addToHiddenAttributes(phaseId, attribute);
  };

  const handleRemoveFromHiddenAttributes = (attribute: string) => {
    removeFromHiddenAttributes(phaseId, attribute);
  };

  // ---- Page markup ----

  return (
    <>
      <BackButton pageAction url={`/admin/phases/${phaseId}/survey-responses`} />
      <Page
        fullWidth
        title="Survey Mapping"
        primaryAction={{
          content: 'Edit Survey Mapping',
          url: `/admin/phases/${phaseId}/edit-survey-mapping`,
        }}
      >
        <Layout>
          <Layout.Section>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card sectioned title="Qualtrics Question IDs">
                  {surveyMapping && (
                    <Grid container>
                      {Object?.keys(surveyMapping)?.map((key) => (
                        <React.Fragment key={key}>
                          {excludeFields(key) && (
                            <Grid item xs={3}>
                              {isHiddenField(key) ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    opacity: '0.5',
                                  }}
                                >
                                  <Card.Section title={key.replaceAll('_', ' ')}>
                                    <p>Ignored</p>
                                  </Card.Section>
                                  <AddIcon
                                    onClick={() => handleRemoveFromHiddenAttributes(key)}
                                    style={{ fontSize: 'large', cursor: 'pointer', float: 'right' }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    opacity: '1',
                                    width: '100%',
                                  }}
                                >
                                  <Card.Section title={key.replaceAll('_', ' ')}>
                                    {surveyMapping[key] ? <p>{surveyMapping[key]}</p> : <WarningIcon />}
                                  </Card.Section>
                                  <DeleteIcon
                                    onClick={() => handleAddToHiddenAttributes(key)}
                                    style={{ fontSize: 'large', cursor: 'pointer', float: 'right' }}
                                  />
                                </div>
                              )}
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  )}
                  <p>
                    Note: All of the &apos;Condition&apos; Questions have their responses pulled-in separately from
                    Qualtrics Surveys. The saved survey response will include a separate answer for each question. When
                    the survey response is used to create an insighter account, the answers from all of the
                    &apos;Conditions&apos; questions will be combined into one single field in the Insighter&apos;s
                    profile.{' '}
                  </p>
                </Card>
              </Grid>
            </Grid>
            <br />
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default SurveyMappingDetail;
