import React from 'react';
import { Layout, Loading, Page, Toast } from '@shopify/polaris';
import { ProjectsContext } from '../contexts/ProjectsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import ProjectForm from '../components/ProjectForm';
import BackButton from '../../navigation/components/BackButton';

const NewProject: React.FunctionComponent = () => {
  const [projectFetched, setProjectFetched] = React.useState(false);
  const { project, fetchFormOptions } = React.useContext(ProjectsContext);

  React.useEffect(() => {
    if (!projectFetched) {
      fetchFormOptions();
      setProjectFetched(true);
    }
  }, [projectFetched, fetchFormOptions]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;
  const loadingMarkup = !projectFetched ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Project">
        <Layout>
          <Layout>
            <Layout.Section>
              <br />
              <ProjectForm project={project} />
            </Layout.Section>
          </Layout>
        </Layout>
      </Page>
    </>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = !projectFetched ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default NewProject;
