import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { HomepageResource } from './model';
import axios from '../../utils/axios.utils';
import HomepageResourcesHeader from './components/HomepageResourcesHeader';
import FeaturedResourcesComponent from './components/FeaturedResources';
import HomepageResourcesFilterBar from './components/HomepageResourcesFilterBar';
import HomepageResourcesGallery from './components/HomepageResourcesGallery';
import Footer from '../common/RedesignFooter';

const ResourcesPage: React.FC = () => {
  const [homepageResources, setHomepageResources] = useState<HomepageResource[]>([]);
  const [featuredResources, setFeaturedResources] = useState<HomepageResource[]>([]);

  const fetchInitialHomepageResources = async (category?: string, query?: string) => {
    try {
      const response = await axios.get('/homepage_resources', { params: { category, query } });
      let allResources = response.data?.result;
      setHomepageResources(allResources);
      setFeaturedResources(allResources.filter((resource: HomepageResource) => resource.featured));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInitialHomepageResources();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <HomepageResourcesHeader />
      </Grid>
      <Grid item xs={12}>
        <FeaturedResourcesComponent featuredResources={featuredResources} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <HomepageResourcesFilterBar setHomepageResources={setHomepageResources} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <HomepageResourcesGallery homepageResources={homepageResources} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default ResourcesPage;
