import React from 'react';
import axios from '../../utils/axios.utils';
import { Opportunity, InsighterOpportunitiesResponse } from '../model';

interface InsighterOpportunitiesContextInterface {
  fetchInsighterOpportunities?: (impersonatedUserId?: string) => Promise<void>;
  fetchInsighterOpportunity?: (opportunityId: string) => Promise<void>;
  insighterOpportunities?: Opportunity[];
  insighterOpportunity?: Opportunity;
  insighterOpportunityLoading?: boolean;
  registerUser?: (formValues: Record<string, string>, onSuccess?: () => void) => Promise<void>;
  formErrors?: Record<string, string>;
}

const InsighterOpportunitiesContext = React.createContext<InsighterOpportunitiesContextInterface>({
  fetchInsighterOpportunities: undefined,
  fetchInsighterOpportunity: undefined,
  insighterOpportunities: [],
  insighterOpportunity: {},
  insighterOpportunityLoading: true,
});

const InsighterOpportunitiesContextConsumer = InsighterOpportunitiesContext.Consumer;
const InsighterOpportunitiesContextProvider: React.FC = ({ children }) => {
  const [insighterOpportunityLoading, setInsighterOpportunityLoading] = React.useState<boolean>(false);
  const [insighterOpportunities, setInsighterOpportunities] = React.useState<Opportunity[]>([]);
  const [insighterOpportunity, setInsighterOpportunity] = React.useState<Opportunity>();
  const [formErrors, setFormErrors] = React.useState({});
  const [formLoading, setFormLoading] = React.useState(false);

  const fetchInsighterOpportunities = async (impersonatedUserId?: string) => {
    setInsighterOpportunityLoading(true);
    let response: InsighterOpportunitiesResponse;

    if (impersonatedUserId) {
      response = await axios.get<string, any>(`impersonations/${impersonatedUserId}/opportunities`);
    } else {
      response = await axios.get<string, any>(`insighter_opportunities.json`);
    }

    setInsighterOpportunityLoading(false);
    setInsighterOpportunities(response?.data?.result);
  };

  const fetchInsighterOpportunity = async (opportunityId: string) => {
    setInsighterOpportunityLoading(true);

    const response = await axios.get<string, any>(`insighter_opportunities/${opportunityId}.json`, {
      headers: { 'Content-Type': 'application/json' },
    });

    setInsighterOpportunityLoading(false);
    setInsighterOpportunity(response?.data?.result);
  };

  const registerUser = async (formValues: Record<string, string>, onSuccess?: () => void) => {
    setFormLoading(true);

    try {
      await axios
        .post(`invitation_registrations`, { user: formValues })
        .then((response: Record<string, any>) => {
          if (response.data.error) {
            setFormLoading(false);
            setFormErrors({ email: response.data.error.email });
          }

          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          setFormLoading(false);
          setFormErrors({ base: 'Please complete all required information and try again' });
        });
    } catch {
      setFormLoading(false);
      setFormErrors({ email: 'Something went wrong, please reload the page and try again.' });
    }
  };

  return (
    <InsighterOpportunitiesContext.Provider
      value={{
        fetchInsighterOpportunities,
        fetchInsighterOpportunity,
        insighterOpportunities,
        insighterOpportunity,
        insighterOpportunityLoading,
        registerUser,
        formErrors,
      }}
    >
      {children}
    </InsighterOpportunitiesContext.Provider>
  );
};

export { InsighterOpportunitiesContextProvider, InsighterOpportunitiesContextConsumer, InsighterOpportunitiesContext };
