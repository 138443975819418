import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import BlogPostDetailHero from './sections/BlogPostDetailHero';
import RedesignFooter from '../common/RedesignFooter';
import BlogBackground from './assets/BlogBackground.png';
import DeleteBlogPostConfirmModal from './components/DeleteBlogPostConfirmModal';
import BlogAuthorViewer from './components/BlogAuthorViewer';
import ContentManagementButtons from '../../shared/components/ContentManagementButtons';
import { BlogPost } from '../../blogPosts/model';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 15,
  },
});

const BlogDetailPage: React.FC = () => {
  let classes = useStyles();

  let { blogPostId } = useParams<{ blogPostId: string }>();

  let [blogPost, setBlogPost] = useState<BlogPost | null>(null);
  let [deleteBlogPostConfirmModalOpen, setDeleteBlogPostConfirmModalOpen] = useState(false);

  const fetchBlogPost = async () => {
    try {
      const response = await axios.get(`blog_posts/${blogPostId}`);
      setBlogPost(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogPost();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ maxWidth: '1600px', margin: 'auto', padding: '2%' }}>
      <Grid item xs={12} style={{ padding: 10 }}>
        <ContentManagementButtons
          recordId={blogPostId}
          recordLink="blog-posts"
          recordLabelPlural="Blog Posts"
          recordLabelSingular="Blog Post"
          deleteModalOpen={setDeleteBlogPostConfirmModalOpen}
        />
      </Grid>
      <DeleteBlogPostConfirmModal
        blogPostId={blogPostId}
        deleteBlogPostConfirmModalOpen={deleteBlogPostConfirmModalOpen}
        setDeleteBlogPostConfirmModalOpen={setDeleteBlogPostConfirmModalOpen}
      />
      <Grid item xs={12} className={classes.sectionGrid}>
        <BlogPostDetailHero blogPost={blogPost} />
      </Grid>
      <Grid item md={1} />
      <Grid item md={10} className={classes.sectionGrid} style={{ marginTop: -80, width: '100%' }}>
        <div
          style={{
            backgroundImage: `url(${BlogBackground})`,
            backgroundSize: 'cover',
            minHeight: 600,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '100px',
              fontFamily: 'Crimson Text',
              color: 'white',
            }}
          >
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: blogPost?.content }} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        {/* <MeetTheAuthor blogPost={blogPost} /> */}
        <BlogAuthorViewer blogAuthor={blogPost?.blog_author} />
      </Grid>
      <Grid item xs={12} style={{ padding: 10 }}>
        <ContentManagementButtons
          recordId={blogPostId}
          recordLink="blog-posts"
          recordLabelPlural="Blog Posts"
          recordLabelSingular="Blog Post"
          deleteModalOpen={setDeleteBlogPostConfirmModalOpen}
        />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default BlogDetailPage;
