const siteWideStyles = {
  primaryButton: {
    backgroundColor: '#72a295',
    color: 'white',
    height: 40,
    fontSize: '14px',
    borderRadius: '4px',
    marginTop: 8,
    textTransform: 'none' as const,
    fontWeight: 700,
  },
  secondaryButton: {
    backgroundColor: '#DDD',
    color: '#444',
    height: 40,
    width: 'auto',
    fontSize: '14px',
    borderRadius: '4px',
    marginTop: 8,
    textTransform: 'capitalize' as const,
    fontWeight: 500,
  },
  destructiveButton: {
    backgroundColor: 'indianred',
    color: 'white',
    height: 40,
    fontSize: '14px',
    borderRadius: '4px',
    marginTop: 8,
    textTransform: 'none' as const,
    fontWeight: 700,
  },
  authButton: {
    backgroundColor: '#E7AC34',
    height: 40,
    width: 'auto',
    fontSize: '16px',
    borderRadius: '4px',
    marginTop: 8,
    textTransform: 'capitalize' as const,
    fontFamily: 'Cooper',
  },

  subheader: {
    fontSize: '20px',
    fontFamily: 'Cooper',
    lineHeight: '28px',
  },
};

export default siteWideStyles;
