import React from 'react';
import { Grid, Typography, Card } from '@material-ui/core';
import {
  UploadFile,
  Merge,
  FormatQuote,
  JoinFull,
  MedicalServices,
  LocalHospital,
  People,
  Label,
  Engineering,
  Face,
  AttachMoney,
  PermPhoneMsg,
  EventAvailable,
  Celebration,
  PostAdd,
  PhotoLibrary,
  LocalLibrary,
  AssignmentInd,
} from '@mui/icons-material';

const allTools = [
  {
    name: 'Insighter Attributes',
    buttons: [
      {
        name: 'Conditions',
        description: '',
        icon: LocalHospital,
        link: '/admin/conditions',
      },
      {
        name: 'Condition Categories',
        description: '',
        icon: MedicalServices,
        link: '/admin/conditions/categories',
      },
      {
        name: 'Ethnicities',
        description: '',
        icon: People,
        link: '/admin/ethnicities',
      },
      {
        name: 'Insighter Roles',
        description: '',
        icon: Face,
        link: '/admin/insighter-roles',
      },
      {
        name: 'Insighter Tags',
        description: '',
        icon: Label,
        link: '/admin/insighter-tags',
      },
      {
        name: 'Specialties',
        description: '',
        icon: Engineering,
        link: '/admin/specialties',
      },
    ],
  },
  {
    name: 'Applications',
    buttons: [
      {
        name: 'List Uploader',
        description: 'Upload a CSV file file to compare with our database.',
        icon: UploadFile,
        link: '/admin/list_uploader',
      },
      {
        name: 'Account Merger',
        description: "Combine two user accounts by moving one users's data to another",
        icon: Merge,
        link: '/admin/account_merger',
      },
      {
        name: 'Condition Merger',
        description: 'Merge two conditions together, then optionally delete one of them',
        icon: JoinFull,
        link: '/admin/conditions/merge',
      },
    ],
  },
  {
    name: 'Back End',
    buttons: [
      {
        name: 'Tremendous Campaigns',
        description: '',
        icon: AttachMoney,
        link: '/admin/tremendous-campaigns',
      },
      {
        name: 'Recruitment Partners',
        description: '',
        icon: PermPhoneMsg,
        link: '/admin/recruitment-partners',
      },
      {
        name: 'Calendly Events',
        description: '',
        icon: EventAvailable,
        link: '/admin/calendly-events',
      },
    ],
  },
  {
    name: 'Homepage Resources',
    buttons: [
      {
        name: 'Blog Authors',
        description: 'Manage blog authors',
        icon: AssignmentInd,
        link: '/admin/blog-authors',
      },
      {
        name: 'Blog Posts',
        description: 'Manage blog posts',
        icon: PostAdd,
        link: '/admin/blog-posts',
      },
      {
        name: 'Case Studies',
        description: '',
        icon: LocalLibrary,
        link: '/admin/case-studies',
      },
      {
        name: 'Events',
        description: 'Image links that appear on the home page and in the resource gallery',
        icon: Celebration,
        link: '/admin/event-resources',
      },
    ],
  },
  {
    name: 'Front End',
    buttons: [
      {
        name: 'Testimonials',
        description: 'Insighter quotes that appear on the home page',
        icon: FormatQuote,
        link: '/admin/testimonials',
      },

      {
        name: 'Icons',
        description: 'A collection of icons.  Currently only used for Case Studies.',
        icon: PhotoLibrary,
        link: '/admin/icons',
      },
    ],
  },
];

const AdminToolsPage: React.FunctionComponent = () => {
  return (
    <Grid container style={{ padding: 10 }}>
      <Typography variant="h2">Admin Tools</Typography>
      {allTools.map((section) => (
        <Grid container style={{ margin: 20 }} key={section.name}>
          <Grid item xs={12}>
            <Typography variant="h3">{section.name}</Typography>
          </Grid>
          {section.buttons.map((button) => (
            <Grid item xs={4} style={{ padding: 5 }} key={button.name}>
              <a href={button.link} style={{ textDecoration: 'none' }}>
                <Card style={{ padding: 10 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                      <button.icon style={{ width: '100%', height: '100%' }} />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="h4">{button.name}</Typography>
                      <p>{button.description}</p>
                    </Grid>
                  </Grid>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminToolsPage;
