import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import OpportunityOverviewPlaceholder from '../assets/OpportunityOverviewPlaceholder.png';

const OpportunityOverview: React.FC = (props) => {
  const history = useHistory();

  return (
    <Grid container spacing={4} style={{ paddingLeft: '12%', paddingRight: '12%' }}>
      <Grid
        item
        xs={12}
        style={{ marginBottom: 10, cursor: 'pointer' }}
        onClick={() => history.push('/redesign/opportunities/1')}
      >
        <img
          src={OpportunityOverviewPlaceholder}
          alt="opportunity"
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
        />
      </Grid>
    </Grid>
  );
};

export default OpportunityOverview;
