import React from 'react';
import { Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';
import Footer from '../../navigation/components/Footer';

const TermsOfUse: React.FunctionComponent = () => {
  return (
    <>
      <Page fullWidth title="">
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <Grid container>
            <Grid item md={8} xs={12}>
              <h2 style={{ fontSize: 28, fontFamily: 'Cooper', marginTop: 40 }}>Terms of Use​</h2>
              <br />
              <h3 style={{ fontSize: 24, fontFamily: 'Cooper', marginTop: 40 }}>Table of Contents​</h3>
              <ol>
                <li>Eligibility</li>
                <li>Registration & Account</li>
                <li>The Platform</li>
                <li>Project Payment & Pricing</li>
                <li>Vetting</li>
                <li>Permitted Uses/License</li>
                <li>Prohibited Uses</li>
                <li>Location-based Services</li>
                <li>Third-Party Sites</li>
                <li>User Content</li>
                <li>Data</li>
                <li>Your Communications</li>
                <li>Reliance on Information Posted</li>
                <li>Limitation of Liability</li>
                <li>Indemnity</li>
                <li>Notice</li>
                <li>Injunctive Relief</li>
                <li>MANDATORY ARBITRATION AND CLASS ACTION AND JURY TRIAL WAIVER</li>
                <li>Warranty Disclaimer</li>
                <li>Electronic Signatures and Notices</li>
                <li>Governing Law</li>
                <li>Miscellaneous</li>
                <li>Questions</li>
              </ol>
              <p>
                The website and the applications (collectively, the “<b>Site</b>”) on which this Terms of Use (“
                <b>Terms”</b>) appear are owned and operated by Linguis-Techs. d/b/a Sommer Consulting (the “
                <b>Company</b>,” “<b>we</b>,” “<b>our</b>,” or “<b>us</b>”). For purposes of these Terms, “you” or
                “your” means the person accessing the Site and any persons that allow others to provide information
                about themselves to us. The Site may provide information, documents, tools, products, services,
                accounts, offers, and suggestions on the best way to use our products and services, including the Pay
                Portal (the <b>“Services”</b>). Any terms not defined herein shall have the meaning in the Pay Portal
                Terms here.
              </p>
              <br />
              <p>
                THESE TERMS CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND US. PLEASE READ CAREFULLY THROUGH ALL
                SECTIONS OF THESE TERMS. YOUR ACCESS TO AND USE OF THE SITE IS SUBJECT TO THESE TERMS AND ALL APPLICABLE
                LAWS AND WE RESERVE THE RIGHT TO TERMINATE YOUR ACCESS TO THE SITE IF YOU VIOLATE THESE TERMS. BY
                CLICKING ON LINKS WITHIN THE SITE OR WEBPAGES BEYOND THE SITE’S HOMEPAGE OR BY CLICKING ON A BOX OR ICON
                YOU AGREE TO THESE TERMS WHETHER OR NOT YOU COMPLETE A TRANSACTION WITH US AND WHETHER OR NOT YOU
                COMPLETE YOUR TRANSACTION ON THE SITE OR THROUGH OTHER CHANNELS, SUCH AS BY TELEPHONE, EMAIL, FACSIMILE,
                OR OTHERWISE. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS OR OTHERWISE USER THE SITE, ANY SERVICES
                AVAILABLE THROUGH THIS SITE, OR ANY INFORMATION CONTAINED ON THIS SITE.
              </p>
              <br />
              <p>
                <b>MANDATORY ARBITRATION NOTICE AND CLASS ACTION AND JURY TRIAL WAIVER.</b> These Terms contain a
                mandatory (binding) arbitration provision and class action and jury trial waiver clauses. Except for
                certain types of disputes described in the arbitration section below or where prohibited by applicable
                law, you agree that disputes between you and us regarding your use of the Site or Services will be
                resolved by binding, individual arbitration and you waive your right to participate in a class action
                lawsuit or class-wide arbitration, including as a class representative. The arbitrator’s decision will
                be subject to very limited review by a court. You will be entitled to a fair hearing, but the
                arbitration procedures are simpler and more limited than rules applicable in Court. For more details,
                see below. `
              </p>
              <br />
              <p>
                Besides these Terms, we also publish a Privacy Policy <a href="/privacy_policy">here</a>. Although it is
                not part of these Terms, we encourage you to read it to better understand how you can update, manage,
                access, and delete your information.
              </p>
              <br />
              <p>
                We reserve the right to modify these Terms at any time, with such changes becoming effective when we
                post the modified Terms to the Site. We also reserve the right to make any changes to the Site and
                Services in any manner and to deny or terminate your access to the Site and Services, even if you have
                an Account, in our sole discretion.
              </p>
              <br />
              <p>
                Each time you use the Site or the Services, the then-current version of the Terms will apply. If you use
                the Site or the Services after a modification of these Terms, you agree to be bound by the Terms as
                modified.
              </p>
              <br />
              <p>
                These Terms contain important information regarding your rights with respect to the Site and the
                Services, including your relationship with us, and include an arbitration provision that may limit your
                ability to pursue claims against us in court. Please read them carefully and review them regularly.
              </p>
              <br />
              <p>
                <b>1. Eligibility.</b>
              </p>
              <br />
              <p>
                If you are under 18 years old, you may not use the Services. You represent that any information you
                submit to us when using the Services is accurate, truthful, and current. You also represent that your
                use of the Services does not violate any applicable law or regulation.{' '}
              </p>
              <br />
              <p>
                <b>2. Registration & Account.</b>
              </p>
              <br />
              <p>
                Certain aspects of the Services or portions of the Site may require you to register for an account (
                <b>“Account”</b>), becoming a “<b>Registered User”</b>. As part of the Account creation process, you may
                be asked to provide a username or email address and password unique to the Account (“
                <b>Login Information”</b>). You are responsible for the confidentiality and use of your Login
                Information and agree not to transfer or disclose your Login Information to any third party other than
                an individual with express authority to act on your behalf. If you suspect any unauthorized use of your
                Account, you agree to notify us immediately. You are solely responsible for any activities occurring
                under your Account. You have no ownership right to your Account. If you are registering an Account on
                behalf of an organization under an agreement between us and another organization, that organization may
                have administrator rights to access your account and any information provided under your Account.
              </p>
              <br />
              <p>
                We have the right to disable your Account and any username, password, or other identifier, whether
                chosen by you or provided by us, at any time in our sole discretion for any reason, including if, in our
                opinion, you have violated any provision of these Terms.{' '}
              </p>
              <br />
              <p>
                <b>3. The Platform</b>
              </p>
              <br />
              <p>
                One of the Services we offer is a platform (the “<b>Platform”</b>) that enables market researchers (“
                <b>Market Researchers</b>”) to connect with respondents to marketing research and studies (“
                <b>Respondents”</b>, and, together with Market Researchers, “<b>Users”</b>). The Platform only enables
                connections between Users, and we are not responsible for the performance of Users, nor do we have
                control over the quality, timing, legality, failure to provide, or any other aspect whatsoever of any
                User or any opportunity provided or presented on the Platform, nor of the integrity, responsibility,
                qualifications or any of the actions or omissions whatsoever of any Users. We make no representations
                about the suitability, reliability, timeliness, or accuracy of any opportunities presented and services
                provided by Users identified through the Platform whether in public, private, or offline interactions.
                If you, as a Market Researcher, attempt to post a project or otherwise connect with Respondents (in any
                event a “<b>Project”</b>), we make no guarantee as to whether such Project will be fulfilled. We do not
                employ, supervise, direct, or control a User’s work in any manner. We do not provide any warranties or
                guarantees regarding any User’s accreditations or credentials. Users do not have authority to enter into
                written or oral — whether implied or express — contracts on behalf of us. The Platform is not an
                employment service and we are not an employer of any User. As such, we are not responsible for and will
                not be liable for any tax payments or withholding, including but not limited to unemployment insurance,
                social security, disability insurance or any other applicable federal or state withholdings in
                connection with your use of the Platform or the Services we provide thereon.
              </p>
              <br />
              <p>
                <b>4. Project Payment & Pricing. </b>
              </p>
              <br />
              <p>
                4.1 <u>Payments</u>. The price we reimburse for Projects will be displayed on the Services. We only pay
                for each successfully completed Project. We may from time to time introduce various policies and
                procedures related to Projects, which we will display on the Services and/or the Platform (collectively,
                the <b>“Policies”</b>). The Policies are incorporated into these Terms and the then-current Policies
                will apply to any Project you post on the Platform. To be eligible to use the Pay Portal to receive
                Payouts from your payor, you must:
              </p>
              <br />
              <p>(a)be at least eighteen (18) years old,</p>
              <br />
              <p>(b) be located in a country supported by the Company,</p>
              <br />
              <p>
                (c) register your use of the Pay Portal by providing current, complete and accurate information as
                prompted by the registration process on the Pay Portal, and
              </p>
              <br />
              <p>(d) agree to the Terms and Conditions presented either in the Pay Portal or by your payor.</p>
              <br />
              <p>
                Some registration and other identifying information about you may be provided by the Company to
                financial institutions from time to time. Multiple registrations are prohibited. You may register only
                once, and each User must maintain a separate registration. If we detect multiple active registrations
                for a single user, we reserve the right to merge or terminate the registrations and refuse to permit you
                to receive Payouts without notification to you.
              </p>
              <br />
              <p>
                4.2. <u>Restrictions</u>.
              </p>
              <br />
              <p>
                (a) The Services provided to the payor are subject to certain limits and availability. Therefore, not
                all Payouts Methods may be made available to you. Save as set out in these Terms, the Services are not
                offered to payees, consumers or the general public.
              </p>
              <br />
              <p>
                (b) The Payout Methods are intended to enable Payouts for business-related and commercial activities in
                connection with your relationship with your payor and are not intended and may not be used for personal,
                family, or household purposes. By registering on the Pay Portal, you attest that you are engaged in a
                trade or business for gain or profit, whether as a sole proprietor or otherwise and are using the Pay
                Portal for commercial purposes as an independent contractor of payor and not for the receipt of any
                wages subject to payroll withholding or otherwise for personal, family or household purposes. Save as
                set out in the Payout Schedule in the Pay Portal Terms, you acknowledge that you are not entering into a
                business relationship with us.
              </p>
              <br />
              <p>
                (c) The Company is not responsible for and does not have any liability with respect to any of the
                products, services, or activities for which you receive a Payout via the Pay Portal or any of the
                actions or inactions of any Payor or any third party, including but not limited to any dispute as to any
                amount of funds paid or payable to you by such Payor or otherwise. Additional restrictions as to the
                receipt of the Payouts are set forth in the Payout Schedule.
              </p>
              <br />
              <p>
                <b>5. Vetting.</b>
              </p>
              <br />
              <p>
                Users may be subject to a vetting process before they can register for and during their use of the
                Platform, including but not limited to a verification of identity, using third party services as
                appropriate. You hereby give consent to us to conduct background checks as often as required in
                compliance with federal and state laws, including, without limitation, the Fair Credit Reporting Act.
                Although we may perform background checks of Users as outlined above, we cannot confirm that each User
                is who they claim to be and therefore, we cannot and do not assume any responsibility for the accuracy
                or reliability of identity or background check information or any information provided through the
                Platform. When interacting with other Users, you should exercise caution and common sense to protect
                your personal safety and property, just as you would when interacting with other persons whom you don’t
                know. NEITHER WE NOR OUR AFFILIATES OR LICENSORS IS RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR
                OFFLINE, OF ANY USER OF THE PLATFORM AND YOU HEREBY RELEASE US AND OUR EMPLOYEES, OFFICERS, DIRECTORS,
                MANAGERS, REPRESENTATIVES, AGENTS, AND OUR AND THEIR AFFILIATES AND LICENSORS (“<b>RELEASED PARTIES</b>
                ”) FROM ANY LIABILITY RELATED THERETO. THE RELEASED PARTIES WILL NOT BE LIABLE FOR ANY CLAIM, INJURY OR
                DAMAGE ARISING IN CONNECTION WITH YOUR USE OF THE PLATFORM.
              </p>
              <br />
              <p>
                <b>6. Permitted Uses/License.</b>
              </p>
              <br />
              <p>
                You are authorized to access the Site for the sole purpose of viewing and using the Services on your
                computer or device. Unless otherwise specified in these Terms, all information and screens appearing on
                this Site are the sole property of us or our subsidiaries and affiliates, and other parties. We provide
                content through the Site that is copyrighted or contains protectable trademarks of us or our third-party
                licensors and suppliers (collectively, the <b>“Materials”</b>). Materials may include documents,
                services, software, site design, text, graphics, logos, video, images, icons, and other content, as well
                as the arrangement thereof. Subject to these Terms, we hereby grant to you a revocable, limited,
                personal, non-exclusive, and non-transferable license to use, view, print, display, and download the
                Materials for the sole purpose of viewing them on a stand-alone personal computer or mobile device and
                to use this Site solely for your personal use. Except for the foregoing license and as otherwise
                required or limited by applicable law, you have no other rights in the Site or any Materials and you may
                not modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance, or in
                any way exploit any of the Site or Materials in any manner or for any purpose that would constitute
                infringement of our, our licensors’, or the Site’s other user’s intellectual property rights. All rights
                not expressly granted herein are reserved.
              </p>
              <br />
              <p>
                If you breach any of these Terms, the above license will terminate automatically and you must
                immediately destroy any downloaded or printed Materials.
              </p>
              <br />
              <p>
                <b>7. Prohibited Uses.</b>
              </p>
              <br />
              <p>
                You may not decompile, disassemble, rent, lease, loan, sell, sublicense, or create derivative works from
                the Site or the Services. You may not use any robot, spider, or other automatic device or manual process
                to monitor or copy the Site or its content without our prior written permission. You further agree not
                to use the Site in an way that: (a) changes or alters the Site or Services; (b) impairs the integrity or
                operation of the Site; (c) is in any way unlawful or prohibited; (d) suggests an express or implied
                affiliation or relationship with us without our express written expression; (e) unlawfully transmits or
                uploads any confidential, proprietary, or trade secret information. We have no obligation, but maintain
                the right, to monitor the Site; (f) is patently offensive in any manner; (g) involves commercial
                activities without our prior written consent, such as contests or sweepstakes; (h) is contrary to our
                public image, goodwill, or reputation; (i) infringes on our or any third party’s intellectual property
                rights; (j) violates any law or any third party’s legal rights; or (k) “frame” or “mirror” any part of
                the Site without our prior written consent. This list of prohibited activities provides examples and is
                not complete or exclusive. We reserve the right to terminate your right to access the Site or to use the
                Services with or without cause and with or without notice, for any reason or no reason, or for any
                action that we determine is inappropriate or disruptive to the Site. We may report to law enforcement
                authorities any actions that may be illegal, and any reports we receive of such conduct. When legally
                required or at our discretion, we will cooperate with law enforcement agencies in any investigation of
                alleged illegal activity on this Site or on the Internet, which may include disclosing any information
                we obtain. In addition, we may disclose information we obtain as necessary or appropriate to operate or
                improve the Site, to protect us and/or our Site users, or for any other purpose that the law permits.
              </p>
              <br />
              <p>
                <b>8. Location-based Services.</b>
              </p>
              <br />
              <p>
                Some of the Services may require that location functionality be enabled on the relevant device in order
                to work properly. You acknowledge and agree that if location permissions and functionalities are not
                enabled on the device with which you access the Services, the Services may not work appropriately or at
                all. We will use any location information we receive from you in accordance with our Privacy Policy.
              </p>
              <br />
              <p>
                <b>9. Third-Party Sites.</b>
              </p>
              <br />
              <p>
                The Site may contain links to websites we do not operate, control, or maintain (“
                <b>Third-Party Websites</b>”). We do not endorse any Third-Party Websites, and we make no representation
                or warranty in any respect regarding the Third-Party Websites. Any links to Third-Party Websites on the
                Site are provided solely for your convenience. If you do access any Third-Party Websites, you do so at
                your own risk and waive any and all claims against us regarding the Third-Party Websites or our links
                thereto. YOU AGREE THAT WE WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE, DIRECLTY OR
                INDIRECTLY, FOR ANY GOODS, SERVICES, INFORMATION, RESOURCES, AND/OR CONTENT AVAILABLE ON OR THROUGH ANY
                THIRD PARTY WEBSITES AND/OR THIRD-PARTY DEALINGS OR COMMUNICATIONS, OR FOR ANY HARM RELATED THERETO, OR
                ANY DAMAGES OR LOSSES CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON
                THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD PARTY.
              </p>
              <br />
              <p>
                <b>10. User Content.</b>
              </p>
              <br />
              <p>
                If you post content and information to the Site or in connection with the Services (“<b>User Content</b>
                ”), you represent and warrant to us that (a) you own or have rights to use the User Content, (b) the
                posting of the User Content does not violate any rights of any person or entity, and (c) you have no
                agreement with or obligations to any third party that would prohibit your use of the Site or Services in
                the manner so used. You agree to pay all royalties, fees, and any other monies owing to any person or
                entity by reason of any User Content posted by you to the Site or through the Services. By posting User
                Content, you grant to us and our affiliates a royalty-free license to use, reproduce, modify, perform,
                display, and distribute such User Content in such manner as is necessary to provide the Services to you;
                provided that this right shall not give us any ownership or other rights in the User Content. We have
                the right to remove or refuse to post any User Content for any or no reason in our sole discretion. We
                may take any action with respect to any User Content, including taking appropriate legal action, or
                terminating or suspending your access to all or part of the Site. Without limiting the foregoing, we
                have the right to cooperate fully with any law enforcement authorities or court order requesting or
                directing us to disclose the identity or other information of anyone posting any materials on or through
                the Site. YOU WAIVE AND HOLD HARMLESS US AND OUR AFFILIATES, LICENSEES, AND SERVICE PROVIDERS] FROM ANY
                CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE
                OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES. However, we cannot review all
                material before it is posted on the Site, and cannot ensure prompt removal of objectionable material
                after it has been posted. Accordingly, we assume no liability for any action or inaction regarding
                transmissions, communications, or content provided by any user or third party. We have no liability or
                responsibility to anyone for performance or nonperformance of the activities described in this section.
              </p>
              <br />
              <p>
                <b>11. Data.</b>
              </p>
              <br />
              <p>
                You agree that we have the right to collect and analyze data and other information relating to the
                provision, use and performance of various aspects of the Site and Services, and related systems (for
                example, anonymous and aggregated information concerning user behavior and use of the Services), and we
                will be free (during and after the term hereof) to (a) use such information and data to improve and
                enhance the Site and Services and for other development, diagnostic and corrective purposes in
                connection with the Site and Services and other Company offerings, and (b) disclose such data solely in
                aggregate or other de-identified form.
              </p>
              <br />
              <p>
                <b>12. Your Communications.</b>
              </p>
              <br />
              <p>
                By forwarding any content or communications to us through the Site or by other electronic means, you
                thereby grant us a perpetual, royalty-free, fully paid-up, world-wide, irrevocable, nonexclusive, freely
                transferable, and freely sublicensable license to use, reproduce, modify, adapt, publish, translate,
                create derivative works from, redistribute, and display such content and communications in any form for
                the purposes of providing the Services and any purpose tangentially related to the Services. No
                compensation will be paid to you with respect to our or our sublicensees’ use of your communications. By
                providing or submitting content, you represent and warrant that you own or otherwise control all of the
                rights to your submitted content and communications as described in this section, including all the
                rights necessary for you to submit the content and communications and grant the license above.
              </p>
              <br />
              <p>
                <b>13. Reliance on Information Posted.</b>
              </p>
              <br />
              <p>
                The information presented on or through the Site is made available solely for general information
                purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance
                you place on such information is strictly at your own risk. We disclaim all liability and responsibility
                arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone
                who may be informed of any of its contents.
              </p>
              <br />
              <p>
                This Site may include content provided by third parties, including materials provided by other users,
                bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All statements
                and/or opinions expressed in these materials, and all articles and responses to questions and other
                content, other than the content provided by us, are solely the opinions and the responsibility of the
                person or entity providing those materials. These materials do not necessarily reflect our opinion. We
                are not responsible, or liable to you or any third party, for the content or accuracy of any materials
                provided by any third parties.
              </p>
              <br />
              <p>
                <b>14. Limitation of Liability.</b>
              </p>
              <br />
              <p>
                WE CANNOT GUARANTEE THE SITE AND SERVICES WILL BE AVAILABLE ONE HUNDRED PERCENT (100%) OF THE TIME
                BECAUSE PUBLIC NETWORKS, SUCH AS THE INTERNET, OCCASIONALLY EXPERIENCE DISRUPTIONS. ALTHOUGH WE STRIVE
                TO PROVIDE THE MOST RELIABLE WEBSITE REASONABLY POSSIBLE, INTERRUPTIONS AND DELAYS IN ACCESSING THE SITE
                ARE UNAVOIDABLE AND WE DISCLAIM ANY LIABILITY FOR DAMAGES RESULTING FROM SUCH PROBLEMS.
              </p>
              <br />
              <p>
                NOTWITHSTANDING THE FOREGOING, OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES, EMPLOYEES, AGENTS,
                REPRESENTATIVES AND THIRD-PARTY SERVICE PROVIDERS WITH RESPECT TO ANY AND ALL CLAIMS ARISING OUT OF YOUR
                USE OF THE SITE OR SERVICES, THE MATERIALS, AND ANY CONTENT OR SERVICES OBTAINED THROUGH THE SITE,
                WHETHER BASED ON WARRANTY, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, SHALL NOT EXCEED, IN THE
                AGGREGATE, ONE-HUNDRED DOLLARS ($100.00).
              </p>
              <br />
              <p>
                IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER
                CONSEQUENTIAL DAMAGES FOR ANY USE OF THE SITE, OR ON ANY OTHER HYPERLINKED WEBSITE, INCLUDING, WITHOUT
                LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA OR OTHERWISE, EVEN
                IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <br />
              <p>
                <b>15. Indemnity.</b>
              </p>
              <br />
              <p>
                You agree to indemnify, defend, and hold the Company, the Affiliates, its shareholders, subsidiaries,
                directors, officers and employees, harmless from any loss, claim, demand, or damage (actual and
                consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and
                undisclosed, including all third-party claims, liability, losses, expenses, damages, fines, penalties
                (including damages awarded or settled amounts and reasonable attorney’s fees), arising out of or in
                connection with (a) your breach of these Terms, (b) your relationship or dealings with your Payor, (c)
                your use of the Site and Services, (d) your receipt of the Payouts through any of the Payout Methods,
                (e) us acting on your instruction or that of your Payor (unless these arise due to our gross negligence
                or willful intent or fraud), and/or (f) any error made by you in providing information or instructions
                to the Company whether verbally or in writing, through the Pay Portal (unless these arise due to our
                gross negligence or willful default or fraud) or in connection with the Payout Methods.
              </p>
              <br />
              <p>
                <b>16. Notice.</b>
              </p>
              <br />
              <p>All notices to the Company must be sent by postal mail to PO Box 307, New Hope, PA 18938.</p>
              <br />
              <p>
                <b>17. Injunctive Relief.</b>
              </p>
              <br />
              <p>
                You acknowledge that we may be irreparably damaged if these Terms are not specifically enforced, and
                damages at law would be an inadequate remedy. Therefore, in the event of a breach or threatened breach
                of any provision of these Terms by you, we shall be entitled, without prejudice to any other rights and
                remedies that may be sought under the mandatory arbitration provision of these Terms, to an injunction
                restraining such breach or threatened breach, without being required to show any actual damage or to
                post an injunction bond, and/or to a decree for specific performance of the provisions of these Terms.
                For purposes of this Section, you agree that any action or proceeding with regard to such injunction
                restraining such breach or threatened breach shall be brought in the state or federal courts located in
                Pennsylvania. You consent to the jurisdiction of such court and waive any objection to the laying of
                venue of any such action or proceeding in such court. You agree that service of any court paper may be
                effected on such party by mail or in such other manner as may be provided under applicable laws, rules
                of procedure or local rules.
              </p>
              <br />
              <p>
                <b>18. MANDATORY ARBITRATION AND CLASS ACTION AND JURY TRIAL WAIVER.</b>
              </p>
              <br />
              <p>
                Most concerns can be resolved quickly and to your satisfaction by contacting us as set forth in the
                <b>“Questions”</b> section below.
              </p>
              <br />
              <p>
                In the event that we are not able to resolve a dispute, and with the exception of the claims for
                injunctive relief by us as described above and to the extent allowed by law, you hereby agree that
                either you or we may require any dispute, claim, or cause of action (“<b>Claim</b>”) between you and us
                or any third parties arising out of use of the Site, the Services, and any other actions with us
                (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory) to be
                arbitrated on an individual (non-class) basis. However, both parties retain the right to seek relief in
                a small claims court (or a state court equivalent) for a Claim within the scope of its jurisdiction so
                long as the small claims action does not seek to certify a class, combine the claims of multiple
                persons, recover damages in excess of the limit for a small claim under applicable state law or is not
                transferred, removed, or appealed from small claims court to any different court. Additionally, if you
                are a California resident, you retain the right to obtain public injunctive relief from any court with
                proper jurisdiction.
              </p>
              <br />
              <p>
                <b>
                  THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS VERY LIMITED.
                  ADDITIONALLY, ANY ARBITRATION OF A CLAIM WILL BE ON AN INDIVIDUAL BASIS, AND, THEREFORE, YOU
                  UNDERSTAND AND AGREE THAT YOU ARE WAIVING THE RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS
                  MEMBER IN A CLASS ACTION LAWSUIT. AS PART OF THIS WAIVER, YOU AGREE THAT YOU WAIVE THE RIGHT TO ACT AS
                  A PRIVATE ATTORNEY GENERAL IN AN ARBITRATION; THAT EXCEPT AS OTHERWISE PROVIDED IN THIS ARBITRATION
                  AGREEMENT, CLAIMS BROUGHT BY OR AGAINST YOU MAY NOT BE JOINED OR CONSOLIDATED WITH CLAIMS BROUGHT BY
                  OR AGAINST ANY OTHER PERSON; AND THE ARBITRATOR SHALL HAVE NO AUTHORITY TO CONDUCT A CLASS-WIDE
                  ARBITRATION, PRIVATE ATTORNEY GENERAL ARBITRATION OR MULTIPLE-PARTY ARBITRATION.
                </b>
              </p>
              <br />
              <p>
                You and we agree that your use of the Services involves interstate commerce, and that this arbitration
                agreement shall be interpreted and enforced in accordance with the Federal Arbitration Act (FAA) set
                forth in Title 9 of the U.S. Code to the fullest extent possible, notwithstanding any state law to the
                contrary, regardless of the origin or nature of the Claims at issue. The arbitrator must follow, to the
                extent applicable: (a) the substantive law of the state in which we entered into the transaction giving
                rise to this arbitration agreement; (b) the applicable statutes of limitations; and (c) claims of
                privilege recognized at law. The arbitrator will not be bound by federal, state or local rules of
                procedure and evidence or by state or local laws concerning arbitration proceedings.
              </p>
              <br />
              <p>
                If either you or we elect to arbitrate a Claim, the dispute shall be resolved by binding arbitration
                administered under the applicable rules of the American Arbitration Association (<b>“AAA”</b>). Either
                you or we may elect to resolve a particular Claim through arbitration, even if the other party has
                already initiated litigation in court related to the Claim, by: (i) making written demand for
                arbitration upon the other party, (ii) initiating arbitration against the other party, or (iii) filing a
                motion to compel arbitration in court.
              </p>
              <br />
              <p>
                If this is a consumer-purpose transaction, the applicable rules will be the AAA’s Consumer Arbitration
                Rules. The applicable AAA rules and other information about arbitrating a claim under AAA, including how
                to submit a dispute to arbitration, may be obtained by visiting its website at{' '}
                <a href="https://www.adr.org/">https://www.adr.org/</a> or by calling 1-800-778-7879. If AAA will not
                serve as the administrator of the arbitration, and you and we cannot then agree upon a substitute
                arbitrator, you and we shall request that a court with proper jurisdiction appoint an arbitrator.
                However, we will abide by the applicable AAA rules regardless of the forum. Arbitration shall be
                conducted in the county and state where you accepted these Terms, you reside, or another reasonably
                convenient place to you as determined by the arbitrator, unless applicable laws require another
                location. Judgment on the award rendered by the arbitrator may be entered in any court having
                jurisdiction thereof. Except as provided in applicable statutes, the arbitrator’s award is not subject
                to review by the court and it cannot be appealed. The parties will have the option to request and
                receive a statement of reasons for the arbitration award.
              </p>
              <br />
              <p>
                If you elect to file the arbitration, and this is a consumer-purpose transaction, you will pay the
                filing fee to the extent required by AAA’s Consumer Arbitration Rules but not to exceed the cost of
                filing a lawsuit. Any amount above what it would cost you to file a lawsuit, we will pay. All other
                arbitration fees and expenses shall be allocated to us according to AAA rules. Except for the
                arbitration fees and expenses, each party shall pay its own costs and fees incurred (including
                attorneys’ fees), unless the arbitrator allocates them differently in accordance with applicable law.
                This paragraph applies only if this is a consumer-purpose transaction.
              </p>
              <br />
              <p>
                Notwithstanding anything to the contrary in these Terms, and except as otherwise set forth in this
                paragraph, the agreement to arbitration may be amended by us only upon advance notice to you. If we make
                any amendment to this agreement to arbitration (other than renumbering the agreement to align with any
                other amendment to the Terms) in the future, that amendment shall not apply to any claim that was filed
                in a legal proceeding or action against us prior to the effective date of the amendment. The amendment
                shall apply to all other Claims governed by this agreement to arbitration that have arisen or may arise
                between you and us. However, we may amend this agreement to arbitration and not provide you notice; in
                that case, the amendments will not apply to you and the agreement to arbitration contained in these
                Terms to which you agreed will continue to apply to you and us as if no amendments were made.
              </p>
              <br />
              <p>
                If any part of this arbitration provision is invalid, all other parts of it remain valid. However, if
                the class action limitation is invalid, then this arbitration provision is invalid in its entirety,
                provided that the remaining Terms shall remain in full force and effect. This arbitration provision will
                survive the termination of your use of the Site, the Services, and any other actions with us.
              </p>
              <br />
              <p>
                <b>
                  You may reject this arbitration provision within thirty (30) days of accepting the Terms by emailing
                  us at <a href="mailto:hello@theinsighters.com">hello@theinsighters.com</a> and including in the
                  subject line “Rejection of Arbitration Provision.
                </b>
              </p>
              <br />
              <p>
                <b>19. Warranty Disclaimer.</b>
              </p>
              <br />
              <p>
                You agree that the Site and Services are available on an “as is” basis, without any warranty, and that
                you use the Site and Services at your own risk. The Materials have not been verified or authenticated in
                whole or in part by us, and they may include inaccuracies, typographical, or other errors. We do not
                warrant the accuracy or timeliness of the Materials contained on this Site. We have no liability for any
                errors or omissions in the Materials, whether provided by us, our licensors or suppliers or other users.
              </p>
              <br />
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW AND EXCEPT AS OTHERWISE PROVIDED HEREIN OR ON THE SITE, THE
                INFORMATION AND SERVICES OFFERED ON OR THROUGH THE SITE AND ANY REFERENCED THIRD-PARTY SITE ARE PROVIDED
                “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. ANY THIRD-PARTY GOODS OR SERVICES
                PROVIDED ARE SUPPLIED AS A CONVENIENCE TO YOU AND DO NOT CONSTITUTE SPONSORSHIP, AFFILIATION,
                PARTNERSHIP, OR ENDORSEMENT. TO THE FULLEST EXTENT ALLOWED BY LAW, WE DISCLAIM ALL EXPRESS AND IMPLIED
                WARRANTIES, INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                AND NON-INFRINGEMENT.
              </p>
              <br />
              <p>
                TO THE FULLEST EXTENT ALLOWED BY LAW, WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR
                THE RESULTS OF THE USE OF THE SITE, THE MATERIALS, ANY CONTENT, OR OTHER POSTED MATERIALS ON THE SITE IN
                TERMS OF ITS CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR OTHERWISE.
              </p>
              <br />
              <p>
                BY PROVIDING THE SERVICES ON THE SITE, WE DO NOT IN ANY WAY PROMISE THAT THE SERVICES WILL REMAIN
                AVAILABLE TO YOU. WE ARE ENTITLED TO TERMINATE ALL OR PART OF ANY OF THE SITE AT ANY TIME, IN OUR SOLE
                DISCRETION WITHOUT NOTICE TO YOU.
              </p>
              <br />
              <p>
                <b>20. Electronic Signatures and Notices</b>
              </p>
              <br />
              <p>
                Certain activities on the Services may require you to make an electronic signature. You understand and
                accept that an electronic signature has same legal rights and obligations as a physical signature. If
                you have an Account, you agree that we may provide you any and all required notices electronically
                through your Account or other electronic means. You agree that we are not responsible for any delivery
                fees charged to you as a result of your receipt of our electronic notices.
              </p>
              <br />
              <p>
                <b>21. Governing Law.</b>
              </p>
              <br />
              <p>
                These Terms are governed by Pennsylvania law, without giving effect to conflicts of law principles. You
                agree that, to submit to the exclusive jurisdiction of the state and federal courts located in
                Philadelphia, Pennsylvania in circumstances where these Terms permit litigation in court.
              </p>
              <br />
              <p>
                <b>22. Miscellaneous</b>
              </p>
              <br />
              <p>
                <u>Assignment</u>. We may assign, transfer, delegate, or otherwise hypothecate our rights under these
                Terms in our sole discretion.
              </p>
              <br />
              <p>
                <u>Waiver</u>. If we fail to enforce a provision of these Terms, you agree that such a failure does not
                constitute a waiver to enforce the provision (or any other provision hereunder).
              </p>
              <br />
              <p>
                <u>Severability</u>. If any provision of these Terms is held or made invalid, the invalidity does not
                affect the remainder of these Terms. We reserve all rights not expressly granted in these Terms and
                disclaim all implied licenses.
              </p>
              <br />
              <p>
                <u>Headings</u>. Provision and section headings are for convenience of reference only and shall not
                affect the interpretation of these Terms.
              </p>
              <br />
              <p>
                <u>Typographical Errors</u>. Information on the Site may contain technical inaccuracies or typographical
                errors. We attempt to make the Site’s postings as accurate as possible, but we do not warrant the
                content of the Site is accurate, complete, reliable, current, or error-free.
              </p>
              <br />
              <p>
                <u>Limitation on Action</u>. You hereby waive any and all rights to bring any claim or action related to
                the Site and Services beyond one (1) year after the first occurrence of the kind of act, event,
                condition or omission upon which such claim or action is based.
              </p>
              <br />
              <p>
                <u>Pay Portal Services</u>. Your use of the Pay Portal services are governed by the Pay Portal Terms
                <a href="/pay-portal-terms">here</a>.
              </p>
              <br />
              <p>
                <b>23. Questions</b>
              </p>
              <br />
              <p>
                If you have any questions or comments about these Terms or this Site, please contact us by email
                at&nbsp;
                <u>hello@theinsighters.com</u>.
              </p>
            </Grid>
          </Grid>
        </div>
      </Page>
      <br />
      <Footer />
    </>
  );
};

export default TermsOfUse;
