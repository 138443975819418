import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import useStyles from '../constants/redesignStyles';
import HoverButton from './HoverButton';
import { Opportunity } from '../../opportunities/model';

// Put the dots on the right side

interface OpportunityScrollerProps {
  hoverButtonText: string;
  opportunities: Opportunity[];
}

const OpportunityScroller: React.FC<OpportunityScrollerProps> = (props) => {
  const classes = useStyles();

  const { hoverButtonText, opportunities } = props;

  const [imageIndex, setImageIndex] = useState(0);

  const handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? opportunities.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex === opportunities.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Grid container className={classes.opportunityScroller}>
      <Grid
        item
        xs={12}
        style={{
          position: 'relative',
          width: '625px',
          height: '274px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 30,
          overflow: 'hidden',
        }}
      >
        <img src={opportunities[imageIndex]?.s3_image_url} alt="Opportunity" width="100%" height="auto" />

        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(45deg, rgba(84,93,120,1), 25%, rgba(59,66,85,0))',
            borderRadius: 30,
            zIndex: 1,
          }}
        />

        <Typography
          style={{
            fontSize: '18px',
            color: 'white',
            fontFamily: 'Crimson Text',
            fontWeight: 600,
            position: 'absolute',
            bottom: '13px',
            left: '20px',
            zIndex: 2,
          }}
        >
          {opportunities[imageIndex]?.name}
        </Typography>

        <Grid className={classes.arrowButtons} style={{ zIndex: 2 }}>
          <button type="button" onClick={handlePrevImage}>
            <NavigateBefore />
          </button>
          <button type="button" onClick={handleNextImage}>
            <NavigateNext />
          </button>
        </Grid>
      </Grid>

      <Grid style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Grid item xs={8}>
          <HoverButton text={hoverButtonText} />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {opportunities.map((_, index) => (
            <div
              key={index}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                border: '1px solid black',
                color: 'black',
                backgroundColor: index === imageIndex ? 'black' : 'white',
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpportunityScroller;
