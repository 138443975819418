import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import USMap from '../components/USMap';
import SectionOrnament from '../../common/SectionOrnament';
import useStyles from '../../constants/redesignStyles';
import MapRectangle from '../../../../assets/redesignAssets/staticImages/MapRectangle.png';

const locationData = [
  {
    num: '18',
    label: 'States coverage',
  },
  {
    num: '268',
    label: 'Locations',
  },
  {
    num: '4577',
    label: 'Active Insighters',
  },
];

const LocationInfoField: React.FC<{ num: string; label: string }> = ({ num, label }) => {
  return (
    <p style={{ fontWeight: 600 }}>
      <span style={{ color: 'green' }}>{num}</span> {label}
    </p>
  );
};

const MapSection: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${MapRectangle})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: '10%',
      }}
    >
      <Grid item xs={5}>
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={8}>
            <SectionOrnament text="The Insighters Coverage" />
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.h2}>Explore Our</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.h2}>Nationwide</Typography>
          </Grid>
          <Grid item xs={8} style={{ marginBottom: 20 }}>
            <Typography className={classes.h2}>Network</Typography>
          </Grid>
          {locationData.map((location, index) => (
            <React.Fragment key={index}>
              <Grid item xs={8}>
                <LocationInfoField num={location.num} label={location.label} />
                {index < locationData.length - 1 && <hr style={{ width: '100%' }} />}{' '}
              </Grid>
              {/* Add the horizontal line between items, but not after the last item */}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={7}>
        <USMap />
      </Grid>
    </Grid>
  );
};

export default MapSection;
