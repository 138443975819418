import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Button } from '@material-ui/core';
import { Box, Grid } from '@mui/material';
import Logo from '../../../assets/logo.png';
import Footer from '../../navigation/components/Footer';
import FirstRowIcon from '../../../assets/about/about1icon.png';
import ArrowIcon from '../../../assets/about/arrowicon.png';
import SecondRowIcon from '../../../assets/about/about2icon.png';
import PlusIcon from '../../../assets/about/plusicon.png';
import FourthRowIcon from '../../../assets/about/about4icon.png';
import RegisterIcon from '../../../assets/homepage/register.png';
import ShareIcon from '../../../assets/homepage/share.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    callToAction: {
      fontSize: '24px',
      marginBottom: 10,
      lineHeight: '24px',
      fontFamily: 'Cooper',

      [theme.breakpoints.up(600)]: {
        fontSize: '36px',
        lineHeight: '44px',
      },
    },

    callToActionContainer: {
      marginLeft: '20px',
      marginRight: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '900px',
    },

    callToActionImageContainer: {
      maxWidth: '125px',

      [theme.breakpoints.up(500)]: {
        maxWidth: '200px',
      },
    },

    callToActionTextContainer: {
      marginRight: '60px',

      [theme.breakpoints.up(500)]: {
        marginRight: '100px',
      },
    },
  }),
);

const AboutPage: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  return (
    <div
      className="Polaris-Page Polaris-Page--fullWidth"
      style={{ position: 'relative', minHeight: '100vh', padding: 0 }}
    >
      <Box display={{ xs: 'block', sm: 'none' }}>
        <Grid container justifyContent="center" style={{ margin: '40px 0 20px' }}>
          <Grid item xs={11} md={8}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <img alt="logo" src={FirstRowIcon} style={{ width: '100%', margin: '0' }} />
              </Grid>
              <Grid item xs={9}>
                <h1
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '30px',
                    lineHeight: '30px',
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  You
                </h1>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '16px',
                    lineHeight: '16px',
                    textAlign: 'left',
                  }}
                >
                  Are you a patient leader? Healthcare professional? Family caregiver?
                  <br />
                  <br />
                  Do you utilize the healthcare system &mdash; whether it&apos;s a lot or a little bit?
                  <br />
                  <br />
                  If you have any related experiences or stories, then{' '}
                  <span style={{ color: '#72a295', fontWeight: 600 }}>you</span> are an Insighter!
                </h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} style={{ display: 'inline-block', textAlign: 'center' }}>
            <img alt="logo" src={PlusIcon} style={{ width: '50px', margin: '0px auto' }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ margin: '30px 0' }}>
          <Grid item xs={11} md={8}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <img alt="logo" src={SecondRowIcon} style={{ width: '100%', margin: '0 0 0 -5px' }} />
              </Grid>
              <Grid item xs={9}>
                <h1
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '30px',
                    lineHeight: '30px',
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  The Insighters®
                </h1>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '16px',
                    lineHeight: '16px',
                    textAlign: 'left',
                  }}
                >
                  <span style={{ color: '#72a295', fontWeight: 600 }}>The Insighters® platform</span> matches you with
                  paid research opportunities that are tailored to your insights.
                  <br />
                  <br />
                  We make it easier for you to be at the forefront of the industry and share your stories.
                </h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} style={{ display: 'inline-block', textAlign: 'center' }}>
            <img alt="logo" src={ArrowIcon} style={{ width: '35px', margin: '0px 0' }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ margin: '30px 0' }}>
          <Grid item xs={11} md={7}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <div style={{ textAlign: 'center' }}>
                  <img alt="logo" src={RegisterIcon} style={{ width: '70%', margin: '0' }} />
                </div>
              </Grid>
              <Grid item xs={9}>
                <h1
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '30px',
                    lineHeight: '30px',
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  Together
                </h1>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '16px',
                    lineHeight: '16px',
                    textAlign: 'left',
                  }}
                >
                  <span style={{ color: '#72a295', fontWeight: 600 }}>Together</span>, we will bridge the gap between
                  your insights and the companies who need your input.
                  <br />
                  <br />
                  Your thoughts are crucial to answering those questions.
                </h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {location.pathname === '/about' && (
          <>
            <Grid container justifyContent="center">
              <div className={classes.callToActionContainer}>
                <div className={classes.callToActionTextContainer}>
                  <h6 className={classes.callToAction}>
                    Your story.
                    <br />
                    Your experience.
                    <br />
                    Your insights.
                  </h6>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#E7AC34',
                      height: 40,
                      width: 'auto',
                      fontSize: '16px',
                      borderRadius: '4px',
                      marginTop: 5,
                      textTransform: 'capitalize',
                      fontFamily: 'Cooper',
                      color: 'white',
                    }}
                    onClick={() => history.push('/sign-up')}
                  >
                    SIGN UP
                  </Button>
                </div>
                <div className={classes.callToActionImageContainer}>
                  <img alt="lightbulb" src={ShareIcon} style={{ width: '100%' }} />
                </div>
              </div>
            </Grid>
            <br />
          </>
        )}
        <hr style={{ height: 0, border: '1px solid #ccc', width: '66%', margin: '0 auto' }} />
        <Grid container justifyContent="center" style={{ margin: '40px 0 20px' }}>
          <Grid item xs={11} md={8}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '16px',
                    lineHeight: '18px',
                    textAlign: 'left',
                  }}
                >
                  Want to see more of The Insighters®? Follow us on social media!
                  <br />
                  <br />
                  Facebook:{' '}
                  <a
                    href="https://www.facebook.com/TheInsightersdotcom/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#ebac34', fontWeight: 600 }}
                  >
                    The Insighters
                  </a>
                  <br />
                  Instagram:{' '}
                  <a
                    href="https://www.instagram.com/theinsighters_/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#ebac34', fontWeight: 600 }}
                  >
                    @theinsighters_
                  </a>
                  <br />
                  Twitter:{' '}
                  <a
                    href="https://twitter.com/theinsighters_"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#ebac34', fontWeight: 600 }}
                  >
                    @theinsighters_
                  </a>
                  <br />
                  <br />
                  Do you have stories, experiences, or insights in another industry? As we expand outside of healthcare,
                  we want to partner with you!
                  <br />
                  <br />
                  Interested in partnering with us? Contact us{' '}
                  <a href="mailto:hello@theinsighters.com" style={{ color: '#ebac34', fontWeight: 600 }}>
                    here
                  </a>
                  .
                  <br />
                </h6>
              </Grid>
              <Grid item xs={3}>
                <img alt="logo" src={FourthRowIcon} style={{ width: '100%', margin: '0' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Grid container justifyContent="center">
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <img alt="logo" src={Logo} style={{ width: '100%', margin: '40px 0 10px', maxWidth: '400px' }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ margin: '40px 0 20px' }}>
          <Grid item xs={11} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <img alt="logo" src={FirstRowIcon} style={{ width: '100%', maxWidth: '125px' }} />
              </Grid>
              <Grid item xs={9}>
                <h1
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '38px',
                    lineHeight: '40px',
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  You
                </h1>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '22px',
                    lineHeight: '32px',
                    textAlign: 'left',
                  }}
                >
                  Are you a patient leader? Healthcare professional? Family caregiver? Do you utilize the healthcare
                  system &mdash; whether it&apos;s a lot or a little bit? If you have any related experiences or
                  stories, then <span style={{ color: '#72a295', fontWeight: 600 }}>you</span> are an Insighter!
                </h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} style={{ display: 'inline-block', textAlign: 'center' }}>
            <img alt="logo" src={PlusIcon} style={{ width: '50px', margin: '0px auto' }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ margin: '30px 0' }}>
          <Grid item xs={11} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <img alt="logo" src={SecondRowIcon} style={{ width: '100%', margin: '0 0 0 -5px' }} />
              </Grid>
              <Grid item xs={9}>
                <h1
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '38px',
                    lineHeight: '40px',
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  The Insighters®
                </h1>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '22px',
                    lineHeight: '32px',
                    textAlign: 'left',
                  }}
                >
                  <span style={{ color: '#72a295', fontWeight: 600 }}>The Insighters® platform</span> matches you with
                  paid research opportunities that are tailored to your insights. We make it easier for you to be at the
                  forefront of the industry and share your stories.
                </h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} style={{ display: 'inline-block', textAlign: 'center' }}>
            <img alt="logo" src={ArrowIcon} style={{ width: '35px', margin: '0px 0' }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ margin: '30px 0' }}>
          <Grid item xs={11} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={3}>
                <div style={{ textAlign: 'center' }}>
                  <img alt="logo" src={RegisterIcon} style={{ width: '70%', margin: '0' }} />
                </div>
              </Grid>
              <Grid item xs={9}>
                <h1
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '38px',
                    lineHeight: '40px',
                    textAlign: 'left',
                    marginBottom: '10px',
                  }}
                >
                  Together
                </h1>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '22px',
                    lineHeight: '32px',
                    textAlign: 'left',
                  }}
                >
                  <span style={{ color: '#72a295', fontWeight: 600 }}>Together</span>, we will bridge the gap between
                  your insights and the companies who need your input. Your thoughts are crucial to answering those
                  questions.
                </h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {location.pathname === '/about' && (
          <>
            <Grid container justifyContent="center">
              <div className={classes.callToActionContainer}>
                <div className={classes.callToActionTextContainer}>
                  <h6 className={classes.callToAction}>
                    Your story.
                    <br />
                    Your experience.
                    <br />
                    Your insights.
                  </h6>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#E7AC34',
                      height: 40,
                      width: 'auto',
                      fontSize: '16px',
                      borderRadius: '4px',
                      marginTop: 5,
                      textTransform: 'capitalize',
                      fontFamily: 'Cooper',
                      color: 'white',
                    }}
                    onClick={() => history.push('/sign-up')}
                  >
                    SIGN UP
                  </Button>
                </div>
                <div className={classes.callToActionImageContainer}>
                  <img alt="lightbulb" src={ShareIcon} style={{ width: '100%' }} />
                </div>
              </div>
            </Grid>
            <br />
          </>
        )}
        <hr style={{ height: 0, border: '1px solid #ccc', width: '66%', margin: '0 auto' }} />
        <Grid container justifyContent="center" style={{ margin: '40px 0 20px' }}>
          <Grid item xs={11} md={6}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '22px',
                    lineHeight: '32px',
                    textAlign: 'left',
                  }}
                >
                  Want to see more of The Insighters®? Follow us on social media!
                </h6>
                <br />
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '20px',
                    lineHeight: '26px',
                    textAlign: 'left',
                  }}
                >
                  Facebook:{' '}
                  <a
                    href="https://www.facebook.com/TheInsightersdotcom/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#ebac34', fontWeight: 600 }}
                  >
                    The Insighters
                  </a>
                  <br />
                  Instagram:{' '}
                  <a
                    href="https://www.instagram.com/theinsighters_/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#ebac34', fontWeight: 600 }}
                  >
                    @theinsighters_
                  </a>
                  <br />
                  Twitter:{' '}
                  <a
                    href="https://twitter.com/theinsighters_"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#ebac34', fontWeight: 600 }}
                  >
                    @theinsighters_
                  </a>
                </h6>
                <br />
                <h6
                  style={{
                    fontFamily: 'Cooper',
                    fontSize: '22px',
                    lineHeight: '32px',
                    textAlign: 'left',
                  }}
                >
                  Do you have stories, experiences, or insights in another industry? As we expand outside of healthcare,
                  we want to partner with you!
                  <br />
                  <br />
                  Interested in partnering with us? Contact us{' '}
                  <a href="mailto:hello@theinsighters.com" style={{ color: '#ebac34', fontWeight: 600 }}>
                    here
                  </a>
                  .
                </h6>
              </Grid>
              <Grid item xs={3}>
                <img alt="logo" src={FourthRowIcon} style={{ width: '100%', margin: '0' }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <br />
      <Footer />
    </div>
  );
};

export default AboutPage;
