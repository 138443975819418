import React from 'react';
import { Page, DataTable, Card } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import Footer from '../../navigation/components/Footer';

const PayPortalTerms: React.FunctionComponent = () => {
  return (
    <>
      <Page fullWidth title="">
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <Grid container>
            <Grid item md={8} xs={12}>
              <h2 style={{ fontSize: 28, fontFamily: 'Cooper', margin: '40px 0' }}>Pay Portal Terms</h2>
              <br />
              <h3 style={{ fontSize: 22, fontFamily: 'Cooper', margin: '40px 0' }}>Table of Contents</h3>
              <ol>
                <li>Introduction.</li>
                <li>Eligibility.</li>
                <li>Registration & Account.</li>
                <li>Restrictions.</li>
                <li>Identity Verification and Third-Party Permissions.</li>
                <li>Your Responsibilities.</li>
                <li>Fees.</li>
                <li>Currency.</li>
                <li>Pay Portal Content.</li>
                <li>Warranty Disclaimer.</li>
                <li>Limitation of Liability.</li>
                <li>Indemnity.</li>
                <li>Notices.</li>
                <li>Termination.</li>
                <li>Closing Your Pay Portal.</li>
                <li>Communication.</li>
                <li>Miscellaneous.</li>
                <li>Questions.</li>
                <li>Payout Schedule</li>
              </ol>
              <br />
              <p>
                Linguis-Techs, Inc. d/b/a Sommer Consulting (the “<b>Company</b>,” “<b>we</b>,” “<b>our</b>,” or “
                <b>us”</b>), connects you with payment services and related functionality and technology through this
                Pay Portal (collectively, “<b>Services</b>”). These Pay Portal Terms (“<b>Terms</b>”) apply to your
                access and use of the Services. For purposes of these Terms, “<b>you”</b> or “<b>your”</b> means the
                person accessing the Services and any persons that allow others to provide information about themselves
                to us. Any terms not defined herein shall have the meaning in the Terms of Use&nbsp;
                <a href="/terms-of-use">here</a>.
              </p>
              <br />
              <p>
                THESE TERMS CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND US. PLEASE READ CAREFULLY THROUGH ALL
                SECTIONS OF THESE TERMS. YOUR ACCESS TO AND USE OF THE SERVICES IS SUBJECT TO THESE TERMS AND ALL
                APPLICABLE LAWS AND WE RESERVE THE RIGHT TO TERMINATE YOUR ACCESS TO THE SERVICES IF YOU VIOLATE THESE
                TERMS. BY CLICKING ON LINKS WITHIN THE SERVICES OR WEBPAGES BEYOND THE SERVICES’ HOMEPAGE OR BY CLICKING
                ON A BOX OR ICON YOU AGREE TO THESE TERMS WHETHER OR NOT YOU COMPLETE A TRANSACTION WITH US AND WHETHER
                OR NOT YOU COMPLETE YOUR TRANSACTION ON THE SERVICES OR THROUGH OTHER CHANNELS, SUCH AS BY TELEPHONE,
                EMAIL, FACSIMILE, OR OTHERWISE. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THE
                SERVICES, OR ANY INFORMATION CONTAINED ON THROUGH THE SERVICES.
              </p>
              <br />
              <p>
                By agreeing to these Terms, you accept and agree to the Terms of Use <a href="/terms-of-use">here</a>,
                which are incorporated herein by reference. If you do not agree to the Terms of Use, then you must not
                access or use the Pay Portal.
              </p>
              <br />
              <p>
                Besides these Terms, we also publish a Privacy Policy <a href="/privacy-policy">here</a>. Although it is
                not part of these Terms, we encourage you to read it to better understand how you can update, manage,
                access, and delete your information.
              </p>
              <br />
              <p>
                <b>1. Introduction</b>
              </p>
              <br />
              <p>
                1.1 The Pay Portal is the interface that may facilitate certain payments which you will receive from the
                Company (the “<b>Payouts”</b> and each, a “<b>Payout”</b>). Payout capabilities are available in
                multiple currencies and can be implemented using a variety of different methods. You may be able to
                receive Payouts from and as directed by the Company through one of several methods, each as determined
                by the Company, such as (but not limited to) a transfer to your bank account and other payment methods
                (each a “Payout Method” and collectively “<b>Payout</b> <b>Methods”</b>), all as supported and made
                available from time to time. Some <b>Payout</b>
                <b>Methods may</b> require access to the Pay Portal. The Payouts may comprise money remittance and/or
                the issuance of stored value. The Payouts are delivered through local affiliates, including members of
                the PayPal group of companies (each an “<b>Affiliate”</b>) around the world, and a network of banks and
                financial service providers (“<b>Network</b> <b>Partners”</b>). The Payouts are subject to additional
                terms and conditions as set forth in the Payout Schedule in these Terms. These Terms govern your receipt
                of Payouts through the Payout Methods.
              </p>
              <br />
              <p>
                1.2 We reserve the right to change these Terms at any time by posting a revised version in the Pay
                Portal. The revised version will be effective at the time we post it to the Pay Portal. You are
                responsible for regularly reviewing the Terms. Your continued ability to access the Pay Portal, and/or
                otherwise using the Payout Methods or receiving the Payouts after the date of any changes constitutes
                your acceptance of any new or changed Terms. If you do not wish to accept the new or changed Terms, you
                must cease accessing the Pay Portal and discontinue receiving the Payouts.
              </p>
              <br />
              <p>
                <b>2. Eligibility</b>
              </p>
              <br />
              <p>
                To be eligible to use the Pay Portal to receive Payouts, you must: (a) be at or above an age of majority
                in your country of location; (b) be located in a country supported by us and our Network Partners; (c)
                register your use of the Pay Portal by providing current, complete and accurate information as prompted
                by the registration process on the Pay Portal; and (d) agree to these Terms,
                <a href="/terms-of-use">Terms of Use</a>, and any terms and conditions presented in the Pay Portal.
              </p>
              <br />
              <p>
                <b>3. Registration & Account</b>
              </p>
              <br />
              <p>
                Multiple registrations are prohibited. You may register only once, and each user must maintain a
                separate registration. If we detect multiple active registrations for a single user, we reserve the
                right to merge or terminate the registrations and refuse to permit you to receive Payouts without
                notification to you.
              </p>
              <br />
              <p>
                <b>4. Restrictions</b>
              </p>
              <br />
              <p>
                4.1 The Services are subject to certain limits and availability. Therefore, not all Payouts Methods may
                be made available to you.
              </p>
              <br />
              <p>
                4.2 We are not responsible for and do not have any liability with respect to any of the products,
                services, or activities for which you receive a Payout via the Pay Portal, including but not limited to
                any dispute as to any amount of funds paid or payable to you by the Company or otherwise. Additional
                restrictions as to the receipt of the Payouts are set forth in the Payout Schedule.
              </p>
              <br />
              <p>
                <b>5. Identity Verification and Third-Party Permissions</b>
              </p>
              <br />
              <p>
                5.1 You acknowledge and agree that we may, at any time, as a condition of receiving the Payouts and in
                accordance with our Privacy Policy available here, ask you for information that will allow us to
                reasonably identify you, require you to take steps to confirm the accuracy and/or completeness of
                information you have provided to us, and/or verify your information against third-party databases or
                through other means for purposes of providing the Payouts to you and/or as may be required to perform
                required screening, monitoring, and investigation of your Pay Portal activities in order to comply with
                applicable laws. We may require you to provide your name, address, date of birth, government
                identification number, taxpayer identification number, phone number, email address, and other
                information that will allow us to identify you. We may require you to provide documentation, which may
                include your passport, driver’s license, or other government issued photo identification document. We
                may also contact you if we have additional questions. You agree that we reserve the right to directly
                assess your fees related to our identity verification, screening, monitoring, investigations and similar
                activities in connection with your Pay Portal and/or receipt of the Payouts. We reserve the right to
                close, suspend, limit or deny access to the Payouts or the Pay Portal in the event we are unable to
                obtain this information, for any reason or for no reason, in our sole discretion.
              </p>
              <br />
              <p>
                5.2 You agree to the disclosure of your information to third-party verification service(s) providers,
                Network Partners, government agencies, and other third parties for identity verification purposes, to
                meet anti-money laundering, anti-terrorist financing, transaction monitoring, and suspicious activity
                reporting requirements, or as otherwise required to provide the Payout Methods to you. You acknowledge
                and authorize that information concerning the location of the device you use to access the Pay Portal
                may be used to assess location, may be obtained at any time while logged into the Pay Portal, and such
                information may be used or disclosed as a part of the Payout Methods and to establish your eligibility
                to receive Payouts. Any third party to which your information is transmitted will be bound to maintain
                your confidentiality and may not use the information supplied for any unauthorized purpose other than to
                verify your identity, meet our legal, regulatory, risk requirements, provide the Payout Methods, or as
                otherwise described herein.
              </p>
              <br />
              <p>
                <b>6. Your Responsibilities</b>
              </p>
              <br />
              <p>
                6.1 You are responsible for maintaining adequate security and control of any and all IDs, usernames,
                passwords, personal identification numbers (PINs), or any other codes that you use to access the Pay
                Portal or to receive the Payouts. You expressly acknowledge and agree that failure to maintain the
                security of these items may result in unauthorized transactions and/or loss to you. We are not
                responsible for reimbursing you or otherwise paying you for any such loss. You agree to notify us
                immediately of any unauthorized use of your Pay Portal or any other breach of security. You will be
                responsible for any transfer of funds request received via your Pay Portal unless we are notified of
                unauthorized use in the manner set out in these Terms. You are urged to take appropriate safeguards
                before downloading information from the Pay Portal. We assume no responsibility for any damages to
                computer equipment or other property that may result from use of the Pay Portal or downloading anything
                from the Pay Portal.
              </p>
              <br />
              <p>
                6.2 Notwithstanding the foregoing, you are responsible for any and all activities, acts or omissions
                that occur in relation to your Pay Portal, including the accuracy of the information transmitted through
                your Pay Portal. You are responsible for your Internet connection or any other method that you use to
                connect to the Pay Portal. You represent that your email account and all information sent to and from
                your email account is secure and nobody has access to your email account except for you. You also
                acknowledge that granting permission to a third party to take specific actions on your behalf does not
                relieve you of any of your responsibilities under these Terms. Further, you acknowledge and agree that
                you will not hold us responsible for, and will defend and indemnify us from, any liability arising from
                the actions or inactions of this third party in connection with any permissions you grant.
              </p>
              <br />
              <p>
                6.3 You may not assign or transfer your right to utilize the Pay Portal to a third party or otherwise
                grant any third party a legal or equitable interest in connection with the Pay Portal.
              </p>
              <br />
              <p>
                6.4 You agree that you may not and will not use the Pay Portal: (a) for any purpose that is unlawful,
                fraudulent, deceitful, untruthful, misrepresentative, dishonest or related in any manner to unlawful,
                unethical or morally questionable activities or is directly or indirectly related to the foregoing; (b)
                if you are located, residing, working or conducting business in any country restricted by any of the
                following, which we may update from time to time without prior notification to you: (i) Special Economic
                Measures Act (Canada), the United Nations Act (Canada), the Freezing Assets of Corrupt Foreign Officials
                Act (Canada), the U.S. Office of Foreign Assets Controls’ (“OFAC”) Specially Designated Nationals and
                Blocked Persons List, or the U.S. Department of State’s Designated Foreign Terrorist Organizations List,
                as applicable and as, may be amended from time to time; or (ii) any applicable sanction, directive or
                other action by Foreign Affairs and International Trade Canada, OFSI, OFAC or the United Nations or any
                other Canadian, U.S, Australian, or European governmental authority regarding terrorism or money
                laundering; (c) for any illegal purposes including but not limited to fraud and money laundering; (d) in
                any attempt to abuse, exploit or circumvent any law or regulation; (e) in any manner that attempts to
                tamper, hack, modify, frame, &quot;deep link&quot; or otherwise bypass or attempt to bypass security,
                functionality, entry points or any other features of the Service or the Pay Portal; (f) directly or
                indirectly, either separately or as part of another service, attempt to redirect the Payouts; or (g) (h)
                for any purpose that we prohibit in our sole discretion or as otherwise prohibited by these Terms.
              </p>
              <br />
              <p>
                <b>7. Fees</b>
              </p>
              <br />
              <p>
                7.1 <u>Fees Related to Payout Methods</u>. Network Partners and other third parties facilitating the
                Payout Methods may impose additional fees on transactions, which may be deducted from available funds
                reflected in your Pay Portal balance. You will be responsible for any fees incurred by us related to a
                Payout rejected due to errors or omissions in the Payout Method details supplied by you. Any bank
                processing fees, currency exchange fees or intermediary fees which may be charged by your bank or any
                other payment provider not directly associated with us are your sole responsibility. We reserve the
                right to directly assess your fees and/or taxes related to our servicing and support of your Pay Portal
                arising out of applicable law, including but not limited to sales or use taxes, value-added taxes (VAT),
                goods and services taxes (GST). You are also responsible for SMS, data services, and any similar phone
                or internet service fees associated with your use of the Pay Portal via computer, mobile device or
                otherwise.
              </p>
              <br />
              <p>
                7.2 <u>Collection</u>. Fees payable by you may be: (a) deducted from the amount transferred in the
                specific transaction to which the fee is applicable, upon your execution of the transaction (for
                example, if you direct us to receive a Payout from the Company to your bank account through the Pay
                Portal, the amount transferred to your bank account will be the amount you specify, less the fee
                applicable to transfer funds to your local bank account); and/or (b) deducted automatically from
                available funds reflected in your Pay Portal. You hereby authorize us to deduct all fees from the
                amounts transferred and/or reflected in your Pay Portal, as applicable. Unredeemed Payouts may be
                subject to additional terms. If you are using Tremendous, then please review Tremendous’ Terms of
                Service here.
              </p>
              <br />
              <p>
                7.3 <u>Changes</u>. We reserve the right to change or otherwise adjust fees at the direction of an
                Issuer, Payor or otherwise at our sole discretion. We will publish updated fees in the Pay Portal. Fees
                will take effect immediately unless otherwise stated or as otherwise provided for in these Terms. If you
                have a question about any of the fees including any dispute involving any fee paid or payable by you for
                the Payout Methods, contact the Company directly.
              </p>
              <br />
              <p>
                <b>8. Currency</b>
              </p>
              <br />
              <p>
                All Payments are based on U.S. currency. If you wish to request a Payout in a different currency, please
                contact us. A foreign exchange fee and/or rate may apply. Payouts will only be provided in a currency of
                a country in which we provide our Services.
              </p>
              <br />
              <p>
                <b>9. Pay Portal Content</b>
              </p>
              <br />
              <p>
                9.1 <u>Copyright and Other Intellectual Property Rights</u>. The Pay Portal contains copyrighted
                material, trademarks and other proprietary and confidential information of the Company and others
                (collectively “<b>Proprietary</b> <b>Material”</b>), which may include, but shall not be limited to
                text, documents, information, data, articles, images, photographs, graphics, software, applications,
                video recordings, audio recordings, sounds, designs, features, and other materials (collectively with
                the Proprietary Material, “<b>Content”</b>). All Content is owned by the Company, its licensors and
                various third parties and is protected by applicable intellectual property rights including copyright,
                patent and trade-mark legislation and treaties. You agree not to modify, publish, copy, transmit,
                register or claim title to, participate in the transfer or sale of, create derivative works of, or in
                any way exploit, in whole or in part, any Content except for copying that occurs in the ordinary course
                of browsing the Internet and personal copies of Pay Portal information that you make for your personal
                use including for your own records. You agree to respect any copyright, trademark, patent and other
                proprietary rights contained in any Content on the Pay Portal. The only rights that you can have in
                Content outside of these Terms are those granted in writing by the owners with rights to the Content.
              </p>
              <br />
              <p>
                9.2 <u>Third-Party Links</u>. The links included within the Pay Portal may cause you to lLinked Sites
                Portal in order to gain access to other websites including those related to third-party services (“
                <b>Linked Sites</b>”). The Linked Sites are not under the control of us and we are not responsible for
                the content of any Linked Site, the services provided at any Linked Site, any link contained in a Linked
                Site, or any changes or updates to such sites unless otherwise noted on such sites. We provide these
                links to you only as a convenience. We may amend, add or delete links on the Pay Portal as we determine
                in our sole discretion. The inclusion of any link to any Linked Sites in our Pay Portal or any link on a
                third party’s site to our Pay Portal does not reflect an endorsement by us nor does it necessarily
                reflect any association with their operators. When you access Linked Sites, you do so at your own risk.
              </p>
              <br />
              <p>
                9.3 <u>Informational Purposes</u>. The Content is intended for information purposes only. Although we
                exercise reasonable efforts to ensure their quality and accuracy, there might be errors, or the
                information provided may not be complete, current, or applicable to your particular situation. We assume
                no liability or responsibility for any errors or omissions. You are responsible for evaluating the
                accuracy, completeness, and usefulness of any opinion, advice, or other content available through the
                Pay Portal or obtained from a Linked Site.
              </p>
              <br />
              <p>
                9.4 <u>No Endorsement</u>. No endorsement or approval of any third party or their advice, opinions,
                information, products or services is expressed or implied by the Pay Portal or Payout Methods including,
                without limitation, any endorsement or approval of any third party.
              </p>
              <br />
              <p>
                <b>10. Warranty Disclaimer</b>
              </p>
              <br />
              <p>
                The use of the Pay Portal and receipt of the Payouts is at your own risk. The Pay Portal, and any
                related information, content and/or materials are provided on an “as is” basis without warranties of any
                kind. We do not warrant the accuracy or timeliness of the contained on the Pay Portal. We have no
                liability for any errors or omissions in the Content, whether provided by us, our licensors or suppliers
                or other users.
              </p>
              <br />
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW AND EXCEPT AS OTHERWISE PROVIDED HEREIN OR THROUGH THE PAY PORTAL,
                THE INFORMATION AND SERVICES OFFERED ON OR THROUGH THE PAY PORTAL AND ANY REFERENCED THIRD-PARTY SITE
                ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. ANY THIRD-PARTY
                GOODS OR SERVICES PROVIDED ARE SUPPLIED AS A CONVENIENCE TO YOU AND DO NOT CONSTITUTE SPONSORSHIP,
                AFFILIATION, PARTNERSHIP, OR ENDORSEMENT. TO THE FULLEST EXTENT ALLOWED BY LAW, WE DISCLAIM ALL EXPRESS
                AND IMPLIED WARRANTIES, INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE, AND NON-INFRINGEMENT.
              </p>
              <br />
              <p>
                TO THE FULLEST EXTENT ALLOWED BY LAW, WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR
                THE RESULTS OF THE USE OF THE PAY PORTAL, ANY CONTENT, OR OTHER POSTED MATERIALS ON THE PAY PORTAL IN
                TERMS OF ITS CORRECTNESS, ACCURACY, TIMELINESS, RELIABILITY OR OTHERWISE.
              </p>
              <br />
              <p>
                BY PROVIDING THE SERVICES ON THE PAY PORTAL, WE DO NOT IN ANY WAY PROMISE THAT THE SERVICES WILL REMAIN
                AVAILABLE TO YOU. WE ARE ENTITLED TO TERMINATE ALL OR PART OF ANY OF THE PAY PORTAL AT ANY TIME, IN OUR
                SOLE DISCRETION WITHOUT NOTICE TO YOU.
              </p>
              <br />
              <p>
                You further agree, without limiting the generality of the foregoing, that we shall not be responsible or
                liable to you for: (a) any dispute as to any amounts paid or payable to you; (b) the Payout Methods for
                which you receive or intend to receive the Payouts regardless whether it is received via the Pay Portal;
                (c) unauthorized access to or alteration of your transmissions or data, any material or data sent or
                received or not sent or received, or any transactions entered into through the Pay Portal or in
                connection with the Payout Methods; (d) any loss due to payments to unintended users due to the input by
                you of incorrect information or for payments in incorrect amounts; (e) any service interruptions,
                including, but not limited to, system failures, power outages, or other interruptions that may affect
                processing, settlement, acceptance or disbursement in connection with the Pay Portal or Payout Methods;
                (f) any actions or inactions of any third party, including but not limited to those of any Network
                Partner or third-party provider; and/or, (g) the inability to perform our obligations because of factors
                beyond our control.
              </p>
              <br />
              <p>
                <b>11. Limitation of Liability</b>
              </p>
              <br />
              <p>
                WE CANNOT GUARANTEE THE SERVICES WILL BE AVAILABLE ONE HUNDRED PERCENT (100%) OF THE TIME BECAUSE
                PUBLICK NETWORKS, SUCH AS THE INTERNET, OCCASSIONALLY EXPERIENCE DISRUPTIONS. ALTHOUGH WE STRIVE TO
                PROVIDE THE MOST RELIABLE SERVICES REASONABLE POSSIBLE, INTERRUPTIONS AND DELAYS IN ACCESSING THE
                SERVICES ARE UNAVOIDABLE AND WE DISCLAIM ANY LIABILITY FOR DAMAGES RESULTING FROM SUCH PROBLEMS.
              </p>
              <br />
              <p>
                NOTWITHSTANDING THE FOREGOING, OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES, EMPLOYEES, AGENTS,
                REPRESENTATIVES AND THIRD-PARTY SERVICE PROVIDERS WITH RESPECT TO ANY AND ALL CLAIMS ARISING OUT OF YOUR
                USE OF THE PAY PORTAL, THE SERVICES, AND ANY CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, NEGLIGENCE,
                STRICT LIABILITY OR OTHERWISE, SHALL NOT EXCEED, IN THE AGGREGATE, ONE-HUNDRED DOLLARS ($100.00).
              </p>
              <br />
              <p>
                IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER
                CONSEQUENTIAL DAMAGES FOR ANY USE OF THE PAY PORTAL, SERVICES, OR ON ANY OTHER HYPERLINKED WEBSITE,
                INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA
                OR OTHERWISE, EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <br />
              <p>
                <b>12. Indemnity</b>
              </p>
              <br />
              <p>
                You agree to indemnify, defend, and hold us, our Affiliates, shareholders, subsidiaries, directors,
                officers and employees, harmless from any loss, claim, demand, or damage (actual and consequential) of
                every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed,
                including all third-party claims, liability, losses, expenses, damages, fines, penalties (including
                damages awarded or settled amounts and reasonable attorneys’ fees), arising out of or in connection with
                (a) your breach of these Terms, (b) your relationship or dealings with the Company, (c) use of the Pay
                Portal or receipt of the Payouts through any of the Payout Methods, (d) all losses arising from us
                acting on your instruction or that of the Company (unless these arise due to our gross negligence or
                willful intent or fraud), and/or (e) any losses we may incur resulting from any error made by you in
                providing information or instructions to us whether verbally or in writing, through the Pay Portal
                (unless these arise due to our gross negligence or willful default or fraud) or in connection with the
                Payout Methods.
              </p>
              <br />
              <p>
                <b>13. Notices</b>
              </p>
              <br />
              <p>
                All notices must be sent by postal mail to the Legal Notice Mailing address for the Affiliate associated
                with your country of location as set forth on the Affiliates and Governing Law Schedule.
              </p>
              <br />
              <p>
                <b>14. Termination</b>
              </p>
              <br />
              <p>
                The Company may at any time suspend or terminate all or any portion of the Payout Methods, including
                access to the Pay Portal, for any reason whatsoever as determined in its sole discretion. Upon such
                event, your access to and/or use of the Pay Portal and receipt of the Payouts will immediately be
                suspended or terminated.
              </p>
              <br />
              <p>
                <b>15. Closing Your Pay Portal</b>
              </p>
              <br />
              <p>
                15.1 You may close your Pay Portal at any time by contacting us. Upon your Pay Portal closure, we will
                cancel any pending transactions and you will have a limited period of time to transfer any remaining
                funds during which time your Pay Portal will be accessible for the purpose of transferring any remaining
                funds or balance to your Payout Method. You acknowledge that if you choose to close your Pay Portal, you
                will not be able to receive any further Payouts through our Services unless your Pay Portal is
                re-opened, or you complete the registration process for a new Pay Portal.
              </p>
              <br />
              <p>
                15.2 You may not close your Pay Portal to evade an investigation. If you attempt to close your Pay
                Portal while we are conducting an investigation, we may hold any funds or balance for up to 180 days to
                protect us, our Affiliates, any Network Partner, or a third party against the risk of reversals,
                chargebacks, claims, fees, fines, penalties and other liability. You agree to cooperate in any
                investigation of any suspected unlawful, fraudulent or improper activity and will remain liable for all
                obligations related to your Pay Portal or the Payouts even after the Pay Portal is closed.
              </p>
              <br />
              <p>
                15.3 <u>Escheatment</u>. If you do not access your Pay Portal or the Payouts which you receive via your
                Pay Portal is deemed unclaimed or abandoned under applicable law, we may close your Pay Portal and any
                remaining funds reflected in your Pay Portal will be sent to the owner of such funds as determined by
                the Payout Schedule. If the Company is the owner of the funds, the funds will be sent back to the
                Company or, if required, escheated in accordance with applicable unclaimed property laws. If you are the
                owner, the funds will be sent to your primary address or, if required, escheated in accordance with
                applicable unclaimed property laws. We will determine your residency based on the information in your
                account on the Pay Portal. We will make reasonable efforts to contact you before transferring
                escheatable funds owned by you. If your funds have already been escheated and you would like to claim
                such escheated Pay Portal funds, please contact the applicable state or country’s unclaimed property
                administrator. We can tell you whether funds have been escheated by contacting us. We reserve the right
                to directly assess your fees related to our escheatment activities in connection with your Pay Portal
                and/or Payout Method.
              </p>
              <br />
              <p>
                <b>16. Communication</b>
              </p>
              <br />
              <p>
                To the fullest extent permitted, you agree and consent to receive any and all communications,
                agreements, policies, schedules, addenda, statements, history and transaction information, documents,
                legal and regulatory notices and disclosures, and other content of any type or nature (collectively, “
                <b>Communications</b>”) that we provide in connection with the Pay Portal and the Payouts electronically
                instead of in paper form. In addition to electronic Communications described above, you understand and
                agree that we may contact you using autodialed or pre-recorded message calls or text messages at the
                telephone number(s) you have provided us to: (a) provide notices regarding the Payouts, your Pay Portal
                or Pay Portal activity; (b) respond to your inquiries; and/or (c) investigate or prevent fraud. Standard
                telephone minute and text charges may apply. You understand and agree that we may, without further
                notice or warning and in our discretion, monitor or record telephone conversations with you or anyone
                acting on your behalf. You acknowledge and understand that your communications with us may be overheard,
                monitored, or recorded without further notice or warning.
              </p>
              <br />
              <p>17. Miscellaneous</p>
              <br />
              <p>
                17.1 <u>Jurisdiction</u>. These Terms will be governed by and construed in accordance with the governing
                law corresponding to the Affiliate providing that portion of the Payout Methods to you as set forth on
                the Affiliates and Governing Law Schedule, without giving effect to any principles of conflict of laws.
                Where the laws of the United States apply, all claims and disputes arising under or relating to these
                Terms are to be settled by binding arbitration and such other terms and conditions as set forth in the
                Agreement to Arbitrate in the Terms of Use.
              </p>
              <br />
              <p>
                17.2 <u>Third-Party Beneficiaries</u>. You acknowledge and agree that: (a) you are not a third-party
                beneficiary of any contract between us and any third party; and (b) if you are receiving Payouts from
                the Company, it is solely responsible for paying you and any dispute related to the Company’s failure to
                pay you shall be solely between you and the Company. Notwithstanding anything to the contrary herein,
                the Company and its affiliates are intended third-party beneficiaries of these Terms, are entitled to
                the rights and benefits hereunder and may enforce the provisions hereof as if they were parties hereto.
              </p>
              <br />
              <p>
                17.3 <u>Waiver</u>. Our failure to act with respect to a breach of any of your obligations under these
                Terms by you or others does not waive our right to act with respect to subsequent or similar breaches.
              </p>
              <br />
              <p>
                17.4 <u>Assignment</u>. You shall not assign, novate, or otherwise transfer any rights or obligations
                you have under these Terms without our prior written consent. We may assign and/or otherwise novate any
                right or obligation under these Terms without your consent.
              </p>
              <br />
              <p>
                17.5 <u>Validity</u>. If any part of these Terms is determined to be invalid or unenforceable pursuant
                to applicable law including, but not limited to, the warranty disclaimers and liability limitations set
                forth above, the remainder of these Terms shall continue in full force and effect. Unless otherwise
                specified herein, these Terms constitute the entire agreement between you and us with respect to the
                Payout Methods, Payouts and the Pay Portal and supersede all prior or contemporaneous communications and
                proposals, whether electronic, oral or written, between you and us with respect to the Payout Methods,
                Payouts and the Pay Portal. The paragraph titles in these Terms are solely used for the convenience of
                the parties and have no legal or contractual significance. It is the express will of the parties that
                these Terms and all related documents have been drawn up in English. We may make these Terms available
                in a language other than English in our sole discretion. If you are reading these Terms in another
                language, the English text shall prevail in the event of any discrepancy in terms among and between the
                other language(s).
              </p>
              <br />
              <p>
                17.6 <u>Limitation on Actions</u>. You hereby waive any and all rights to bring any claim or action
                related to the Pay Portal or Services beyond one (1) year after the first occurrence of the kind of act,
                event, condition or omission upon which such claim or action is based.
              </p>
              <br />
              <p>
                <b>18. Questions</b>
              </p>
              <br />
              <p>
                If you have any questions or comments about these Terms or the Pay Portal, please contact us by email at
                hello@theinsighters.com.
              </p>
              <br />
              <p>
                <b>19. Payout Schedule</b>
              </p>
              <p>
                1. <u>Application</u>. This Payout Schedule is a schedule to these Terms and sets forth additional terms
                and conditions applicable to certain payment services and functionality which may be made available
                through the Payout Method and Pay Portal and as provided by certain Affiliates. The Company determines
                which Payout Method will be made available to you.
              </p>
              <br />
              <p>
                2. <u>Description of the Payout Method</u>. Through the Pay Portal, you may receive Payouts from the
                Company through transfer to your PayPal Account (as defined below); provided you are eligible to use the
                PayPal to receive the Payouts pursuant to the Terms and once your registration of Payout Method details
                (e.g. PayPal account information) and preferences has been completed, whether by you or through the
                Company, then upon and to the extent of receipt of funds and complete Payout Method instructions, such
                funds may then be received by you through PayPal in accordance of those instructions.
              </p>
              <br />
              <p>
                3. <u>General Payout Terms and Conditions</u>. By directing us to make a transfer to your PayPal
                account, you are affirming that you are the owner of the PayPal account, have confirmed the accuracy of
                the bank account information provided to us, and have authority to initiate transactions in respect of
                the bank account. We will make electronic credits and debits via bank clearing networks and/or wire to
                your PayPal Account in the amount the Company specifies unless there are insufficient funds available.
                You agree that your requests for a transfer to your PayPal account constitutes your authorization to us
                to make the transfers to and from your designated PayPal account, including any adjustments necessary
                for any transactions which are credited or debited in error. We may permit you or we may utilize other
                methods to process any particular transfer, such as a wire transfer. Once you have provided your
                authorization for the transfer, you will not be able to cancel that electronic transfer unless you do so
                in a manner that allows us and your bank a reasonable opportunity to act on such cancellation. It may
                take two to five business days for the proceeds of the transfer to transmit to your PayPal Account. In
                some situations, we may provide you with an estimate of the processing time your transaction will
                require via the Pay Portal. Although we may be able to specify a timeframe in which the funds will reach
                your designated bank, we cannot be responsible for any delays due to any bank clearing network or your
                bank’s processing of a transaction and cannot guarantee that your bank will make funds available to you
                on the day it receives the transfer. You must provide us with the correct details for the PayPal account
                to which you would like to transfer funds. If you input incorrect bank account information, you agree
                that you will not hold us liable for any claims or losses should a transaction not be properly executed,
                including but not limited to where funds are sent to a bank account in error or otherwise. PayPal may
                charge a return fee or other related fee in connection with the attempted transfer. In the event such a
                fee is incurred or otherwise imposed on us, such fee will be passed on to you and deducted from your Pay
                Portal balance or amounts otherwise payable to you. We will not be liable where you do not have enough
                money available to transfer, your PayPal account is closed, the transaction exceeds dollar or frequency
                limitations imposed by us or your PayPal, the transaction is not processed, or the transaction is
                returned by PayPal or where we otherwise advise you that your request will not be processed. We shall
                have no responsibility for any action PayPal may take with respect to such transfer to your PayPal
                account, including offsetting amounts owed to such bank from such transfer due to an overdrawn account,
                outstanding fees, or otherwise.
              </p>
            </Grid>
          </Grid>
        </div>
      </Page>
      <br />
      <Footer />
    </>
  );
};

export default PayPortalTerms;
