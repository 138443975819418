import React, { Dispatch, SetStateAction, useState } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DropZone } from '@shopify/polaris';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormInput from '../../form/components/FormInput';
import { BlogAuthor } from '../model';

interface BlogAuthorFormProps {
  formValues: BlogAuthor;
  setFormValues: Dispatch<SetStateAction<BlogAuthor>>;
  formErrors: string;
  setImageFileData: Dispatch<SetStateAction<FormData>>;
}

const BlogAuthorForm: React.FC<BlogAuthorFormProps> = (props: BlogAuthorFormProps) => {
  const { formValues, setFormValues, formErrors, setImageFileData } = props;

  const [imagePreview, setImagePreview] = useState<string | null>(formValues?.s3_image_url || null);

  const handleAttachFile = (files: File[]) => {
    const newFormData = new FormData();
    const attachedFile = files[0];
    newFormData.append('file', attachedFile, attachedFile.name);
    setImageFileData(newFormData);

    const previewUrl = URL.createObjectURL(attachedFile);
    setImagePreview(previewUrl);
  };

  React.useEffect(() => {
    if (formValues?.s3_image_url) {
      setImagePreview(formValues?.s3_image_url);
    }
  }, [formValues?.s3_image_url]);

  return (
    <Card style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <FormInput
            type="text"
            label="Full Name"
            value={formValues?.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={10}>
          <FormInput
            type="text"
            label="Title"
            value={formValues?.title}
            onChange={(value) => setFormValues({ ...formValues, title: value })}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" style={{ marginBottom: 0 }}>
            Bio
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <ReactQuill
            theme="snow"
            value={formValues?.bio}
            onChange={(value) => setFormValues({ ...formValues, bio: value })}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} style={{ margin: 'auto' }}>
          <Card title="Image">
            <DropZone type="image" label="" onDrop={handleAttachFile} allowMultiple={false}>
              <DropZone.FileUpload />
            </DropZone>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '50%', height: 'auto' }} />}
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>{formErrors}</p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BlogAuthorForm;
