import React from 'react';
import { Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';
import Footer from '../../navigation/components/Footer';

const CookiePolicy: React.FunctionComponent = () => {
  return (
    <>
      <Page fullWidth title="">
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <Grid container>
            <Grid item md={8} xs={12}>
              <h2 style={{ fontSize: 28, fontFamily: 'Cooper', margin: '40px 0' }}>Cookie Policy</h2>

              <p className="p1">
                Like many other companies, we use cookies and other tracking technologies (such as pixels and web
                beacons) (collectively, &ldquo;Cookies&rdquo;) when you visit our platform, The Insighters
                (&ldquo;Platform&rdquo;), and the services provided through our Platform (collectively,
                &ldquo;Services&rdquo;). <span className="Apple-converted-space">&nbsp;</span>
              </p>
              <br />
              <p className="p1">
                This Cookie Policy covers the following topics:<span className="Apple-converted-space">&nbsp;</span>
              </p>
              <br />
              <p className="p2">
                1.<span className="Apple-converted-space">&nbsp;&nbsp;</span>General Cookie Information
              </p>
              <br />
              <p className="p2">2. Cookies We Use on the Platform</p>
              <br />
              <p className="p2">3. How You Can Opt-Out of Cookies</p>
              <br />
              <p className="p2">4. How to Contact Us</p>
              <br />
              <p className="p1">
                <strong>1. &nbsp; &nbsp; General Cookie Information</strong>
              </p>
              <br />
              <p className="p1">
                &ldquo;Cookies&rdquo; are small files of information that are stored by your web browser software on
                your computer hard drive, mobile or other devices (e.g., smartphones or tablets).
                <span className="Apple-converted-space">&nbsp;</span>
              </p>
              <br />
              <p className="p3">We may use the following types of Cookies:</p>
              <ul className="ul1">
                <li className="li1">
                  Strictly Necessary Cookies:<span className="Apple-converted-space">&nbsp;&nbsp;</span>These Cookies
                  are necessary for the website to function and cannot be switched off in our systems. They are usually
                  only set in response to actions made by you which amount to a request for services, such as setting
                  your privacy preferences, logging in or filling in forms. You can set your browser to block or alert
                  you about these cookies, but some parts of the Sites will not then work. These Cookies do not store
                  any personally identifiable information.
                </li>
                <li className="li1">
                  <span className="s2">
                    Performance Cookies:<span className="Apple-converted-space">&nbsp;&nbsp;</span>These Cookies allow
                    us to count visits and traffic sources so we can measure and improve the performance of our Sites.
                    They help us to know which pages are the most and least popular and see how visitors move around the
                    Sites. All information these Cookies collect is aggregated and therefore anonymous. If you do not
                    allow these Cookies we will not know when you have visited our Sites and will not be able to monitor
                    its performance.
                  </span>
                </li>
                <li className="li1">
                  <span className="s2">
                    Functional Cookies:<span className="Apple-converted-space">&nbsp;&nbsp;</span>These Cookies enable
                    the website to provide enhanced functionality and personalization. They may be set by us or by third
                    party providers whose services we have added to our pages. If you do not allow these Cookies then
                    some or all of these Services may not function properly.
                  </span>
                </li>
                <li className="li1">
                  <span className="s2">
                    Targeting Cookies: These Cookies may be set through our Sites by our advertising partners. They may
                    be used by those companies to build a profile of your interests and show you relevant adverts on
                    other sites. They do not store directly personal information, but are based on uniquely identifying
                    your browser and internet device. If you do not allow these Cookies, you will experience less
                    targeted advertising.
                  </span>
                </li>
                <li className="li1">
                  <span className="s2">
                    Social Media Cookies: These Cookies are set by a range of social media services that we have added
                    to the Sites to enable you to share our content with your friends and networks. They are capable of
                    tracking your browser across other sites and building up a profile of your interests. This may
                    impact the content and messages you see on other websites you visit. If you do not allow these
                    Cookies you may not be able to use or see these sharing tools.
                  </span>
                </li>
              </ul>
              <p className="p1">
                <strong>2. &nbsp; &nbsp;Cookies We Use on the Platform</strong>
              </p>
              <br />
              <p className="p1">
                &nbsp; &nbsp; <em>Google Analytics</em>
              </p>
              <br />
              <p className="p1">
                We use Google Analytics, a web analytics service provided by Google, Inc.
                <span className="Apple-converted-space">&nbsp;&nbsp;</span>Google Analytics uses Cookies or other
                tracking technologies to help us analyze how users interact with the Site and Services, compile reports
                on their activity, and provide other services related to their activity and usage.
                <span className="Apple-converted-space">&nbsp;&nbsp;</span>The technologies used by Google may collect
                information such as your IP address, time of visit, whether you are a returning visitor, and any
                referring website.<span className="Apple-converted-space">&nbsp;&nbsp;</span>The technologies used by
                Google Analytics do not gather information that personally identifies you.
                <span className="Apple-converted-space">&nbsp;&nbsp;</span>The information generated by Google Analytics
                will be transmitted to and stored by Google and will be subject to Google&rsquo;s{' '}
                <a href="https://policies.google.com/privacy?hl=en">
                  <span className="s3">privacy policies</span>
                </a>
                .<span className="Apple-converted-space">&nbsp;&nbsp;</span>To learn more about Google&rsquo;s partner
                services and to learn how to opt-out of tracking of analytics by Google, click{' '}
                <a href="https://policies.google.com/technologies/partner-sites">
                  <span className="s3">here</span>
                </a>
                .
              </p>
              <br />
              <p className="p1">
                &nbsp; &nbsp; <em>Google reCAPTCHA</em>
              </p>
              <br />
              <p className="p1">
                We use Google reCAPTCHA, a free service provided by Google, Inc., to protect our Site from spam and
                abuse. Google reCAPTCHA uses advanced risk analysis techniques to decipher humans and bots. Google
                reCAPTCHA works differently depending on what version is deployed. For example, you may be asked to
                check a box indicating that you are not a robot or Google reCAPTCHA may detect abusive traffic without
                user interaction. Google reCAPTCHA works by transmitting certain types of information to Google, such as
                the referrer URL, IP address, visitor behavior, operating system information, browser and length of the
                visit, cookies, and mouse movements. Your use of Google reCAPTCHA is subject to Google’s{' '}
                <a href="https://policies.google.com/privacy">
                  <span className="s3">Privacy Policy</span>
                </a>{' '}
                and{' '}
                <a href="https://policies.google.com/terms">
                  <span className="s3">Terms of Use</span>
                </a>
                . More information as to Google reCAPTCHA and how it works is available{' '}
                <a href="https://developers.google.com/recaptcha">
                  <span className="s3">here</span>
                </a>
                .
              </p>
              <br />
              <p className="p1">
                <strong>3. &nbsp; &nbsp;How You Can Opt-Out of Cookies</strong>
              </p>
              <br />
              <p className="p5">
                &nbsp; &nbsp; <em>Browser Settings</em>
              </p>
              <br />
              <p className="p5">
                Cookies can be blocked by changing your Internet browser settings to refuse all or some Cookies.
                <span className="Apple-converted-space">&nbsp;&nbsp;</span>If you choose to block all Cookies (including
                essential Cookies) you may not be able to access all or parts of the Sites.
              </p>
              <br />
              <p className="p5">
                You can find out more about Cookies and how to manage them by visiting{' '}
                <a href="http://www.aboutcookies.org/">
                  <span className="s3">www.AboutCookies.org</span>
                </a>{' '}
                or{' '}
                <a href="http://www.AllAboutCookies.org">
                  <span className="s3">www.AllAboutCookies.org</span>
                </a>
                .<span className="Apple-converted-space">&nbsp;</span>
              </p>
              <br />
              <p className="p5">
                &nbsp; &nbsp;{' '}
                <em>
                  Advertising Industry Resources<span className="Apple-converted-space">&nbsp;</span>
                </em>
              </p>
              <br />
              <p className="p5">
                You can understand which entities have currently enabled Cookies for your browser or mobile device and
                how to opt-out of some of those Cookies by accessing the{' '}
                <a href="http://optout.networkadvertising.org/#!/">
                  <span className="s3">Network Advertising Initiative&rsquo;s website</span>
                </a>{' '}
                or the{' '}
                <a href="http://optout.aboutads.info/#!/">
                  <span className="s3">Digital Advertising Alliance&rsquo;s website</span>
                </a>
                .<span className="Apple-converted-space">&nbsp;&nbsp;</span>For more information on mobile specific
                opt-out choices, visit the{' '}
                <a href="http://www.networkadvertising.org/mobile-choices">
                  <span className="s3">Network Advertising Initiative&rsquo;s Mobile Choices website</span>
                </a>
                .
              </p>
              <br />
              <p className="p5">
                Please note that these opt-out mechanisms are specific to the device or browser on which they are
                exercised. Therefore, you will need to opt out on every browser and device that you use.
              </p>
              <br />
              <p className="p1">
                <strong>4. &nbsp; &nbsp;How to Contact Us</strong>
              </p>
              <br />
              <p className="p1">
                To contact us for questions or concerns about our privacy policies or practices please email
                hello@theinsighters.com or call 215-539-1634.
              </p>
            </Grid>
          </Grid>
        </div>
      </Page>
      <br />
      <Footer />
    </>
  );
};

export default CookiePolicy;
