import React from 'react';
import { Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';
import Footer from '../../navigation/components/Footer';

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <>
      <Page fullWidth title="">
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <Grid container>
            <Grid item md={8} xs={12}>
              <h2 style={{ fontSize: 28, fontFamily: 'Cooper', margin: '40px 0' }}>Privacy Policy</h2>
              <p>
                <b>Introduction</b>
              </p>
              <br />
              <p>Last updated March 12, 2024</p>
              <br />
              <p>
                Linguis-Techs, Inc. d/b/a Sommer Consulting (“Sommer Consulting,” “we,” “our,” or “us”) respects the
                privacy of your information. This Privacy Policy is designed to assist you in understanding how we
                collect, use and safeguard the information you provide to us in using our platform, The Insighters (the
                “Platform”), and the services provided through our Platform (the “Services”).
              </p>
              <br />
              <p>
                From time to time, we may change this Privacy Policy. If we do, we will post an amended version on this
                webpage. If we make material changes to our Privacy Policy, we may also notify you by other means, such
                as sending an email or posting a notice on our homepage. If required by applicable data protection laws,
                we will obtain your consent to any material changes. Please review this Privacy Policy periodically.
              </p>
              <br />
              <p>
                Please also refer to our <a href="/terms-of-use">Terms of Use</a> regarding the proper usage of the
                Platform and Services.
              </p>
              <br />
              <p>This Privacy Policy covers the following topics:</p>
              <ol>
                <li>What is Sommer Consulting?</li>
                <li>Personal Data We Collect</li>
                <li>How We Use Your Personal Data</li>
                <li>How We Share Your Personal Data</li>
                <li>International Data Transfers</li>
                <li>“Do Not Track” Signals</li>
                <li>Advertising and Marketing Choices</li>
                <li>Third Party Links</li>
                <li>Security</li>
                <li>Children’s Privacy</li>
                <li>Your Personal Data and Your Rights –United States Only</li>
                <li>Your Personal Data and Your Rights –Europe and the United Kingdom Only</li>
                <li>How to Contact Us</li>
              </ol>
              <br />
              <p>
                <b>1. What is Sommer Consulting?</b>
              </p>
              <br />
              <p>
                Sommer Consulting is a market research and consulting firm that leverages their proprietary 8-Components
                Linguis-Techs questioning model to provide actionable insight to clients across diverse
                industries—biotech, finance, pharmaceuticals, luxury goods & healthcare. The Platform enables market
                researchers to connect with respondents to engage in marketing research and studies. Sommer Consulting
                is a registered Minority & Woman-Owned Small Business & Federal vendor.
              </p>
              <br />
              <p>
                Sommer Consulting’s mailing address is PO Box 307, New Hope, PA 18938, USA. Sommer Consulting is the
                data controller of all personal data collected from residents of the European Economic Area or the
                United Kingdom.
              </p>
              <br />
              <p>
                <b>2. Personal Data We Collect</b>
              </p>
              <br />
              <p>
                Through your use of the Platform and Services, we will collect personal data from you. Personal data is
                information that is linked or reasonably linkable to an identified or identifiable individual. We
                collect the following types of personal data:
              </p>
              <br />
              <p>
                <em>Personal Data You Provide</em>
              </p>
              <br />
              <p>
                Depending on how you interact with our Platform and Services, we will collect the following personal
                data that you voluntarily provide to us for the following purposes:
              </p>
              <ul>
                <li>
                  <em>Register as an Insighter</em>. When you register for an account as an Insighter, you will provide
                  us with your first and last name, email address, and password. To complete your profile, you may
                  provide your telephone number, zip code, date of birth, gender, sexual orientation, race/ethnicity,
                  and veteran status.
                </li>
                <li>
                  Participate in a Project. When you choose to participate as a respondent in an active Project, you may
                  provide us with information regarding your health conditions, age, gender, race/ethnicity, and
                  opinions. For certain Projects, you may not be considered if you do not provide us with this
                  information. For our recruiters to contact you about the project, you will provide us with your
                  contact information, including your first and last name, email address, telephone number, and mailing
                  address. To access the payment portal following completion of participation in a Project, birth date
                  is additionally required.
                </li>
              </ul>
              <br />
              <p>
                <em>Personal Data as You Navigate Our Platform and Services</em>
              </p>
              <br />
              <p>
                We automatically collect certain personal data through your use of the Platform and Services and our use
                of cookies and other tracking technologies, such as the following:
              </p>
              <ul>
                <li>
                  <em>Usage Information</em>. For example, the pages on the Platform you access, the frequency of
                  access, and what you click on while on the Platform.
                </li>
                <li>
                  <em>Device Information</em>. For example, hardware model, operating system, application version
                  number, and browser.
                </li>
                <li>
                  <em>Mobile Device Information.</em> Aggregated information about whether the Platform is accessed via
                  a mobile device or tablet, the device type, and the carrier.
                </li>
                <li>
                  <em>Location Information</em>. Location information from Platform visitors on a city-regional basis.
                </li>
              </ul>
              <br />
              <p>
                To learn more about how we use cookies and to manage your cookie settings, please see our{' '}
                <a href="/cookie-policy">Cookie Policy</a>.
              </p>
              <br />
              <p>
                <i>Personal Data We Collect About You from Other Sources</i>
              </p>
              <br />
              <p>
                In some cases, we may receive personal data about you from other sources. This includes government
                entities, advertising networks, data brokers, operating systems and platforms, customer relationship
                management platforms, mailing list providers, social networks and advertising and marketing partners.
              </p>
              <br />
              <p>
                <b>3. How We Use Your Personal Data</b>
              </p>
              <br />
              <p>
                In addition to the purposes stated above, we may use all the personal data we collect in accordance with
                applicable law such as to:
              </p>
              <ol>
                <li>Maintain and improve our Platform and Services;</li>
                <li>Protect the security and integrity of our Platform and Services; </li>
                <li>
                  Investigate, prevent, or take action regarding illegal activities, suspected fraud, situations
                  involving potential threats to the physical safety of any person, or violations of our{' '}
                  <a href="/terms-of-use">Terms of Use</a>, and to otherwise fulfill our legal obligations;
                </li>
                <li>Defend our legal rights and the rights of others;</li>
                <li>Fulfill any other purposes for which you provide it;</li>
                <li>
                  For any purpose that is reasonably necessary to or compatible with the original purpose for which we
                  collected the personal data as disclosed to you; and
                </li>
                <li>Comply with applicable law.</li>
              </ol>
              <br />
              <p>
                <b>4. How We Share Your Personal Data</b>
              </p>
              <br />
              <p>We may share the personal data that we collect about you in the following ways:</p>
              <ul>
                <li>
                  With vendors who perform data services on our behalf (e.g., email, survey generator, appointment
                  scheduling, hosting, maintenance, backup, analysis, etc.);
                </li>
                <li>To vendors to prepare, deploy and analyze advertising content;</li>
                <li>To the extent that we are required to do so by law;</li>
                <li>In connection with any legal proceedings or prospective legal proceedings;</li>
                <li>
                  To establish, exercise, or defend our legal rights, including providing information to others for the
                  purposes of fraud prevention;
                </li>
                <li>
                  With any person who we reasonably believe may apply to a court or other competent authority for
                  disclosure of that personal data where, in our reasonable opinion, such court or authority would be
                  reasonably likely to order disclosure of that personal data;
                </li>
                <li>
                  To any other person or entity as part of any business or asset sale, equity transaction, merger,
                  acquisition or in preparation for any of these events; and
                </li>
                <li>To any other person or entity where you consent to the disclosure. </li>
              </ul>
              <br />
              <p>
                <b>5. International Data Transfers</b>
              </p>
              <br />
              <p>
                We operate internationally and transfer information to the United States for the purposes described in
                this policy. The United States may have privacy and data protection laws that differ from, and are
                potentially less protective than, the laws of your country. Your personal data can be subject to access
                requests from governments, courts, or law enforcement in the United States according to the laws of the
                United States.
              </p>
              <br />
              <p>
                For any transfers of personal data from the European Economic Area (EEA), Switzerland or the United
                Kingdom that we make to other entities as described in this Privacy Policy, we use appropriate
                safeguards to ensure for the lawful processing and transfer of the personal data, including, when
                appropriate, the use of{' '}
                <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">
                  standard contractual clauses
                </a>{' '}
                approved by the European Commission. To obtain a copy of the safeguards, contact us at
                <a href="mailto:hello@theinsighters.com">hello@theinsighters.com</a>.
              </p>
              <br />
              <p>
                <b>6. “Do Not Track” Signals</b>
              </p>
              <br />
              <p>
                Some internet browsers incorporate a “Do Not Track” feature that signals to websites you visit that you
                do not want to have your online activity tracked. Given that there is not a uniform way that browsers
                communicate the “Do Not Track” signal, the Platform does not currently interpret, respond to or alter
                its practices when it receives “Do Not Track” signals.
              </p>
              <br />
              <p>
                <b>7. Advertising and Marketing Choices</b>
              </p>
              <br />
              <p>
                Depending on your location (and reflecting applicable law), you may have been asked to indicate your
                preferences, provide us with your consent regarding the receipt of such information from us, and
                indicate how you would like to receive it.
              </p>
              <br />
              <p>
                Wherever you are located, we will send you marketing communications based on any preferences you may
                have expressed.
              </p>
              <br />

              <p>
                We only want to send you information in which you are interested. If you do not want to receive these
                communications or would like to understand more about other unsubscribe options, please contact us as
                set out in the “<b>How to Contact Us</b>” section below.
              </p>
              <br />
              <p>
                For email communications, you can opt-out by clicking on the unsubscribe link provided at the bottom of
                any email you receive from us. You also may submit a request to us at{' '}
                <a href="mailto:hello@theinsighters.com">hello@theinsighters.com</a>. Note, however, that you will
                continue to receive communications regarding your use of the Services (e.g., when you have decided to
                participate in a Project). If we call or text you with information you do not want to receive, you can
                advise us of this during the telephone call [or by replying “STOP” to the text message].
              </p>
              <br />
              <p>
                <b>8. Third Party Links</b>
              </p>
              <br />
              <p>
                The Platform and Services may contain links that will let you leave the Platform and Services and access
                another website. Linked websites are not under our control. This Privacy Policy applies solely to
                personal data that is acquired on this Platform and Services. We accept no responsibility or liability
                for these other websites.
              </p>
              <br />
              <p>
                <b>9. Security</b>
              </p>
              <br />
              <p>
                We maintain commercially reasonable security measures to protect the personal data we collect and store
                from loss, misuse, destruction, or unauthorized access. However, no security measure or modality of data
                transmission over the Internet is 100% secure. Although we strive to use commercially acceptable means
                to protect your personal data, we cannot guarantee absolute security.
              </p>
              <br />
              <p>
                <b>10. Children’s Privacy</b>
              </p>
              <br />
              <p>
                The Platform and Services are not intended for individuals under 13 years of age. We do not knowingly
                collect, use, or disclose personal information from individuals under 13.
              </p>
              <br />
              <p>
                <b>11. Your Personal Data and Your Rights –United States Only</b>
              </p>
              <br />
              <p>California Shine the Light Law</p>
              <br />
              <p>
                We do not disclose personal information obtained through our Platform or Services to third parties for
                their direct marketing purposes. Accordingly, we have no obligations under California Civil Code §
                1798.83.
              </p>
              <br />
              <p>
                <b>Notice to Nevada Residents</b>
              </p>
              <br />
              <p>
                Nevada law allows Nevada residents to opt-out of the sale of certain types of personal information.
                Subject to a number of exceptions, Nevada law defines “sale” to mean the exchange of certain types of
                personal information for monetary consideration to another person. We do not currently sell personal
                information as defined in the Nevada law. However, if you are a Nevada resident, you still may submit a
                verified request to opt-out of sales and we will record your instructions and incorporate them in the
                future if our policy changes. Opt-out requests may be sent to
                <a href="mailto:hello@theinsighters.com">hello@theinsighters.com</a>.
              </p>
              <br />
              <p>
                <b>12. Your Personal Data and Your Rights –Europe and the United Kingdom Only</b>
              </p>
              <br />
              <p>
                If you are in a country in the European Economic Area (EEA) or in the United Kingdom, you are entitled
                to the following explanation of the legal bases we rely on to process your personal data and a
                description of your privacy rights.
              </p>
              <br />
              <p>
                <b>Legal Bases for Processing</b>
              </p>
              <br />
              <p>
                The legal basis for collecting and using the personal data described above will depend on the personal
                data concerned and the specific context in which we collect it.
              </p>
              <br />
              <p>
                <em>Consent</em>
              </p>
              <br />
              <p>
                We may process your personal data based on your consent such as when you create an account or when you
                ask us to send certain kinds of marketing communications. You have the right to withdraw your consent at
                any time without affecting the lawfulness of the processing based on consent before its withdrawal.
              </p>
              <br />
              <p>
                <em>Our Legitimate Interests</em>
              </p>
              <br />
              <p>
                We may process your personal data if doing so is necessary for our legitimate interests and your rights
                as an individual do not override those legitimate interests. For example, when we process your personal
                data to carry out fraud prevention activities and activities to increase network and information
                security, to market directly to you, to expand our business activities and to improve our services and
                the content and functionality of our Platform.
              </p>
              <br />
              <p>
                <em>Performance of a Contract</em>
              </p>
              <br />
              <p>We may process your personal data to administer and fulfill contractual obligations to you.</p>
              <br />
              <p>
                <em>To Enable Us to Comply with a Legal Obligation</em>
              </p>
              <br />
              <p>
                We may process your personal data to comply with legal obligations to which we are subject. This may
                include any requirement to produce audited accounts and to comply with legal process.
              </p>
              <br />
              <p>
                <em>Necessary for the Exercise or Defense of Legal Claims </em>
              </p>
              <br />
              <p>
                If you bring a claim against us or we bring a claim against you, we may process your personal data in
                relation to that claim.
              </p>
              <br />
              <p>
                If you have any questions about or need further information concerning the legal basis on which we
                collect and use your personal data for any specific processing activity, please contact us using the “
                <b>How to Contact Us”</b> section below.
              </p>
              <br />
              <p>
                <b>Your Rights</b>
              </p>
              <br />
              <p>
                To exercise any of these rights, please contact us as described in the “<b>How to Contact Us”</b>{' '}
                section below.
              </p>
              <br />
              <p>
                <em>Access Your Personal Data</em>
              </p>
              <br />
              <p>
                You have the right to obtain from us confirmation as to whether or not we are processing personal data
                about you and, if so, the right to be provided with the information contained in this Privacy Policy.
                You also have the right to receive a copy of the personal data undergoing processing.
              </p>
              <br />
              <p>
                <em>Rectify Your Personal Data</em>
              </p>
              <br />
              <p>
                You have the right to ask us to rectify any inaccurate personal data about you and to have incomplete
                personal data completed.
              </p>
              <br />
              <p>
                <em>Restrict Our Use of Your Personal Data</em>
              </p>
              <br />
              <p>
                You have the right to ask us to place a restriction on our use of your personal data if one of the
                following applies to you:
              </p>
              <ul>
                <li>
                  You contest the accuracy of the information that we hold about you, while we verify its accuracy;
                </li>
                <li>
                  We have used your information unlawfully, but you request us to restrict its use instead of erasing
                  it;
                </li>
                <li>
                  We no longer need the information for the purpose for which we collected it, but you need it to deal
                  with a legal claim; or
                </li>
                <li>
                  You have objected to us using your information, while we check whether our legitimate grounds override
                  your right to object.
                </li>
              </ul>
              <br />
              <p>
                <em>Object to Our Use of Your Personal Data</em>
              </p>
              <br />
              <p>
                You have the right to object to our use of your personal data where our reason for using it is based on
                our legitimate interests or your consent (rather than when the reason for using it is to perform an
                obligation due to you under a contract with us).
              </p>
              <br />
              <p>
                <em>Delete Your Personal Data</em>
              </p>
              <br />
              <p>You can ask us to delete your personal data if:</p>
              <ul>
                <li>We no longer need it for the purposes for which we collected it;</li>
                <li>We have been using it with no valid legal basis;</li>
                <li>We are obligated to erase it to comply with a legal obligation to which we are subject;</li>
                <li>We need your consent to use the information and you withdraw consent;</li>
                <li>
                  You object to us processing your personal data where our legal basis for doing so is our legitimate
                  interests and there are no overriding legitimate grounds for the processing.
                </li>
              </ul>
              <br />
              <p>
                However, this right is not absolute. Even if you make a request for deletion, we may need to retain
                certain information for legal or administrative purposes, such as record keeping, maintenance of opt-out
                requirements, defending or making legal claims, or detecting fraudulent activities. We will retain
                information in accordance with the “How Long Is Your Personal Data Kept” section below.
              </p>
              <br />
              <p>
                If you do exercise a valid right to have your personal data deleted, please keep in mind that deletion
                by third parties to whom the information has been provided might not be immediate and that the deleted
                information may persist in backup copies for a reasonable period (but will not be available to others).
              </p>
              <br />
              <p>
                <em>Transfer Your Personal Data to Another Service Provider</em>
              </p>
              <br />
              <p>
                You may request that we transfer some of the personal data you have provided to you or another service
                provider in electronic copy. This applies to personal data we are processing to service a contract with
                you and to personal data we are processing based on your consent.
              </p>
              <br />
              <p>
                <em>Make a Complaint</em>
              </p>
              <br />
              <p>
                If you have any concerns or complaints regarding our processing of your personal data, please contact us
                as described in the “How to Contact Us” section below and we will do our best to answer any question and
                resolve any complaint to your satisfaction.
              </p>
              <br />
              <p>
                If, for whatever reason, you feel we do not meet the standards you expect of us, you are also entitled
                to make a complaint to your local supervisory authority:
              </p>
              <br />
              <p>
                <a href="https://www.edpb.europa.eu/about-edpb/about-edpb/members_en">
                  EU Data Protection Authorities (DPAs)
                </a>
              </p>
              <br />
              <p>
                <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/task.html">
                  Swiss Federal Data Protection and Information Commissioner (FDPIC)
                </a>
              </p>
              <br />
              <p>
                <a href="https://ico.org.uk/make-a-complaint/">Information Commissioner’s Office (United Kingdom)</a>
              </p>
              <br />
              <p>
                <em>How Long Is Your Personal Data Kept?</em>
              </p>
              <br />
              <p>
                We will retain your personal data for as long as necessary to fulfill the purposes for which we collect
                it and as set out in this Privacy Policy and for the purpose of satisfying any legal, accounting, or
                reporting requirements that apply to us.
              </p>
              <br />
              <p>
                <b>13. How to Contact Us</b>
              </p>
              <br />
              <p>
                For questions or concerns about our privacy policies or practices, please contact us by email at
                <a href="mailto:hello@theinsighters.com">hello@theinsighters.com</a>.
              </p>
            </Grid>
          </Grid>
        </div>
      </Page>
      <br />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
