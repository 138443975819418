import React, { useContext } from 'react';
import { Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import { PaymentsContext } from '../contexts/PaymentsContext';
import BackButton from '../../navigation/components/BackButton';
import NewExpenseForm from '../components/NewExpenseForm';
import NewExpenseConfirmation from '../components/NewExpenseConfirmation';

const NewExpensePage: React.FunctionComponent = () => {
  const { payment } = useContext(PaymentsContext);

  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Expense">
        <br />
        <Layout>
          <Layout.Section>{payment?.id ? <NewExpenseConfirmation /> : <NewExpenseForm />}</Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default NewExpensePage;
