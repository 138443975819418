import React from 'react';

import { Grid } from '@material-ui/core';
import EventViewer from './EventViewer';
import { EventResource } from '../model';

interface EventViewerRowProps {
  eventResources: EventResource[];
}

const EventViewerRow: React.FC<EventViewerRowProps> = (props) => {
  const { eventResources } = props;

  return (
    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-around' }}>
      {eventResources.map((event, index) => (
        <Grid item xs={3} key={`event-${index}`}>
          <EventViewer eventResource={event} />
        </Grid>
      ))}
    </Grid>
  );
};

export default EventViewerRow;
