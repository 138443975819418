import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core';
import Logo from '../../../assets/logo.png';
import AlyssaMedwynter from '../../../assets/team/AlyssaMedwynter.jpg';
import TenaNovak from '../../../assets/team/TenaNovak.jpg';
import MaryFox from '../../../assets/team/MaryFox.jpg';
import AmyChristianson from '../../../assets/team/AmyChristianson.png';
import TinaAswaniOmprakash from '../../../assets/team/TinaAswaniOmprakash.png';
import Footer from '../../navigation/components/Footer';

const useStyles = makeStyles((theme) =>
  createStyles({
    employeeBio: {
      fontFamily: 'Cooper',
      fontSize: '16px',
      marginBottom: '10px',
    },

    employeeGridItem: {
      padding: '0 7.5px 30px 7.5px',
    },

    employeeGridBorder: {
      border: '1px solid #e6e6e6',
    },

    employeeImage: {
      display: 'block',
      margin: '0',
      padding: '0',
      width: '100%',
    },

    employeeHorizontalRule: {
      borderBottom: '5px solid #3b4255',
      borderTop: '0',
      margin: '0',
      padding: '0',
      width: '100%',
    },

    employeeInfoContainer: {
      padding: '40px',
      minHeight: '430px',
      alignItems: 'baseline',
      display: 'flex',
    },

    employeeName: {
      color: '#3b4255',
      fontFamily: 'Cooper',
      fontSize: '28px',
      lineHeight: '1.2',
      marginBottom: '10px',
    },

    employeeTitle: {
      color: '#13202c',
      fontFamily: 'Cooper',
      fontSize: '22px',
      lineHeight: '1.2em',
      marginBottom: '10px',
      minHeight: '3em',
    },

    title: {
      fontFamily: 'Cooper',
      fontSize: '40px',
      lineHeight: '40px',
      margin: '30px 0',
      textAlign: 'center',

      [theme.breakpoints.up(500)]: {
        fontSize: '54px',
        lineHeight: '54px',
        margin: '40px 0',
      },
    },

    seperator: {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: '#3b4255',
      maxWidth: '60px',
      margin: '40px auto',
    },
  }),
);

const MeetTheTeam: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div
      className="Polaris-Page Polaris-Page--fullWidth"
      style={{ position: 'relative', minHeight: '100vh', padding: 0 }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={8} md={4}>
          <img alt="logo" src={Logo} style={{ width: '100%', margin: '40px 0 -10px' }} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={8}>
          <h1 className={classes.title}>Meet the Team</h1>
          <div className={classes.seperator} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Grid container>
            <Grid item lg={6} md={12} className={classes.employeeGridItem}>
              <div className={classes.employeeGridBorder}>
                <div>
                  <img alt="Mary Fox" src={MaryFox} className={classes.employeeImage} />
                  <hr className={classes.employeeHorizontalRule} />
                </div>
                <div className={classes.employeeInfoContainer}>
                  <div>
                    <h3 className={classes.employeeName}>Mary Fox</h3>
                    <h4 className={classes.employeeTitle}>Vice President, Strategic Growth & Business Development</h4>
                    <p className={classes.employeeBio}>
                      Mary is passionate about connecting real people & real stories to research. She believes in the
                      power of language to effect change, & is obsessed with bridging gaps between patients, healthcare
                      providers, & the companies that need to hear from them. Mary holds an M.A. in Applied Linguistics
                      & is a Forbes Business Development Council Member.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={12} className={classes.employeeGridItem}>
              <div className={classes.employeeGridBorder}>
                <div>
                  <img alt="Tina Aswani-Omprakash" src={TinaAswaniOmprakash} className={classes.employeeImage} />
                  <hr className={classes.employeeHorizontalRule} />
                </div>
                <div className={classes.employeeInfoContainer}>
                  <div>
                    <h3 className={classes.employeeName}>Tina Aswani-Omprakash</h3>
                    <h4 className={classes.employeeTitle}>Senior Director of Strategic Partnerships</h4>
                    <p className={classes.employeeBio}>
                      Tina&apos;s strength lies in health promotion and literacy to empower patient communities with
                      patient-centric language and educational content. She is passionate about health research being
                      patient-powered. Tina is all about connecting patients, multidisciplinary healthcare providers,
                      and industry to create sustainable patient-forward change in healthcare spaces. Tina holds a
                      Master&apos;s in Public Health (MPH). She has a prominent blog called Own Your Crohn&apos;s and
                      sits on the Board of Directors of a patient and clinician-led 501c3 charitable organization called
                      South Asian IBD Alliance (SAIA).
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={12} className={classes.employeeGridItem}>
              <div className={classes.employeeGridBorder}>
                <div>
                  <img alt="Tena Novak" src={TenaNovak} className={classes.employeeImage} />
                  <hr className={classes.employeeHorizontalRule} />
                </div>
                <div className={classes.employeeInfoContainer}>
                  <div>
                    <h3 className={classes.employeeName}>Tena Novak</h3>
                    <h4 className={classes.employeeTitle}>Research Recruiting Manager</h4>
                    <p className={classes.employeeBio}>
                      Tena’s strong organizational skills & attention to detail ensure that each & every Insighter has a
                      smooth opportunity experience—from initial application to final payment. She is passionate about
                      growing The Insighters® community by identifying new groups & building new connections with both
                      patients and healthcare providers.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={12} className={classes.employeeGridItem}>
              <div className={classes.employeeGridBorder}>
                <div>
                  <img alt="Alyssa Medwynter" src={AlyssaMedwynter} className={classes.employeeImage} />
                  <hr className={classes.employeeHorizontalRule} />
                </div>
                <div className={classes.employeeInfoContainer}>
                  <div>
                    <h3 className={classes.employeeName}>Alyssa Medwynter</h3>
                    <h4 className={classes.employeeTitle}>Digital Marketing Specialist</h4>
                    <p className={classes.employeeBio}>
                      Spearheading the Digital Marketing division, Alyssa is well-versed in social media & passionate
                      about health advocacy off & online. She is proud of & believes in her work at The Insighters® as
                      she uses her creative talents to build an online community for all Insighters to be heard.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={12} className={classes.employeeGridItem}>
              <div className={classes.employeeGridBorder}>
                <div>
                  <img alt="Amy Christianson" src={AmyChristianson} className={classes.employeeImage} />
                  <hr className={classes.employeeHorizontalRule} />
                </div>
                <div className={classes.employeeInfoContainer}>
                  <div>
                    <h3 className={classes.employeeName}>Amy Christianson</h3>
                    <h4 className={classes.employeeTitle}>Recruitment & Engagement Manager</h4>
                    <p className={classes.employeeBio}>
                      Passionate about people and progress, Amy seeks a genuine connection between The Insighters®
                      mission and every patient, healthcare provider, and healthcare company who crosses her path. She
                      works alongside her team to build a strong and knowledgeable Insighter community, and loves to use
                      her organization and management skills to be an asset in any way she can! Amy holds a M.S. in
                      Management and Leadership and over 6 years of recruitment and engagement experience.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Footer />
    </div>
  );
};

export default MeetTheTeam;
