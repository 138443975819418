import React from 'react';
import axios from '../../utils/axios.utils';
import { Newsletter, AddNewsletterFormValues } from '../model';

interface NewslettersContextInterface {
  fetchNewsletters?: () => Promise<void>;
  newsletters?: Newsletter[];
  createNewsletter?: (formValues: AddNewsletterFormValues) => Promise<void>;
  newsletterSubmitting?: boolean;
  updateNewsletterOptIn?: () => Promise<void>;
}

const NewslettersContext = React.createContext<NewslettersContextInterface>({});
const NewslettersContextConsumer = NewslettersContext.Consumer;

const NewslettersContextProvider: React.FC = ({ children }) => {
  const [newsletters, setNewsletters] = React.useState<Newsletter[]>([]);
  const [newslettersLoading, setNewslettersLoading] = React.useState(false);
  const [newsletterSubmitting, setNewsletterSubmitting] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const fetchNewsletters = async () => {
    setNewslettersLoading(true);
    const response = await axios.get<string, any>(`newsletters`);

    setNewslettersLoading(false);
    setNewsletters(response?.data.result);
  };

  const createNewsletter = async (formValues: AddNewsletterFormValues) => {
    try {
      setNewsletterSubmitting(true);
      axios
        .post<string, any>(`newsletters.json`, {
          newsletter: { ...formValues },
        })
        .then((response) => {
          setNewsletterSubmitting(false);
          setNewsletters([...newsletters, response.data.result]);
        })
        .catch((error) => {
          setNewsletterSubmitting(false);
          const errorMessages = Object.values(error.response.data.error);
          setErrorMessage(errorMessages.join(', '));
        });
    } catch {
      setNewsletterSubmitting(false);
      setErrorMessage('Something went wrong, please reload the page and try again.');
    }
  };

  const updateNewsletterOptIn = async () => {
    setNewsletterSubmitting(true);

    await axios
      .put<string, any>(`newsletter_opt_in/0`)
      .then(() => {
        setNewsletterSubmitting(false);
      })
      .catch((error) => {
        setNewsletterSubmitting(false);
        const errorMessages = Object.values(error.response.data.error);
        setErrorMessage(errorMessages.join(', '));
      });
  };

  return (
    <NewslettersContext.Provider
      value={{
        fetchNewsletters,
        newsletters,
        createNewsletter,
        newsletterSubmitting,
        updateNewsletterOptIn,
      }}
    >
      {children}
    </NewslettersContext.Provider>
  );
};

export { NewslettersContextProvider, NewslettersContextConsumer, NewslettersContext };
