import React from 'react';
import { Grid, Typography } from '@material-ui/core';
// src/assets/redesignAssets/staticImages/ValuesRect.png
import ValuesRect from '../../../../assets/redesignAssets/staticImages/ValuesRect.png';
import ValuesDivider from '../../../../assets/redesignAssets/staticImages/ValuesDivider.png';
import ExcellenceIcon from '../../../../assets/redesignAssets/staticImages/ExcellenceIcon.png';
import DiversityIcon from '../../../../assets/redesignAssets/staticImages/DiversityIcon.png';
import ExpertiseIcon from '../../../../assets/redesignAssets/staticImages/ExpertiseIcon.png';
import useStyles from '../../constants/redesignStyles';

const valueStyles = {
  valueGrid: {
    width: '30%',
    height: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' as const,
  },
  dividerGrid: {
    width: 'auto',
    height: '30%',
  },
  dividerPic: {
    width: '70%',
    height: 'auto',
  },
  iconPic: {
    width: '40%',
    height: 'auto',
  },
};

const ValuesBox: React.FC = () => {
  const classes = useStyles();

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        padding: '20px',
      }}
    >
      {/* Tried to use the ValuesRect image as the background object here.
      But the imported image includes a shadow that throws off the alignment of everything else  */}
      <div
        // src={ValuesRect}
        // alt="Values"
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 0,
          borderRadius: 20,
          objectFit: 'cover',
        }}
      />
      <Grid
        container
        spacing={2}
        style={{ position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'center' }}
      >
        <Grid item style={valueStyles.valueGrid}>
          <img src={ExcellenceIcon} alt="Excellence" style={valueStyles.iconPic} />
          <Typography style={{ color: 'black', fontSize: 24, fontWeight: 700 }}>Excellence</Typography>
          <Typography className={classes.smallText}>40 years in business</Typography>
        </Grid>
        <Grid item style={valueStyles.dividerGrid}>
          <img src={ValuesDivider} alt="Values" style={valueStyles.dividerPic} />
        </Grid>
        <Grid item style={valueStyles.valueGrid}>
          <img src={DiversityIcon} alt="Diversity" style={valueStyles.iconPic} />
          <Typography style={{ color: 'black', fontSize: 24, fontWeight: 700 }}>Diversity</Typography>
          <Typography className={classes.smallText}>Women and Minority-Owned</Typography>
        </Grid>
        <Grid item style={valueStyles.dividerGrid}>
          <img src={ValuesDivider} alt="Values" style={valueStyles.dividerPic} />
        </Grid>
        <Grid item style={valueStyles.valueGrid}>
          <img src={ExpertiseIcon} alt="Diversity" style={valueStyles.iconPic} />
          <Typography style={{ color: 'black', fontSize: 24, fontWeight: 700 }}>Expertise</Typography>
          <Typography className={classes.smallText}>50,000+ Interviews & Surveys Completed</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ValuesBox;
