import React from 'react';
import { Card } from '@shopify/polaris';
import FormInput from '../../form/components/FormInput';
import { UserContext } from '../../auth/contexts/UserContext';
import { NewsletterSignupFormValues } from '../model';
import { NewslettersContext } from '../contexts/NewslettersContext';

const NewsLetterSignupForm: React.FunctionComponent = () => {
  const { user } = React.useContext(UserContext);
  const { updateNewsletterOptIn } = React.useContext(NewslettersContext);
  const [formValues, setFormValues] = React.useState<NewsletterSignupFormValues>({});

  React.useEffect(() => {
    setFormValues({
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
    });
  }, [user, setFormValues]);

  const handleUpdateNewsletterOptIn = () => {
    updateNewsletterOptIn();
  };

  return (
    <Card>
      <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
      <div id="mc_embed_signup" style={{ paddingRight: '20px' }}>
        <form
          action="https://theinsighters.us5.list-manage.com/subscribe/post?u=0dc651d5d87dd1ccc7bba5827&amp;id=a4a537596c"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <FormInput
            label="First Name"
            value={formValues?.first_name}
            inputProps={{ name: 'FNAME' }}
            onChange={(value) => setFormValues({ ...formValues, first_name: value })}
          />
          <FormInput
            label="Last Name"
            value={formValues?.last_name}
            inputProps={{ name: 'LNAME' }}
            onChange={(value) => setFormValues({ ...formValues, last_name: value })}
          />
          <FormInput
            label="Email Address"
            value={formValues?.email}
            inputProps={{ name: 'EMAIL' }}
            onChange={(value) => setFormValues({ ...formValues, email: value })}
          />
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="button"
            style={{ backgroundColor: '#72a295' }}
            onClick={() => handleUpdateNewsletterOptIn()}
          />
        </form>
      </div>
    </Card>
  );
};

export default NewsLetterSignupForm;
