import React from 'react';
import { Grid } from '@material-ui/core';

interface RecipientNameLineProps {
  user?: any;
}

const RecipientNameLine: React.FC<RecipientNameLineProps> = (props) => {
  const { user } = props;

  return (
    <Grid container style={{ padding: 15 }}>
      <Grid xs={2}>
        <p>
          <strong>ID</strong>
        </p>
        <p>{user?.uuid}</p>
      </Grid>
      <Grid xs={3}>
        <p>
          <strong>Name</strong>
        </p>
        <p>
          {user?.last_name}, {user?.first_name}
        </p>
      </Grid>
      <Grid xs={5}>
        <p>
          <strong>Email</strong>
        </p>
        <p>{user?.email}</p>
      </Grid>
    </Grid>
  );
};

export default RecipientNameLine;
