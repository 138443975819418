import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Card, Typography, Button } from '@material-ui/core';
import { DropZone } from '@shopify/polaris';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import { CaseStudy } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const picLabels = ['Thumbnail Image', 'Title Image', 'Center Image', 'Last Image'];

const CaseStudyEditImagesPage: React.FC = () => {
  let history = useHistory();
  let { caseStudyId } = useParams<{ caseStudyId: string }>();
  let classes = useStyles();

  let [caseStudy, setCaseStudy] = useState<CaseStudy>();
  let [imagesToAttach, setImagesToAttach] = useState<File[]>([]);
  let [imagePreviews, setImagePreviews] = useState<string[]>([]);
  let [imagesSubmitting, setImagesSubmitting] = useState<boolean>(false);
  let [imageErrors, setImageErrors] = useState<string[]>([]);

  let fetchCaseStudy = async () => {
    try {
      const response = await axios.get(`case_studies/${caseStudyId}`);
      setCaseStudy(response?.data?.result);

      let caseStudyImages = response?.data?.result?.case_study_images;
      let newImagePreviews = caseStudyImages.map((image: any) => {
        return image.s3_image_url;
      });
      setImagePreviews(newImagePreviews);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (caseStudyId) {
      fetchCaseStudy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStudyId]);

  const handleAttachFile = (index: number) => (files: File[]) => {
    const newImagesToAttach = [...imagesToAttach];
    const [firstFile] = files;
    newImagesToAttach[index] = firstFile;
    setImagesToAttach(newImagesToAttach);

    const previewUrl = URL.createObjectURL(firstFile);
    const newImagePreviews = [...imagePreviews];
    newImagePreviews[index] = previewUrl;
    setImagePreviews(newImagePreviews);
  };

  const handleSubmitImages = async () => {
    setImagesSubmitting(true);

    const formData = new FormData();
    imagesToAttach.forEach((file, index) => {
      formData.append(`case_study[images][${index}]`, file, file.name);
    });

    try {
      await axios.put(`case_studies/${caseStudyId}/attach_images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      history.push(`/admin/case-studies/${caseStudyId}/key-details`);
    } catch (error: any) {
      setImageErrors(error?.response?.data?.errors);
      setImagesSubmitting(false);
    }
  };

  return (
    <Grid container style={{ padding: '2%' }}>
      <AdminPageHeader
        title="Edit Case Study Images"
        backButton={{ label: 'Case Study Text', link: `/admin/case-studies/${caseStudyId}/edit` }}
      />
      <Grid item xs={12} style={{ padding: 15 }}>
        <Typography className={classes.h3}>{caseStudy?.title}</Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: 15 }}>
        <Typography className={classes.h3} style={{ color: 'red' }}>
          Note: When editing the images for an existing Case Study, you will need to re-upload all four images.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: 15 }}>
        <Card style={{ marginTop: 5, padding: '2%' }}>
          <Grid container>
            {picLabels.map((label, index) => (
              <React.Fragment key={index}>
                <Grid item xs={7}>
                  <Typography variant="h5">{label}</Typography>
                </Grid>
                <Grid item xs={7} style={{ marginBottom: 8 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DropZone type="image" label="" onDrop={handleAttachFile(index)} allowMultiple={false}>
                      <DropZone.FileUpload />
                    </DropZone>
                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {imagePreviews[index] && (
                        <img src={imagePreviews[index]} alt="Preview" style={{ width: '50%', height: 'auto' }} />
                      )}
                    </Grid>
                  </div>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ padding: 15 }}>
        <p style={{ color: 'red' }}>{imageErrors}</p>
      </Grid>
      <Grid item style={{ marginTop: 10, padding: 10 }}>
        <Button onClick={handleSubmitImages} style={siteWideStyles.primaryButton} disabled={imagesSubmitting}>
          {imagesSubmitting ? 'Submitting...' : 'Submit Images'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CaseStudyEditImagesPage;
