import React, { useState } from 'react';
import {
  Button,
  Grid,
  Link,
  CircularProgress,
  FilledInput,
  InputAdornment,
  FormControl,
  IconButton,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormLayout } from '@shopify/polaris';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { UserContext } from '../contexts/UserContext';
import FormInput from '../../form/components/FormInput';

const LoginForm: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, isLoading, loginFormError } = React.useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    // TODO: FIX THIS HARDCODED EMAIL
    // if (['colin@nextlinklabs.com', 'jzivkovic@nextlinklabs.com'].includes(email)) {
    //   window.location.href = '/admin/login';
    // } else {
    //   login(email, password);
    // }

    login(email, password);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <FormLayout>
      <div role="form" onKeyDown={handleKeyPress}>
        <FormInput type="email" label="Email" value={email} onChange={(value) => setEmail(value)} />
        <FormControl variant="filled" fullWidth>
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label"> Password</div>
          </div>
          <FilledInput
            id="formInput"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ height: '40px' }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              style: {
                fontSize: '1.4rem',
                paddingLeft: '10px',
                paddingTop: '10px',
              },
            }}
          />
        </FormControl>

        {loginFormError && <p style={{ color: 'red' }}>{loginFormError}</p>}
        <Grid container spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#E7AC34',
                height: 40,
                width: 'auto',
                fontSize: '16px',
                borderRadius: '4px',
                marginTop: 8,
                textTransform: 'capitalize',
                fontFamily: 'Cooper',
                minWidth: '120px',
              }}
              onClick={() => handleLogin()}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress style={{ width: '30px', height: '30px', marginTop: 0 }} /> : 'Log In'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#ddd',
                height: 40,
                width: 'auto',
                fontSize: '16px',
                borderRadius: '4px',
                marginTop: 8,
                textTransform: 'capitalize',
                fontFamily: 'Cooper',
              }}
              onClick={() => history.push('/sign-up')}
            >
              Register
            </Button>
          </Grid>
          <Grid item>
            <Link style={{ lineHeight: '60px', fontFamily: 'Cooper' }} href="/password-reset">
              Forgot your password?
            </Link>
          </Grid>
        </Grid>
      </div>
    </FormLayout>
  );
};

export default LoginForm;
