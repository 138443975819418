import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import ResourcesAccordion from './ResourcesAccordion';
import SeparatorBarComponent from './SeparatorBarComponent';
import Logo from '../../../assets/LogoWhite.png';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';

// TODO: Still need to put the LogIn Icon next to the LogIn text.

const RedesignSideNavigation: React.FC = () => {
  const classes = useStyles();

  const [resourceAccordionOpen, setResourceAccordionOpen] = useState(false);

  return (
    <Grid container style={{ position: 'relative', backgroundColor: '#545D78' }}>
      <Grid item xs={12}>
        <a href="/" className="Polaris-TopBar__NavigationIcon">
          <img alt="logo" src={Logo} style={{ height: '40px', margin: '10px 20px' }} />
        </a>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" className={classes.navHeader}>
          General Opportunities
        </Typography>
      </Grid>
      <SeparatorBarComponent />
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" className={classes.navHeader}>
          Healthcare Professionals
        </Typography>
      </Grid>
      <SeparatorBarComponent />
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" className={classes.navHeader}>
          About
        </Typography>
      </Grid>
      <SeparatorBarComponent />
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'center' }}
        onClick={() => setResourceAccordionOpen(!resourceAccordionOpen)}
      >
        <Typography variant="h6" className={classes.navHeader}>
          <span style={{ color: '#F3C66B' }}>Resources </span>
          <KeyboardArrowDown
            style={{
              transform: resourceAccordionOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease-in-out',
              fontWeight: 'bold',
            }}
          />
        </Typography>
      </Grid>
      <Collapse in={resourceAccordionOpen} timeout="auto" unmountOnExit>
        <Grid item xs={12} style={{ marginBottom: 10, paddingLeft: '12%', paddingRight: '12%' }}>
          <ResourcesAccordion />
        </Grid>
      </Collapse>
      <Grid item xs={12} style={{ marginTop: 25, display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h6" className={classes.navHeader}>
          Log/Sign In
        </Typography>
      </Grid>
      {/* An empty grid to push the background to the bottom of the view screen. */}
      <Grid item xs={12} style={{ minHeight: '60vh' }} />
    </Grid>
  );
};

export default RedesignSideNavigation;
