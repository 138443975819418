import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HoverButton from '../../common/HoverButton';
import MediumOrnament from '../../common/MediumOrnament';
import PreHeaderContainerImage from '../assets/PreHeaderContainer.png';
import TitleBackgroundImage from '../../../../assets/redesignAssets/aboutPage/TitleBackgroundImage.png';
import TitleSubBackgroundImage from '../assets/TitleSubBackground.png';
import HeroImageOther from '../../../../assets/redesignAssets/staticImages/HeroImageOther.png';
import OpportunityDetailHero from '../assets/OpportunityDetailHero.png';
import SectionOrnament from '../../common/SectionOrnament';
import useStyles from '../../constants/redesignStyles';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  heroTitleContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${TitleBackgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
  },
  heroTextWrapper: {
    marginLeft: '20%',
  },
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-4.25px',
    width: '100%',
    margin: 'auto 15% auto auto',
  },
  h1AccentLineContainerOverride: {
    backgroundImage: `url(${TitleSubBackgroundImage})`,
    margin: 'auto 15% auto -5%',
    height: '7em',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  h1AccentLineOverride: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '1.1em',
    width: '100%',
    letterSpacing: '-2.25px',
    marginLeft: '5%',
    background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    position: 'relative',
    top: '5px',
  },
}));

const AboutLandingSection: React.FC = () => {
  const classes = styleOverrides();

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
          }}
        >
          <img
            src={TitleBackgroundImage}
            style={{
              position: 'absolute',
              width: '100%',
              height: 'auto',
              top: 0,
              left: 0,
              zIndex: 0,
              objectFit: 'cover',
            }}
            alt="title background"
          />
          <div style={{ paddingLeft: '10%', paddingTop: '5%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: '50%',
              }}
            >
              <SectionOrnament text="Get Paid for Research" centerText={false} />
            </div>
            <h1 className={classes.h1Override} style={{ position: 'relative' }}>
              Join $125 Remote Interviews
            </h1>
            <div style={{ position: 'relative', display: 'inline-block', marginBottom: 15 }}>
              <img
                src={TitleSubBackgroundImage}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  zIndex: 0,
                  objectFit: 'cover',
                }}
                alt="title background"
              />
              <h1 className={classes.h1Override} style={{ position: 'relative' }}>
                <span
                  style={{
                    background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  For Parents & Guardians
                </span>
              </h1>
            </div>
            <br />
            <HoverButton text="Apply Now" />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <img src={OpportunityDetailHero} alt="Hero" style={{ width: '100%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
};

export default AboutLandingSection;
