import React from 'react';
import ReverseLogo from '../../../assets/reverse-logo.png';
import FacebookIcon from '../../../assets/social/facebook-icon.png';
import TwitterIcon from '../../../assets/social/twitter-icon.png';
import InstagramIcon from '../../../assets/social/instagram-icon.png';

const Footer: React.FunctionComponent = () => {
  return (
    <footer
      id="main-footer"
      style={{
        backgroundColor: '#3B4255',
        color: '#fff',
        paddingTop: 80,
        paddingBottom: 20,
        position: 'absolute',
        bottom: 'auto',
        width: '100%',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img alt="Logo" src={ReverseLogo} style={{ width: '250px', marginBottom: 40 }} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <ul style={{ display: 'inline', textAlign: 'center', padding: 0 }}>
          <li style={{ display: 'inline-block' }}>
            <a
              style={{ margin: '7px 7px 7px -3px' }}
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/TheInsightersdotcom/"
            >
              <img alt="FacebookIcon" src={FacebookIcon} style={{ width: 40 }} />
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a style={{ margin: 7 }} target="_blank" rel="noreferrer" href="https://www.instagram.com/theinsighters_/">
              <img alt="InstagramIcon" src={InstagramIcon} style={{ width: 40 }} />
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a style={{ margin: 7 }} target="_blank" rel="noreferrer" href="https://twitter.com/theinsighters_">
              <img alt="TwitterIcon" src={TwitterIcon} style={{ width: 40 }} />
            </a>
          </li>
        </ul>
      </div>
      <br />
      <div style={{ textAlign: 'center', fontSize: 12 }}>
        <ul style={{ display: 'inline', textAlign: 'center', padding: 0 }}>
          <li style={{ display: 'inline-block' }}>
            <a
              style={{
                marginRight: 20,
                fontFamily: 'Montserrat',
                color: '#fff',
                textDecoration: 'none',
              }}
              href="/privacy-policy"
            >
              Privacy Policy
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a
              style={{
                fontFamily: 'Montserrat',
                color: '#fff',
                textDecoration: 'none',
                marginRight: 20,
              }}
              href="/cookie-policy"
            >
              Cookie Policy
            </a>
          </li>
          <li style={{ display: 'inline-block', marginRight: 20 }}>
            <a style={{ fontFamily: 'Montserrat', color: '#fff', textDecoration: 'none' }} href="/terms-of-use">
              Terms of Use
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a style={{ fontFamily: 'Montserrat', color: '#fff', textDecoration: 'none' }} href="/pay-portal-terms">
              Pay Portal Terms
            </a>
          </li>
        </ul>
      </div>
      <div id="footer-bottom">
        <div className="container clearfix">
          <div id="footer-info" style={{ textAlign: 'center', fontFamily: 'Montserrat', fontSize: 12, marginTop: 40 }}>
            © SOMMER CONSULTING 2022 - ALL RIGHTS RESERVED.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
