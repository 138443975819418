import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Badge, Layout, Loading, Page, FormLayout, Button } from '@shopify/polaris';
import { Grid, Card, Typography } from '@material-ui/core';
import PaymentForm from '../components/PaymentForm';
import RecipientNameLine from '../components/RecipientNameLine';

import { PaymentsContext } from '../contexts/PaymentsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import PaymentConfirmationCard from '../components/PaymentConfirmationCard';

const EditPaymentPage: React.FunctionComponent = () => {
  const history = useHistory();
  const { paymentId } = useParams<Record<string, string>>();

  const [paymentFetched, setPaymentFetched] = useState(false);
  const [formValues, setFormValues] = useState<any>({});
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(false);

  const { payment, fetchPayment, paymentsLoading, fetchFormOptions, updatePayment, formError, paymentSubmitting } =
    useContext(PaymentsContext);

  useEffect(() => {
    if (!paymentFetched) {
      fetchPayment(paymentId);
      fetchFormOptions();
      setPaymentFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentFetched, paymentId]);

  useEffect(() => {
    console.log('payment in Page: ', payment);
    setFormValues(payment);
  }, [payment]);

  const shouldDisableUpdateButton = () => {
    if (!formValues?.category) return true;
    if (!formValues?.status) return true;
    return false;
  };

  useEffect(() => {
    setUpdateButtonDisabled(shouldDisableUpdateButton());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const getProfileLink = () => {
    return {
      content: 'View Insighter Profile',
      onAction: () => history.push(`/admin/insighters/${payment?.insighter_id}`),
    };
  };

  const loadingMarkup = paymentsLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Page
      fullWidth
      title={payment?.description}
      titleMetadata={
        payment?.status === 'active' ? (
          <Badge status="success">Active</Badge>
        ) : (
          <Badge status="critical">Inactive</Badge>
        )
      }
      primaryAction={payment?.insighter_id ? getProfileLink() : undefined}
      additionalMetadata={payment.created_at ? `Created on ${payment?.created_at}` : ''}
    >
      <Layout>
        <Layout.Section>
          <FormLayout>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 className="Polaris-Heading">Payment Information</h2>
              </Grid>
              <Grid item xs={12}>
                <RecipientNameLine user={payment?.user} />
              </Grid>
              <Grid item xs={12}>
                <PaymentForm formValues={formValues} setFormValues={setFormValues} user={payment?.user} />
              </Grid>
            </Grid>
            {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
            <Button
              primary
              onClick={() => updatePayment(payment?.id, formValues)}
              loading={paymentSubmitting}
              disabled={updateButtonDisabled}
            >
              Update Payment
            </Button>
          </FormLayout>
        </Layout.Section>
      </Layout>
      <br />
      {payment?.payment_confirmations?.length > 0 ? (
        <>
          {payment.payment_confirmations?.map((confirmation) => (
            <PaymentConfirmationCard key={confirmation.id} confirmation={confirmation} />
          ))}
        </>
      ) : (
        <Card style={{ padding: 20 }}>
          <Typography variant="h4">No payment confirmations available</Typography>
        </Card>
      )}
    </Page>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = paymentsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditPaymentPage;
