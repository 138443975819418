import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Phase, PhaseAddress, PhaseFormValues, PhaseLaunchChecklist, PhaseChecklistFormValues } from '../model';

interface ProjectPhasesContextInterface {
  fetchPhases?: (params?: string) => Promise<void>;
  fetchPhase?: (phaseId?: string) => Promise<void>;
  phases?: Phase[];
  phase?: Phase;
  phaseLoading?: boolean;

  statusFilter?: string;
  setStatusFilter?: React.Dispatch<React.SetStateAction<string>>;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, any[]>;
  updatePhase?: (phase_id: string, formValues: PhaseFormValues) => Promise<void>;
  createPhase?: (formValues: PhaseFormValues, onSuccess?: () => void) => Promise<void>;
  archivePhase?: (phase_id: string) => Promise<void>;
  activatePhase?: (phase_id: string) => Promise<void>;
  errorMessage?: string;
  phaseSubmitting?: boolean;

  fetchPhaseLaunchChecklist?: (phase_id: string) => Promise<void>;
  updatePhaseLaunchChecklist?: (phase_id: string, formValues: PhaseChecklistFormValues) => Promise<void>;
  phaseLaunchChecklist?: PhaseLaunchChecklist;

  sortPhases?: (sortValues: string) => void;

  phaseAddress?: PhaseAddress;
}

const ProjectPhasesContext = React.createContext<ProjectPhasesContextInterface>({
  formOptions: {},
});

const ProjectPhasesContextConsumer = ProjectPhasesContext.Consumer;
const ProjectPhasesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [phases, setPhases] = React.useState<Phase[]>();
  const [phase, setPhase] = React.useState<Phase>();
  const [phaseAddress, setPhaseAddress] = useState<PhaseAddress>();
  const [phaseLaunchChecklist, setPhaseLaunchChecklist] = React.useState<PhaseLaunchChecklist>({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState('');
  const [phaseLoading, setPhaseLoading] = React.useState(false);
  const [phaseSubmitting, setPhaseSubmitting] = React.useState<boolean>(false);
  const [formOptions, setFormOptions] = React.useState<Record<string, any[]>>({});

  const fetchPhases = async (params?: string) => {
    setPhaseLoading(true);
    const response = await axios.get<string, any>(`phases?${params}`);

    setPhases(response?.data?.result);
    setStatusFilter(response?.data?.applied_filters?.status?.value);
    setPhaseLoading(false);
  };

  const fetchPhase = async (phaseId?: string) => {
    setPhaseLoading(true);
    const response = await axios.get<string, any>(`phases/${phaseId}`);

    setPhase(response?.data?.result);
    setPhaseAddress(response?.data?.result?.address);
    setPhaseLoading(false);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`phases/new`);

    setFormOptions(response?.data);
  };

  const updatePhase = async (phase_id: string, formValues: PhaseFormValues) => {
    setPhaseSubmitting(true);

    const response = await axios.put<string, any>(`phases/${phase_id}`, {
      phase: { ...formValues },
    });

    setPhaseSubmitting(false);
    history.push(`/admin/phases/${response.data.result.id}`);
  };

  const createPhase = async (formValues: PhaseFormValues, onSuccess?: () => void) => {
    try {
      setPhaseSubmitting(true);
      axios
        .post<string, any>(`phases`, {
          phase: { ...formValues },
        })
        .then((response) => {
          if (onSuccess) {
            onSuccess();
          } else {
            history.push(`/admin/projects/${response.data.result.project_id}`);
          }
        })
        .catch((error) => {
          setPhaseSubmitting(false);
          setErrorMessage(error?.response?.data?.message);
        });
    } catch (error: any) {
      setPhaseSubmitting(false);
      setErrorMessage(error.response.data.message.map((message: string) => `${message}\n`));
    }
  };

  const activatePhase = async (phase_id: string) => {
    setPhaseSubmitting(true);

    await axios
      .put<string, any>(`phases/${phase_id}.json`, {
        phase: { status: 'active' },
      })
      .then(() => {
        setPhaseSubmitting(false);
        history.push(`/admin/phases/${phase.id}`);
      })
      .catch((error) => {
        setPhaseSubmitting(false);
        const errorMessages = Object.values(error.response.data.error);
        setErrorMessage(errorMessages.join(', '));
      });
  };

  const fetchPhaseLaunchChecklist = async (phase_id: string) => {
    setPhaseLoading(true);

    const response = await axios.get<string, any>(`phase_launch_checklists/${phase_id}.json`);

    setPhaseLoading(false);

    setPhaseLaunchChecklist(response?.data?.result);
  };

  const updatePhaseLaunchChecklist = async (phase_id: string, formValues: PhaseChecklistFormValues) => {
    setPhaseSubmitting(true);
    await axios.put<string, any>(`phase_launch_checklists/${phase_id}.json`, {
      phase_launch_checklist: { ...formValues },
    });

    setPhaseSubmitting(false);
    // history.push(`/admin/phases/${phase.id}/launch-checklist`);
  };

  const archivePhase = async (phase_id: string) => {
    setPhaseSubmitting(true);

    const response = await axios.put<string, any>(`phases/${phase_id}`, {
      phase: { status: 'archived', update_status: 'false' },
    });

    setPhaseSubmitting(false);
    history.push('/admin/phases/');
  };

  const sortPhases = (sortValues: string) => {
    axios.post('/phases/sort.json', {
      sorting: sortValues,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  return (
    <ProjectPhasesContext.Provider
      value={{
        fetchPhases,
        fetchPhase,
        phases,
        phase,
        phaseLoading,

        statusFilter,
        setStatusFilter,

        fetchFormOptions,
        formOptions,
        updatePhase,
        createPhase,
        archivePhase,
        activatePhase,
        errorMessage,
        phaseSubmitting,

        fetchPhaseLaunchChecklist,
        updatePhaseLaunchChecklist,
        phaseLaunchChecklist,

        sortPhases,

        phaseAddress,
      }}
    >
      {children}
    </ProjectPhasesContext.Provider>
  );
};

export { ProjectPhasesContextProvider, ProjectPhasesContextConsumer, ProjectPhasesContext };
