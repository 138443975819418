import React from 'react';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { Opportunity } from '../model';
import { InvitationOpportunitiesContext } from '../../invitationOpportunities/contexts/InvitationOpportunitiesContext';

interface ClosedOpportunityCardProps {
  opportunity?: Opportunity;
}

const ClosedOpportunityCard: React.FC<ClosedOpportunityCardProps> = (props) => {
  const history = useHistory();
  const { opportunity } = props;
  const { fetchInvitationOpportunity, invitationOpportunity } = React.useContext(InvitationOpportunitiesContext);

  const [urlParamsUpdated, setUrlParamsUpdated] = React.useState(false);

  React.useEffect(() => {
    if (opportunity.id.toString() === '39') {
      fetchInvitationOpportunity('13');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity]);

  return (
    <div className="Polaris-Card" style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div className="Polaris-MediaCard">
        <div className="Polaris-MediaCard__MediaContainer" style={{ display: 'flex', alignItems: 'center' }}>
          <img
            alt=""
            width="100%"
            height="auto"
            src={opportunity?.s3_image_url}
            style={{ objectFit: 'cover', objectPosition: 'center center' }}
          />
        </div>
        <div className="Polaris-MediaCard__InfoContainer" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="Polaris-Card__Section" style={{ paddingBottom: '0' }}>
            <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingTight">
              <div className="Polaris-Stack__Item">
                <div className="Polaris-MediaCard__Heading">
                  <h2 className="Polaris-Heading" style={{ fontSize: 24 }}>
                    {opportunity?.name}
                  </h2>
                </div>
              </div>
              <br />
              <div className="Polaris-Stack__Item">
                <p style={{ whiteSpace: 'break-spaces' }}>{opportunity?.description}</p>
              </div>
            </div>
            <div style={{ marginTop: 15 }} className="Polaris-Stack__Item">
              <span className="Polaris-Navigation__Indicator">
                <span className="Polaris-Indicator Polaris-Indicator--pulseIndicator warning" />
              </span>
              <p style={{ display: 'inline-block', marginLeft: 10, position: 'relative', top: -4, fontWeight: 600 }}>
                Opportunity Closed
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <p>
        This opportunity is now closed. To find other opportunities like this in the future, please sign up as an
        Insighter below.
      </p>
      <div style={{ marginTop: '20px' }}>
        <Grid container justifyContent="center">
          <Grid item md={10} xs={10}>
            <Grid container spacing={4}>
              <Grid item style={{ marginBottom: '20px' }} container justifyContent="center">
                <button
                  style={{ backgroundColor: '#006E52', color: 'white' }}
                  className="Polaris-Button"
                  type="button"
                  onClick={() => history.push('/sign-up')}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">Sign Up</span>
                  </span>
                </button>
                &emsp;
                <button
                  style={{ backgroundColor: 'white', color: 'black' }}
                  className="Polaris-Button"
                  type="button"
                  onClick={() => history.push('/public-opportunities')}
                >
                  <span className="Polaris-Button__Content">
                    <span className="Polaris-Button__Text">View Other Opportunities</span>
                  </span>
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ClosedOpportunityCard;
