import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import CalendlyEventForm from '../components/CalendlyEventForm';
import { CalendlyEventContext } from '../contexts/CalendlyEventContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';
import Page from '../../shared/components/Page';

const EditCalendlyEvent: React.FunctionComponent = () => {
  const { calendlyEventId } = useParams<Record<string, string>>();
  const [calendlyEventFetched, setSpecialtyFetched] = React.useState<boolean>(false);
  const { calendlyEvent, fetchCalendlyEvent, calendlyEventsLoading } = React.useContext(CalendlyEventContext);

  React.useEffect(() => {
    if (!calendlyEventFetched) {
      fetchCalendlyEvent(calendlyEventId);
      setSpecialtyFetched(true);
    }
  }, [calendlyEventFetched, fetchCalendlyEvent, calendlyEventId]);

  const loadingMarkup = calendlyEventsLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title={`${calendlyEvent?.name} `}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <CalendlyEventForm calendlyEvent={calendlyEvent} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = calendlyEventsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditCalendlyEvent;
