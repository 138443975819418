import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';
import HeroImageOther from '../../../../assets/redesignAssets/staticImages/HeroImageOther.png';
// HeroImageOther seems to fit better.  But we'll hold on to it for now in case that changes as we add other components
import HeroImageSource from '../../../../assets/redesignAssets/staticImages/HeroImageSource.png';

const HomeLandingSection: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={6} style={{ padding: '70px 30px 20px' }}>
        <Typography className={classes.h1}>Bridging Stories Into Impactful Insights</Typography>
        <br />
        <HoverButton text="Find a Paid Opportunity" />
      </Grid>
      <Grid item xs={6}>
        <img src={HeroImageOther} alt="Hero" style={{ width: '100%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
};

export default HomeLandingSection;
