import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@shopify/polaris';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Phase } from '../model';
import { Payment } from '../../payments/model';

const getStatusColor = (status: string) => {
  if (status === 'failed') {
    return 'red';
  }

  if (status === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

export const paymentColumns: IDataTableColumn<any>[] = [
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Amount',
    selector: 'number_amount',
    sortable: true,
    cell: ({ number_amount }) => <div>${number_amount}</div>,
  },
  {
    name: 'Notes',
    selector: 'notes',
    sortable: true,
  },
  {
    name: 'Processed At',
    selector: 'processed_at',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status),
        }}
      >
        {status}
      </div>
    ),
  },
];

interface PhasePaymentsProps {
  phase: Phase;
  payments: Payment[];
}

const PhasePayments: React.FC<PhasePaymentsProps> = (props) => {
  const { payments, phase } = props;
  const history = useHistory();

  return (
    <>
      <Card sectioned>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Payments & Expenses</h2>
            </div>
            <div className="Polaris-Stack__Item">
              <div className="Polaris-ButtonGroup">
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  <Button primary url={`/admin/projects/${phase?.project_id}/payments/new?phase_id=${phase?.id}`}>
                    Add Payment
                  </Button>
                  <span style={{ marginRight: 7 }} />
                  <Button primary url={`/admin/projects/${phase?.project_id}/expenses/new?phase_id=${phase?.id}`}>
                    Add Expense
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          onRowClicked={(payment: Payment) => history.push(`/admin/payments/${payment?.id}`)}
          columns={paymentColumns}
          data={payments}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          noHeader
        />
        <div className="Polaris-Card__Section">
          <p>
            <strong>Payment Total:</strong> ${phase?.payments_sum}
          </p>
        </div>
      </Card>
    </>
  );
};

export default PhasePayments;
