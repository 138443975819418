import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import TechPartnersCarousel from '../components/TechPartnersCarousel';
import SectionOrnament from '../../common/SectionOrnament';
import LightBulbIcon from '../../../../assets/redesignAssets/staticImages/LightbulbIcon.png';
import useStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';

const TechnologyPartnersSection: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <img src={LightBulbIcon} alt="Light Bulb Icon" style={{ width: '100%', height: 'auto' }} />
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid container>
          <Grid item xs={5}>
            <SectionOrnament text="We work with the best" />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.h2}>Our Trusted Technology Partners</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Typography className={classes.smallText}>OTHER PARTNERS</Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.hrLine} />
          </Grid>
          <Grid item xs={12}>
            <TechPartnersCarousel />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <HoverButton text="Explore Our Partnerships" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TechnologyPartnersSection;
