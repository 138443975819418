import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import SeparatorBarComponent from './SeparatorBarComponent';
import ResourceAccordionPaint from '../../../assets/redesignAssets/staticImages/resource-accordion-paint.png';

const ResourcesAccordion: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        position: 'relative',
        width: '100%',
        height: 'auto',
      }}
    >
      <img
        src={ResourceAccordionPaint}
        alt="Resource Accordion Paint"
        style={{ position: 'absolute', width: '100%', height: 'auto', zIndex: 0 }}
      />
      <Grid item xs={12} style={{ marginTop: 18, display: 'flex', justifyContent: 'center', zIndex: 1 }}>
        <Typography variant="h6" className={classes.navHeader}>
          Articles
        </Typography>
      </Grid>
      <SeparatorBarComponent />
      <Grid item xs={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'center', zIndex: 1 }}>
        <Typography variant="h6" className={classes.navHeader}>
          Case Studies
        </Typography>
      </Grid>
      <SeparatorBarComponent />
      <Grid item xs={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'center', zIndex: 1 }}>
        <Typography variant="h6" className={classes.navHeader}>
          Events
        </Typography>
      </Grid>
      <SeparatorBarComponent />
      <Grid item xs={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'center', zIndex: 1 }}>
        <Typography variant="h6" className={classes.navHeader}>
          Got a Question?
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ResourcesAccordion;
