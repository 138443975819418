import React from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import siteWideStyles from '../../styles/siteWideStyles';

interface AdminPageHeaderProps {
  title: string;
  backButton?: {
    label: string;
    link: string;
  };
  primaryButton?: {
    label: string;
    link: string;
  };
}

const AdminPageHeader: React.FC<AdminPageHeaderProps> = (props) => {
  let classes = useStyles();
  let history = useHistory();

  let { title, backButton, primaryButton } = props;

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {backButton && (
            <Button onClick={() => history.push(backButton?.link)} style={siteWideStyles.secondaryButton}>
              {backButton?.label}
            </Button>
          )}
        </div>
        <div>
          {primaryButton && (
            <Button onClick={() => history.push(primaryButton?.link)} style={siteWideStyles.primaryButton}>
              {primaryButton.label}
            </Button>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.h2}>{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default AdminPageHeader;
