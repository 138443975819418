import React, { useState } from 'react';
import { Button, Card, TextField } from '@shopify/polaris';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  EmailIcon,
} from 'react-share';
import { makeStyles } from '@material-ui/core';
import { Opportunity } from '../model';

const useStyles = makeStyles({
  shareNetwork: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginRight: 10,
    textAlign: 'center',
  },
  shareButton: {
    cursor: 'pointer',
  },
});

interface SocialShareCardProps {
  opportunity: Opportunity;
}

const SocialShareCard: React.FC<SocialShareCardProps> = (props) => {
  const classes = useStyles();
  const { opportunity } = props;
  const [copyButtonText, setCopyButtonText] = useState('Copy Link');

  const shareUrl = `https://theinsighters.com/public-opportunities/${opportunity.id}`;
  const title = 'GitHub';

  const handleClick = () => {
    navigator.clipboard.writeText(shareUrl);
    setCopyButtonText('Copied!');
  };

  return (
    <Card>
      <Card.Section title="Promote Opportunity">
        <div style={{ margin: '10px 0 0' }}>
          <div className={classes.shareNetwork}>
            <FacebookShareButton url={shareUrl} quote={title} className={classes.shareButton}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          <div className={classes.shareNetwork}>
            <TwitterShareButton url={shareUrl} className={classes.shareButton}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className={classes.shareNetwork}>
            <EmailShareButton url={shareUrl} className={classes.shareButton}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
          <div className={classes.shareNetwork}>
            <PinterestShareButton
              media="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
              url={shareUrl}
              className={classes.shareButton}
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
          </div>
          <div className={classes.shareNetwork}>
            <LinkedinShareButton url={shareUrl} className={classes.shareButton}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>
        </div>
      </Card.Section>
      <Card.Section title="Share link to opportunity">
        <TextField value={shareUrl} onChange={() => null} label="" type="email" />
        <br />
        <Button primary onClick={handleClick}>
          {copyButtonText}
        </Button>
      </Card.Section>
    </Card>
  );
};

export default SocialShareCard;
