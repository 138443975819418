import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Page from '../../shared/components/Page';
import BackButton from '../../navigation/components/BackButton';
import { Icon } from '../model';
import axios from '../../utils/axios.utils';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import siteWideStyles from '../../styles/siteWideStyles';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const IconsListPage: React.FC = () => {
  let history = useHistory();
  let classes = useStyles();

  let [icons, setIcons] = useState<Icon[]>([]);

  let fetchIcons = async () => {
    try {
      let response = await axios.get('icons');
      setIcons(response?.data?.result);
    } catch {
      console.log('Error fetching icons');
    }
  };

  useEffect(() => {
    fetchIcons();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BackButton pageAction={false} url="/admin/tools" />
      <Page
        fullWidth
        title="Icons"
        primaryAction={{
          content: 'New Icon',
          onAction: () => history.push('/admin/icons/create/'),
        }}
      >
        <Card style={{ padding: 10 }}>
          <Grid container>
            {icons.map((icon) => (
              <Grid
                item
                xs={1}
                key={icon.id}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 3,
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  margin: 5,
                }}
                onClick={() => history.push(`/admin/icons/${icon.id}/edit`)}
              >
                <img src={icon.s3_image_url} alt={icon.name} style={{ width: '50px', height: 'auto' }} />
                <Typography className={classes.smallText} style={{ marginTop: 5 }}>
                  {icon.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Page>
    </>
  );
};

export default IconsListPage;
