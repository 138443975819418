import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { RespondentFormValues } from '../model';

interface BulkRespondentsContextInterface {
  createBulkRespondents?: (
    phase_id: string,
    formValues: RespondentFormValues,
    afterCreate?: () => void,
  ) => Promise<void>;
  respondentSubmitting?: boolean;
}

const BulkRespondentsContext = React.createContext<BulkRespondentsContextInterface>({});

const BulkRespondentsContextConsumer = BulkRespondentsContext.Consumer;
const BulkRespondentsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [respondentSubmitting, setRespondentSubmitting] = React.useState(false);

  const createBulkRespondents = async (
    phase_id: string,
    formValues: RespondentFormValues,
    afterCreate?: () => void,
  ) => {
    setRespondentSubmitting(true);

    const response = await axios.post<string, any>(`phases/${phase_id}/bulk_respondents`, {
      respondent: { ...formValues },
    });

    setRespondentSubmitting(false);

    if (afterCreate) {
      afterCreate();
    } else {
      history.push(`/admin/respondents/${response.data.result.id}`);
    }
  };

  return (
    <BulkRespondentsContext.Provider
      value={{
        createBulkRespondents,
        respondentSubmitting,
      }}
    >
      {children}
    </BulkRespondentsContext.Provider>
  );
};

export { BulkRespondentsContextProvider, BulkRespondentsContextConsumer, BulkRespondentsContext };
