import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Badge, Layout, Button } from '@shopify/polaris';
import { CircularProgress, Grid } from '@material-ui/core';
import moment from 'moment';
import Page from '../../shared/components/Page';
import SelectInput from '../../form/components/SelectInput';
import DataTable from '../../dataTable/DataTable/DataTable';
import { ProjectPhasesContext } from '../../projectPhases/contexts/ProjectPhasesContext';
import { SurveyResponsesContext } from '../contexts/SurveyResponsesContext';
import { BulkRespondentsContext } from '../../respondents/contexts/BulkRespondentsContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import BackButton from '../../navigation/components/BackButton';
import WarningIcon from '../../insighterPayments/components/WarningIcon';

const LeadFinder: React.FC = () => {
  const { phaseId } = useParams<Record<string, string>>();
  const history = useHistory();

  const [projectFetched, setProjectFetched] = React.useState(false);
  const [surveyResponsesFetched, setSurveyResponsesFetched] = React.useState(false);
  const [toggledClearRows] = React.useState(false);
  const [selectedResponses, setSelectedResponses] = React.useState([]);
  const [physicalLocation, setPhysicalLocation] = React.useState('None');
  const [healthcareRole, setHealthcareRole] = React.useState('');

  const { createBulkRespondents, respondentSubmitting } = React.useContext(BulkRespondentsContext);

  const {
    createManualImport,
    updateSurveyResponse,
    fetchSurveyResponses,
    surveyResponses,
    surveyResponseLoading,
    surveyResponseSubmitting,
  } = React.useContext(SurveyResponsesContext);

  const { phase, fetchPhase } = React.useContext(ProjectPhasesContext);

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 0.6,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Gender',
      selector: 'gender',
      sortable: true,
    },
    {
      name: 'Race',
      selector: 'race',
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: 'mobile_phone',
      sortable: true,
    },
    {
      name: 'Zip Code',
      selector: 'zip',
      sortable: true,
    },
    {
      name: 'Actions',
      selector: 'actions',
      sortable: false,
      grow: 2.5,
      cell: (row) => (
        <>
          <div style={{ marginRight: 10 }}>
            <Button
              destructive
              onClick={() =>
                updateSurveyResponse(row.id, { hide_from_import: true }, () =>
                  history.push(`/admin/phases/${phaseId}/survey-responses`),
                )
              }
            >
              Hide
            </Button>
          </div>
          <br />
          <div>
            <Button
              disabled={row.become_an_insighter === '6'}
              onClick={() =>
                updateSurveyResponse(row.id, { hide_from_import: true, create_profile: true }, () =>
                  history.push(`/admin/phases/${phaseId}/survey-responses`),
                )
              }
            >
              {row.insighter_id ? 'Update Profile and Hide' : 'Import Profile and Hide'}
            </Button>
          </div>
        </>
      ),
    },
  ];

  React.useEffect(() => {
    if (!projectFetched) {
      fetchPhase(phaseId);
      setProjectFetched(true);
    }
  }, [projectFetched, fetchPhase, phaseId]);

  React.useEffect(() => {
    if (!surveyResponsesFetched) {
      fetchSurveyResponses(phaseId);
      setSurveyResponsesFetched(true);
    }
  }, [fetchSurveyResponses, surveyResponsesFetched, phaseId]);

  const handleSelectedInsighters = ({ selectedRows }) => {
    setSelectedResponses([...selectedRows]);
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <BackButton pageAction={false} url={`/admin/phases/${phase?.id}`} />
        </Grid>
        <Grid item>
          <div className="Polaris-Card__SectionHeader" style={{ padding: '2rem', top: 20, position: 'relative' }}>
            <Button primary url={`/admin/phases/${phase?.id}/survey-mapping`}>
              View Survey Mapping
            </Button>

            <div style={{ marginTop: 10 }}>
              {phase?.missing_survey_mapping_field ? <WarningIcon label="Missing Fields" /> : null}
            </div>
          </div>
        </Grid>
      </Grid>
      <Page
        fullWidth
        title={`${phase?.name} for ${phase?.project_number}`}
        titleMetadata={
          phase?.status === 'active' ? (
            <Badge status="success">Active</Badge>
          ) : (
            <Badge status="critical">Inactive</Badge>
          )
        }
        additionalMetadata={`Created on ${phase?.created_at}`}
      >
        <Layout>
          <Layout.Section>
            <div>
              <div className="filter-container" style={{ width: '100%', marginBottom: 25 }}>
                <h2 className="Polaris-Heading" style={{ margin: '10px 0' }}>
                  Qualtrics Import Details
                </h2>
                <Grid container>
                  <Grid item>
                    <Grid container spacing={6}>
                      <Grid item>
                        <div className="Polaris-Card__SectionHeader" style={{ padding: '2rem 0 0.8rem' }}>
                          <h3 aria-label="Qualtrics Survey ID" className="Polaris-Subheading">
                            Qualtrics Survey ID
                          </h3>
                        </div>
                        <p>{phase?.qualtrics_survey_id}</p>
                      </Grid>
                      <Grid item>
                        <div className="Polaris-Card__SectionHeader" style={{ padding: '2rem 0 0.8rem' }}>
                          <h3 aria-label="Last Qualtrics Import" className="Polaris-Subheading">
                            Last Qualtrics Import
                          </h3>
                        </div>
                        <p>{moment().format('MMMM DD, YYYY')}</p>
                      </Grid>
                      <Grid item>
                        <div className="Polaris-Card__SectionHeader" style={{ padding: '2rem 0 0.8rem' }}>
                          <h3 aria-label="Next Qualtrics Import" className="Polaris-Subheading">
                            Next Scheduled Import
                          </h3>
                        </div>
                        <p>{moment().add(1, 'days').format('MMMM DD, YYYY')} at 5:00 AM EST</p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <>
                  <Grid container justifyContent="space-between">
                    <Grid item style={{ alignItems: 'end', display: 'flex' }}>
                      <Button primary onClick={() => createManualImport(phaseId)} disabled={surveyResponseSubmitting}>
                        {surveyResponseSubmitting ? 'Pulling Responses From Qualtrics' : 'Refresh Survey Responses'}
                      </Button>
                      {surveyResponseSubmitting && (
                        <CircularProgress style={{ marginLeft: 20, position: 'relative', top: 12 }} />
                      )}
                    </Grid>

                    <Grid item style={{ width: 'auto' }}>
                      {['60', '61', '62', '63', '64', '65'].includes(phase?.id.toString()) ? (
                        <Grid container>
                          <Grid item style={{ width: 200, marginRight: 7 }}>
                            <SelectInput
                              label="Physical Location"
                              choices={[
                                { label: 'New York', value: 'New York (3rd Ave, Midtown)' },
                                { label: 'Philadelphia', value: 'Philadelphia (Arch Street, Center City)' },
                                { label: 'Atlanta', value: 'Atlanta (Peachtree Dunwoody Rd near Perimeter Center)' },
                                { label: 'Chicago', value: 'Chicago (Michigan Ave, Downtown)' },
                                { label: 'None', value: null },
                              ]}
                              value={physicalLocation}
                              onChange={(value: string) => setPhysicalLocation(value)}
                            />
                          </Grid>
                          <Grid item style={{ marginTop: 30 }}>
                            <Button
                              onClick={() =>
                                fetchSurveyResponses(phaseId, physicalLocation ? `location=${physicalLocation}` : null)
                              }
                            >
                              Apply Filter
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item style={{ width: 200, marginRight: 7 }}>
                            <SelectInput
                              label="Healthcare Role"
                              choices={[
                                { label: 'Any', value: '' },
                                { label: 'Patient', value: 'Patient' },
                                { label: 'Family Caregiver', value: 'Family Caregiver' },
                                { label: 'Physician', value: 'Physician' },
                                { label: 'Physician Associate', value: 'Physician Associate' },
                                { label: 'Nurse Practictioner', value: 'Nurse Practictioner' },
                                { label: 'Nurse', value: 'Nurse' },
                                { label: 'Pharmacist', value: 'Pharmacist' },
                                { label: 'Occupational Therapist', value: 'Occupational Therapist' },
                                { label: 'Physical Therapist', value: 'Physical Therapist' },
                                { label: 'Medical Administrator', value: 'Medical Administrator' },
                                { label: 'Midwife', value: 'Midwife' },
                                { label: 'Medical Assistant', value: 'Medical Assistant' },
                                {
                                  label: "I don't think of myself as a patient, but I don't work in healthcare either",
                                  value: 'think of myself',
                                },
                              ]}
                              value={healthcareRole}
                              onChange={(value: string) => setHealthcareRole(value)}
                            />
                          </Grid>
                          <Grid item style={{ marginTop: 30 }}>
                            <Button
                              onClick={() =>
                                fetchSurveyResponses(
                                  phaseId,
                                  healthcareRole ? `healthcare_role=${healthcareRole}` : null,
                                )
                              }
                            >
                              Apply Filter
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              </div>
              <h2 className="Polaris-Heading" style={{ margin: '10px 0' }}>
                New Survey Responses
              </h2>
              <DataTable
                columns={columns}
                data={surveyResponses}
                progressPending={surveyResponseLoading}
                selectableRows
                onSelectedRowsChange={handleSelectedInsighters}
                clearSelectedRows={toggledClearRows}
                pagination
                paginationPerPage={25}
                highlightOnHover
                pointerOnHover
                noHeader
              />
              <br />
              <Button
                primary
                onClick={() =>
                  createBulkRespondents(
                    phaseId,
                    { survey_response_ids: selectedResponses.map((lead) => lead.id) },
                    () => history.push(`/admin/phases/${phaseId}`),
                  )
                }
                loading={respondentSubmitting}
              >
                Submit Selected Responses
              </Button>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default LeadFinder;
