import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import StayConnectedRect from '../../../assets/redesignAssets/staticImages/StayConnectedRect.png';
import useStyles from '../constants/redesignStyles';
import HoverButton from './HoverButton';

const StayConnected: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${StayConnectedRect})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '350px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        paddingLeft: '10%',
      }}
    >
      <Grid item xs={12}>
        <Typography className={classes.h2} style={{ color: 'white' }}>
          Stay Informed,
        </Typography>
        <Typography className={classes.h2} style={{ color: 'white' }}>
          Stay Connected
        </Typography>
        <p style={{ marginTop: 5, fontSize: 10, color: 'white' }}>Get the Latest Opportunities, Insights & Events</p>
        <Grid
          container
          style={{
            backgroundColor: 'white',
            borderRadius: '20px',
            marginTop: 15,
            alignItems: 'center',
            padding: '3px 3px 3px 20px',
            width: '60%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={5}>
            <p style={{ fontSize: 12 }}>Enter your email</p>
          </Grid>
          <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <HoverButton text="Sign Me Up" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StayConnected;
