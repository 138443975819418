import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import carouselCardDetails from '../../constants/carouselCardDetails';

const useStyles = makeStyles({
  '@keyframes slide': {
    '0%': {
      transform: 'translateX(0%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
  slideContainer: {
    overflow: 'hidden',
    display: 'flex',
    width: '100%', // Adjust to the width of the parent container
  },
  slideTrack: {
    display: 'flex',
    animation: '$slide 20s linear infinite',
    width: '200%', // Double the width to accommodate duplicate slides
  },
  slideCard: {
    width: '20%', // Adjust based on the number of words
    flexShrink: 0,
  },
});

const doubledSlides = Object.values(carouselCardDetails)?.concat(Object.values(carouselCardDetails));

const TechPartnersCarousel: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.slideContainer}>
      <div className={classes.slideTrack}>
        {doubledSlides.map((cardDetail, index) => (
          <Grid item className={classes.slideCard} key={`word-${index}`}>
            <img src={cardDetail.imgUrl} alt={cardDetail.title} style={{ height: 100, width: 'auto' }} />
          </Grid>
        ))}
      </div>
    </Grid>
  );
};

export default TechPartnersCarousel;
