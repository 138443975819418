import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import DataTable from '../../dataTable';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import { BlogAuthor } from '../model';

import axios from '../../utils/axios.utils';

const BlogAuthorsListPage: React.FC = () => {
  const history = useHistory();

  const [blogAuthors, setBlogAuthors] = useState<BlogAuthor[]>([]);

  const fetchBlogAuthors = async () => {
    try {
      const response = await axios.get('blog_authors');
      setBlogAuthors(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogAuthors();
  }, []);

  const blogAuthorColumns = [
    {
      name: 'Pic',
      selector: 'pic',
      sortable: true,
      grow: 0.5,
      cell: (row: any) => (
        <img src={row.s3_image_url} alt={row.name} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
      ),
    },
    { name: 'Name', selector: 'name', sortable: true },
    { name: 'Title', selector: 'title', sortable: true },
    {
      name: 'Edit',
      selector: 'edit',
      grow: 0.5,
      cell: (row: any) => (
        <Edit
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/admin/blog-authors/${row.id}/edit`);
          }}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={3} style={{ padding: '2%' }}>
      <AdminPageHeader
        title="Blog Authors"
        primaryButton={{ label: 'Add Blog Author', link: '/admin/blog-authors/new' }}
        backButton={{ label: 'Admin Tools', link: '/admin/tools' }}
      />
      <Grid item xs={12}>
        <Card>
          <DataTable
            data={blogAuthors}
            columns={blogAuthorColumns}
            onRowClicked={(rowData: any) => history.push(`/admin/blog-authors/${rowData.id}`)}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default BlogAuthorsListPage;
