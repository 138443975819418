import React from 'react';
import { useHistory } from 'react-router';
import { Grid, makeStyles, createStyles, AppBar } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logo from '../../../assets/logo.png';
import LoginSplat from '../../../assets/redesignAssets/staticImages/LogInSplat.png';
import Users from '../../../assets/redesignAssets/staticImages/Users.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: '40px',
      margin: '10px 20px',
      lineHeight: '28px',
      display: 'none',
      [theme.breakpoints.up(360)]: {
        display: 'inline-block',
      },
    },
  }),
);

const styles = {
  navMenuText: {
    cursor: 'pointer',
    lineHeight: '60px',
    textDecoration: 'none',
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Crimson Text',
    fontSize: 18,
    letterSpacing: '-0.2px',
    width: 'auto',
    background: 'none',
    border: 'none',
    padding: 0,
  },
  buttonLink: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
};

interface RedesignTopNavigationProps {
  mobileNavigationActive: any;
  setMobileNavigationActive: any;
}

const RedesignTopNavigation: React.FC<RedesignTopNavigationProps> = (props) => {
  const { mobileNavigationActive, setMobileNavigationActive } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      className="Polaris-TopBar"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        boxShadow: 'none',
        background: 'inherit',
      }}
    >
      <Grid item>
        <button
          type="button"
          className="Polaris-TopBar__NavigationIcon"
          aria-label="Toggle menu"
          onClick={() => setMobileNavigationActive(true)}
          style={{ marginTop: 7 }}
        >
          <span className="Polaris-Icon">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
              <path d="M19 11H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" />
            </svg>
          </span>
        </button>
      </Grid>
      <Grid item md={3}>
        <button type="button" style={{ ...styles.buttonLink, float: 'left' }} onClick={() => history.push('/redesign')}>
          <img alt="logo" src={Logo} className={classes.logo} style={{ float: 'left' }} />
        </button>
      </Grid>
      <Grid item md={9}>
        <Grid container justifyContent="space-between">
          <Grid item style={{ maxWidth: '600px', width: '80%' }}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <button
                  type="button"
                  onClick={() => history.push('/redesign/opportunities/general')}
                  className="Polaris-Heading Polaris-TopBar__ContextControl"
                  style={styles.navMenuText}
                >
                  General Opportunities
                </button>
              </Grid>
              <Grid item>
                <button
                  type="button"
                  onClick={() => history.push('/redesign/opportunities/healthcare_professional')}
                  className="Polaris-Heading Polaris-TopBar__ContextControl"
                  style={styles.navMenuText}
                >
                  Healthcare Professionals
                </button>
              </Grid>
              <Grid item>
                <button
                  type="button"
                  onClick={() => history.push('/redesign/about')}
                  className="Polaris-Heading Polaris-TopBar__ContextControl"
                  style={styles.navMenuText}
                >
                  About
                </button>
              </Grid>
              <Grid item>
                <button
                  type="button"
                  onClick={() => history.push('/redesign/resources')}
                  className="Polaris-Heading Polaris-TopBar__ContextControl"
                  style={styles.navMenuText}
                >
                  Resources
                  <KeyboardArrowDownIcon
                    style={{
                      transform: 'rotate(0deg)',
                      transition: 'transform 0.3s ease-in-out',
                      fontWeight: 'bold',
                      position: 'relative',
                      top: 4,
                      left: 2,
                    }}
                  />
                </button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container onClick={() => history.push('/login')}>
              {/* TODO: Extract these CSS attributes for background images */}
              {/* But note that some, such as height, width, and padding, won't stay the same */}
              <Grid
                item
                style={{
                  backgroundImage: `url(${LoginSplat})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '50px',
                  width: '50px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  padding: 8,
                  marginTop: 4,
                  marginRight: 10,
                }}
              >
                <img src={Users} alt="Users" style={{ width: '80%', height: 'auto' }} />
              </Grid>
              <Grid item>
                <p style={styles.navMenuText}>Log In</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </AppBar> */}
    </Grid>
  );
};

export default RedesignTopNavigation;
