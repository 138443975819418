import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InternalUserForm from '../components/InternalUserForm';
import { InternalUsersContext } from '../contexts/InternalUsersContext';
import BackButton from '../../navigation/components/BackButton';

const NewInternalUser: React.FunctionComponent = () => {
  const { internalUserId } = useParams<Record<string, string>>();
  const [internalUserFetched, setInternalUserFetched] = React.useState<boolean>(false);
  const { fetchFormOptions } = React.useContext(InternalUsersContext);

  React.useEffect(() => {
    if (!internalUserFetched) {
      // fetchFormOptions();
      setInternalUserFetched(true);
    }
  }, [internalUserFetched, internalUserId, fetchFormOptions]);

  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="New Internal User">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InternalUserForm internalUser={{}} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default NewInternalUser;
