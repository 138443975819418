import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import OpportunitiesListHero from './sections/OpportunitiesListHero';
import OpportunitiesFilterBar from './sections/OpportunitiesFilterBar';
import OpportunitiesGallery from './sections/OpportunitiesGallery';
import RedesignFooter from '../common/RedesignFooter';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 30,
  },
});

const sections = [OpportunitiesListHero, OpportunitiesFilterBar, RedesignFooter];

const OpportunitiesListPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesListHero />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesFilterBar fetchOpportunities={() => null} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunitiesGallery redesignOpportunities={[{}, {}, {}, {}, {}, {}, {}, {}]} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default OpportunitiesListPage;
