import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Card, Layout, Loading } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import OpportunityForm from '../components/OpportunityForm';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const EditOpportunity: React.FunctionComponent = () => {
  const { opportunityId } = useParams<Record<string, string>>();
  const [opportunityFetched, setOpportunityFetched] = React.useState<boolean>(false);
  const { opportunity, fetchOpportunity, opportunityLoading, fetchFormOptions } =
    React.useContext(OpportunitiesContext);

  React.useEffect(() => {
    if (!opportunityFetched) {
      fetchFormOptions();
      fetchOpportunity(opportunityId);
      setOpportunityFetched(true);
    }
  }, [opportunityFetched, fetchOpportunity, opportunityId, fetchFormOptions]);

  const loadingMarkup = opportunityLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page
        fullWidth
        title={opportunity.name}
        titleMetadata={
          opportunity?.status === 'active' ? (
            <Badge status="success">Active</Badge>
          ) : (
            <Badge status="critical">Inactive</Badge>
          )
        }
        additionalMetadata={`Created on ${opportunity?.created_at}`}
      >
        <Layout>
          <Layout.Section>
            <Card sectioned>{opportunity.id && <OpportunityForm opportunity={opportunity} />}</Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = opportunityLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditOpportunity;
