import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { CalendlyEvent, CalendlyEventFormValues } from '../model';

interface CalendlyEventContextInterface {
  fetchCalendlyEvents?: (params?: string) => void;
  fetchCalendlyEvent?: (calendly_event_id: string) => void;
  calendlyEventsLoading?: boolean;
  calendlyEvent?: CalendlyEvent;
  calendlyEvents?: CalendlyEvent[];

  updateCalendlyEvent?: (calendly_event_id: string, formValues: CalendlyEventFormValues) => void;
  calendlyEventSubmitting?: boolean;
  formError?: string;
}

const CalendlyEventContext = React.createContext<CalendlyEventContextInterface>({});

const CalendlyEventContextConsumer = CalendlyEventContext.Consumer;
const CalendlyEventContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [calendlyEvents, setCalendlyEvents] = React.useState<CalendlyEvent[]>([]);
  const [calendlyEvent, setCalendlyEvent] = React.useState<CalendlyEvent>({});
  const [calendlyEventsLoading, setCalendlyEventsLoading] = React.useState<boolean>(false);
  const [calendlyEventSubmitting, setCalendlyEventSubmitting] = React.useState(false);

  const fetchCalendlyEvents = async (params = '') => {
    setCalendlyEventsLoading(true);

    const response = await axios.get<string, any>(`calendly_events?${params}`);

    setCalendlyEventsLoading(false);
    setCalendlyEvents(response?.data?.result);
  };

  const fetchCalendlyEvent = async (calendly_event_id: string) => {
    setCalendlyEventsLoading(true);

    const response = await axios.get<string, any>(`calendly_events/${calendly_event_id}`);

    setCalendlyEventsLoading(false);
    setCalendlyEvent(response?.data?.result);
  };

  const updateCalendlyEvent = async (calendly_event_id: string, formValues: CalendlyEventFormValues) => {
    setCalendlyEventSubmitting(true);

    await axios.put<string, any>(`calendly_events/${calendly_event_id}`, {
      calendly_event: { ...formValues },
    });

    setCalendlyEventSubmitting(false);
    history.push('/admin/calendly-events');
  };

  return (
    <CalendlyEventContext.Provider
      value={{
        fetchCalendlyEvents,
        fetchCalendlyEvent,
        calendlyEventsLoading,
        calendlyEvent,
        calendlyEvents,

        updateCalendlyEvent,
        calendlyEventSubmitting,
      }}
    >
      {children}
    </CalendlyEventContext.Provider>
  );
};

export { CalendlyEventContextProvider, CalendlyEventContextConsumer, CalendlyEventContext };
