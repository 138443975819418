import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Typography, Card, Button } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import InformationField from '../../form/components/InformationField';
import TestimonialViewer from '../../redesignedFrontend/homePage/sections/TestimonialViewer';
import siteWideStyles from '../../styles/siteWideStyles';
import DeleteTestimonialConfirmModal from '../components/DeleteTestimonialConfirmModal';

const TestimonialsDetailPage: React.FC = () => {
  const { testimonialId } = useParams<{ testimonialId: string }>();
  const history = useHistory();

  const [testimonial, setTestimonial] = useState<any>({});
  const [deleteTestimonialConfirmModalOpen, setDeleteTestimonialConfirmModalOpen] = useState<boolean>(false);

  const fetchTestimonial = async () => {
    try {
      const response = await axios.get(`testimonials/${testimonialId}`);
      setTestimonial(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTestimonial();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ padding: 10 }} spacing={3}>
      <DeleteTestimonialConfirmModal
        testimonialToDelete={testimonialId}
        deleteTestimonialConfirmModalOpen={deleteTestimonialConfirmModalOpen}
        setDeleteTestimonialConfirmModalOpen={setDeleteTestimonialConfirmModalOpen}
      />
      <Grid item xs={3} style={{ marginLeft: '30px', marginTop: '15px' }}>
        <Button style={siteWideStyles.secondaryButton} onClick={() => history.push(`/admin/testimonials`)}>
          All Testimonials
        </Button>
      </Grid>
      <br />
      <Grid item xs={10}>
        <Typography variant="h1">{testimonial?.name}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          style={siteWideStyles.primaryButton}
          onClick={() => history.push(`/admin/testimonials/${testimonialId}/edit`)}
        >
          Edit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: 20 }}>
          <Grid container>
            <Grid item xs={3}>
              <InformationField label="Job Title" value={testimonial?.job_title} />
            </Grid>
            <Grid item xs={3}>
              <InformationField label="Status" value={testimonial?.status} />
            </Grid>
            <Grid item xs={6}>
              <InformationField label="Quote" value={testimonial?.quote} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 30 }}>
        <Typography variant="h2">Preview</Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: 20, marginTop: -80, marginBottom: -80 }}>
        <TestimonialViewer testimonials={[testimonial]} />
      </Grid>
      <Grid item xs={12} style={{ padding: 20 }}>
        <Button style={siteWideStyles.destructiveButton} onClick={() => setDeleteTestimonialConfirmModalOpen(true)}>
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default TestimonialsDetailPage;
