import React, { useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Badge, Button, Card, DropZone, Layout, Loading, Modal, Page, Toast, TextContainer } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';
import { UserContext } from '../../auth/contexts/UserContext';
import SocialShareCard from '../components/SocialShareCard';
import OpportunityCard from '../components/OpportunityCard';

const OpportunityDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const { opportunityId } = useParams<Record<string, string>>();

  const { opportunity, fetchOpportunity, updateOpportunity, archiveOpportunity, opportunitySubmitting } =
    React.useContext(OpportunitiesContext);
  const { currentUserHasRole } = React.useContext(UserContext);

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [opportunityFetched, setOpportunityFetched] = React.useState<boolean>(false);
  const [toastActive, setToastActive] = useState(false);

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;
  const loadingMarkup = !opportunityFetched ? <Loading /> : null;

  React.useEffect(() => {
    if (!opportunityFetched) {
      fetchOpportunity(opportunityId);
      setOpportunityFetched(true);
    }
  }, [opportunityFetched, fetchOpportunity, opportunityId]);

  const handleUpload = (files: File[]) => {
    const data = new FormData();
    const attachedFile = files[0];

    data.append(`file`, attachedFile, attachedFile.name);

    axios.post(`/opportunity_images?id=${opportunity.id}`, data).then(() => {
      history.push(`/admin/opportunities/${opportunityId}`);
    });
  };

  const statusBadge = () => {
    if (['active', 'prescreen'].includes(opportunity?.status)) {
      return (
        <Badge status="success">{opportunity?.status?.charAt(0)?.toUpperCase() + opportunity?.status?.slice(1)}</Badge>
      );
    }
    if (opportunity?.status === 'archived') {
      return <Badge status="critical">Archived</Badge>;
    }
    return (
      <Badge status="critical">{opportunity?.status?.charAt(0)?.toUpperCase() + opportunity?.status?.slice(1)}</Badge>
    );
  };

  const ProjectButton = () => {
    if (opportunity?.project_id) {
      return (
        <Grid style={{ marginLeft: '30px', marginTop: '15px' }}>
          <Button url={`/admin/projects/${opportunity?.project_id}`}>Project {opportunity?.project_number}</Button>
        </Grid>
      );
    }
    return null;
  };

  // ---- Page markup ----
  const pageMarkup = (
    <>
      <ProjectButton />
      <Page
        fullWidth
        title={opportunity.name}
        titleMetadata={statusBadge()}
        primaryAction={{
          content: 'Edit Opportunity',
          url: `/admin/opportunities/${opportunity.id}/edit`,
        }}
        additionalMetadata={`Created on ${opportunity?.created_at}`}
      >
        <Layout>
          <Layout.Section>
            <Card sectioned title="Opportunity Details">
              <Grid container>
                <Grid item xs={4}>
                  <Card.Section title="Name">
                    <p>{opportunity.name}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Audience">
                    <p>{opportunity.audience}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Project Name">
                    <p>{opportunity.project_name}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Phase Name">
                    <p>{opportunity.phase_name}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Category">
                    <p>{opportunity.category}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Conditions">
                    <p>{opportunity.diagnosis}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Roles">
                    <p>{opportunity.insighter_roles}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Specialties">
                    <p>{opportunity.specialties}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Start Date">
                    <p>{opportunity.start_date}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="End Date">
                    <p>{opportunity.end_date}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Status">
                    <p
                      style={{
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        fontSize: 16,
                      }}
                    >
                      {opportunity.status}
                    </p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Invitation Amount">
                    <p>${opportunity.invitation_amount}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={4}>
                  <Card.Section title="Automatically Update Status">
                    <p>{opportunity?.update_status ? 'Yes' : 'No'}</p>
                  </Card.Section>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Card.Section title="Quick Description">
                    <p style={{ whiteSpace: 'break-spaces' }}>{opportunity.description}</p>
                  </Card.Section>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Card.Section title="Learn More">
                    <p style={{ whiteSpace: 'break-spaces' }}>{opportunity.learn_more}</p>
                  </Card.Section>
                </Grid>
              </Grid>
            </Card>
            <Card sectioned title="Screening and Follow-up Information">
              <Grid container>
                <Grid item xs={12}>
                  <Card.Section title="Pre-Screener URL">
                    <p>{opportunity.screener_url}</p>
                  </Card.Section>
                </Grid>
                {opportunity.follow_up_failure_message && (
                  <Grid item xs={12}>
                    <Card.Section title="Screening Failure Message">
                      <p>{opportunity.follow_up_failure_message}</p>
                    </Card.Section>
                  </Grid>
                )}
                {opportunity.follow_up_success_message && (
                  <Grid item xs={12}>
                    <Card.Section title="Screening Success Message">
                      <p>{opportunity.follow_up_success_message}</p>
                    </Card.Section>
                  </Grid>
                )}
                {opportunity.follow_up_category && (
                  <Grid item xs={4}>
                    <Card.Section title="Follow-up Category">
                      <p>{opportunity.follow_up_category}</p>
                    </Card.Section>
                  </Grid>
                )}
                {opportunity.follow_up_category && (
                  <Grid item xs={8}>
                    <Card.Section title={`${opportunity.follow_up_category} Link`}>
                      <p>{opportunity.follow_up_link}</p>
                    </Card.Section>
                  </Grid>
                )}
              </Grid>
            </Card>
            <Card title="Opportunity Image" sectioned>
              <DropZone type="image" label="" onDrop={handleUpload} allowMultiple={false}>
                <DropZone.FileUpload />
              </DropZone>
            </Card>
            {opportunity.status !== 'redesign' && (
              <Card title="Preview" sectioned>
                <OpportunityCard opportunity={opportunity} />
              </Card>
            )}
            <br />
            {currentUserHasRole('superadmin') && (
              <>
                {opportunity?.status === 'archived' ? (
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        loading={opportunitySubmitting}
                        onClick={() => {
                          updateOpportunity(opportunity?.id, { status: 'inactive' });
                        }}
                      >
                        Unarchive Opportunity
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Modal
                      instant
                      open={showArchiveModal}
                      onClose={() => setShowArchiveModal(false)}
                      title="Archive Confirmation"
                      primaryAction={{
                        content: 'Archive Opportunity',
                        destructive: true,
                        onAction: () => archiveOpportunity(opportunity?.id),
                      }}
                      secondaryActions={[
                        {
                          content: 'Cancel',
                          onAction: () => setShowArchiveModal(false),
                        },
                      ]}
                    >
                      <Modal.Section>
                        <TextContainer>
                          <p>
                            Are you sure you want to archive this opportunity? This will hide the opportunity by
                            default.
                          </p>
                        </TextContainer>
                      </Modal.Section>
                    </Modal>
                    <Grid item xs={6}>
                      <Button
                        destructive
                        loading={opportunitySubmitting}
                        onClick={() => {
                          setShowArchiveModal(true);
                        }}
                      >
                        Archive Opportunity
                      </Button>
                      {opportunity.status === 'redesign' && (
                        <Button
                          onClick={() => {
                            window.location.href = `/redesign/opportunities/${opportunity.id}`;
                          }}
                        >
                          Preview Redesigned Opportunity
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Layout.Section>
          {opportunity.status !== 'redesign' && (
            <Layout.Section secondary>
              <SocialShareCard opportunity={opportunity} />
            </Layout.Section>
          )}
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  // const loadingPageMarkup = <DetailPageSkeleton />;
  // const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default OpportunityDetail;
