import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import HomeLandingSection from './sections/HomeLandingSection';
import ExplorePaidOpportunitiesSection from './sections/ExplorePaidOpportunitiesSection';
import DrivingMarketResearchSection from './sections/DrivingMarketResearchSection';
import QuotesSection from './sections/QuotesSection';
import HowItWorksSection from './sections/HowItWorksSection';
import TechnologyPartnersSection from './sections/TechnologyPartnersSection';
import Footer from '../common/RedesignFooter';
import MapSection from './sections/MapSection';
import EventSection from './sections/EventsSection';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 30,
  },
});

const sections = [
  HomeLandingSection,
  ExplorePaidOpportunitiesSection,
  DrivingMarketResearchSection,
  QuotesSection,
  HowItWorksSection,
  MapSection,
  TechnologyPartnersSection,
  EventSection,
  Footer,
];

const RedesignedHomepage: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      {sections.map((Section, index) => (
        <Grid item xs={12} className={classes.sectionGrid} key={index}>
          <Section />
        </Grid>
      ))}
    </Grid>
  );
};

export default RedesignedHomepage;
