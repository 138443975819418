import React from 'react';
import { ActionList, TopBar } from '@shopify/polaris';
import UserMenu from './UserMenu';
import Logo from '../../../assets/logo.png';

interface TopNavigationProps {
  mobileNavigationActive: boolean;
  setMobileNavigationActive: any;
}

const searchValues = [
  { content: '2020-1073 (Nasal Polyps Consumer Message Testing)', url: '/admin/projects/2' },
  { content: '2020-1062 (Oncology PPI)', url: '/admin/projects/1' },
  { content: 'TI2021-018 (Pediatric Covid-19 Vaccine Survey)', url: '/admin/projects/16' },
  { content: 'TI2021-017 (Sweater Weather Health Tips Poll)', url: '/admin/projects/17' },
  { content: 'TI2021-016 (Where Do You Want To See The Insighters in 2022)', url: '/admin/projects/15' },
  { content: '2021-2026 (Narcolepsy Patient Exploratory)', url: '/admin/projects/3' },
  { content: '2021-2022 (RMMH Fertility Journey Gap Research)', url: '/admin/projects/4' },
  { content: '2021-2033 (Botox Spasticity (Message and Concept))', url: '/admin/projects/6' },
  { content: '2021-2037 (Atogepant Consumer Concept)', url: '/admin/projects/7' },
  {
    content: '2021-2051 (Parkinson’s Disease (PD) and Traumatic Brain Injury (TBI) Patient Exploratory Research)',
    url: '/admin/projects/8',
  },
  { content: 'TI2021-03 (Heart Month Survey)', url: '/admin/projects/31' },
  { content: 'TI2021-02 (What do you want to see from us?)', url: '/admin/projects/32' },
  { content: 'TI2021-01 (COVID-19 Vaccine Survey)', url: '/admin/projects/33' },
  { content: 'TI2021-015 (FIBI - Birth Control)', url: '/admin/projects/19' },
  { content: '2021-2028b (FLUency "Ways In" Positioning)', url: '/admin/projects/18' },
  { content: '2021-2092 (Multaq Consumer Persistency)', url: '/admin/projects/14' },
  { content: '2022-1006 (RMMH Patient Market Research)', url: '/admin/projects/38' },
  { content: '2021-2074 (Nexplanon HCC and HCP Journey Qual and Quant)', url: '/admin/projects/34' },
  { content: '2021-2097 (Saphnelo SLE Consumer Concepts)', url: '/admin/projects/36' },
  { content: '2021-2042 (Cystinosis Patient \u0026 Caregiver Messaging)', url: '/admin/projects/35' },
  { content: 'TI2021-014 (Hispanic Heritage Month Trivia (Spanish))', url: '/admin/projects/20' },
  { content: 'TI2021-013 (Hispanic Heritage Month Trivia (English))', url: '/admin/projects/21' },
  { content: 'TI2021-012 (Summer Heat Safety Survey)', url: '/admin/projects/22' },
  { content: 'TI2021-011 (The Insighter Newsletter Survey)', url: '/admin/projects/23' },
  { content: 'TI2021-010 (Mid-Year Check In)', url: '/admin/projects/24' },
  { content: 'TI2021-09 (Pediatric COVID-19 Vaccine Survey (12-17))', url: '/admin/projects/25' },
  { content: 'TI2021-08 (Pride Month Trivia)', url: '/admin/projects/26' },
  { content: 'TI2021-07 (Pride Month Logo Poll)', url: '/admin/projects/27' },
  { content: 'TI2021-06 (Multilingual Healthcare Experiences)', url: '/admin/projects/28' },
  { content: 'TI2021-05 (Patient Safety Survey)', url: '/admin/projects/29' },
  { content: 'TI2021-04 (Black History Month Trivia)', url: '/admin/projects/30' },
  { content: '2021-2064 (Organon Nexplanon Consumer Messaging)', url: '/admin/projects/12' },
  { content: 'TI2022-02 (Referral Program Research)', url: '/admin/projects/41' },
  { content: 'TI2022-03 (Women \u0026 IBD Survey)', url: '/admin/projects/40' },
  { content: '2021-FTBOU (Race, Culture, \u0026 Breast Cancer Clinical Trial Perceptions)', url: '/admin/projects/10' },
  { content: '0000 ((old) ftbou)', url: '/admin/projects/9' },
  { content: 'TI2022-01 (New Year Check In)', url: '/admin/projects/39' },
  { content: '0000 ((old) ftbou)', url: '/admin/projects/11' },
  { content: '2021-2067 (AZ Fasenra Consumer TV Concepts)', url: '/admin/projects/37' },
  { content: '2021-2095 (Toujeo Max Creative Concept Testing)', url: '/admin/projects/13' },
  { content: 'Phase — Pre-Recruit: quant for 2021-2064', url: '/admin/phases/9' },
  { content: 'Phase — Pre-Recruit for 2022-1006', url: '/admin/phases/10' },
  { content: 'Phase — Phase II: Quant Survey for 2021-2064', url: '/admin/phases/7' },
  { content: 'Phase — Multaq Consumer Persistency for 2021-2092', url: '/admin/phases/3' },
  { content: 'Phase — Pre-Recruit Asthma for 2021-2067', url: '/admin/phases/15' },
  { content: 'Phase — Type 2 Diabetes Interviews (Virtual) for 2021-2095', url: '/admin/phases/4' },
  { content: 'Phase — Referral Program Interviews for TI2022-02', url: '/admin/phases/11' },
  { content: 'Phase — Phase 2 Consumer (Virtual) for 2021-2074', url: '/admin/phases/1' },
  { content: 'Phase — New Year Check In (In-House) for TI2022-01', url: '/admin/phases/12' },
  { content: 'Phase — Phase I: Qual for 2021-2064', url: '/admin/phases/8' },
  { content: 'Phase — FTBOU Quant Survey (Phase 2) for 2021-FTBOU', url: '/admin/phases/6' },
  { content: 'Phase — Lupus (SLE) Virtual Interviews for 2021-2097', url: '/admin/phases/5' },
  { content: 'Phase — FTBOU Qual Interviews (Phase 1) for 2021-FTBOU', url: '/admin/phases/13' },
  { content: 'Phase — Phase I: Qual for 2021-2074', url: '/admin/phases/2' },
  { content: 'Phase — Asthma Virtual Interviews for 2021-2067', url: '/admin/phases/14' },
];

const TopNavigation: React.FC<TopNavigationProps> = (props) => {
  const [searchActive, setSearchActive] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState('');
  const { mobileNavigationActive, setMobileNavigationActive } = props;

  const toggleMobileNavigationActive = () => {
    setMobileNavigationActive(!mobileNavigationActive);
  };

  const handleSearchResultsDismiss = React.useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchFieldChange = React.useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const searchResultsMarkup = (
    <ActionList items={searchValues.filter((i) => i.content.toLowerCase().includes(searchValue.toLowerCase()))} />
  );

  const contextControlMarkup = (
    <div>
      <img alt="logo" style={{ height: 40, margin: '10px 20px' }} src={Logo} />
    </div>
  );

  const searchFieldMarkup = (
    <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search" />
  );

  return (
    <TopBar
      showNavigationToggle
      userMenu={<UserMenu />}
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
      contextControl={contextControlMarkup}
      searchResultsOverlayVisible
    />
  );
};

export default TopNavigation;
