import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AboutLandingSection from './sections/AboutLandingSection';
import LightbulbContentSection from './sections/LightbulbContentSection';
import InsightersTeamBios from './sections/InsightersTeamBios';
import RedesignFooter from '../common/RedesignFooter';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 30,
  },
});

const sections = [AboutLandingSection, LightbulbContentSection, InsightersTeamBios, RedesignFooter];

const RedesignedHomepage: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      {sections.map((Section, index) => (
        <Grid item xs={12} className={classes.sectionGrid} key={index}>
          <Section />
        </Grid>
      ))}
    </Grid>
  );
};

export default RedesignedHomepage;
