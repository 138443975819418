import React from 'react';
import Grid from '@material-ui/core/Grid';
import TitleSubBackgroundImage from '../assets/TitleSubBackground.png';
import useStyles from '../../constants/redesignStyles';

const ResearchCompany: React.FC = () => {
  const classes = useStyles();
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={TitleSubBackgroundImage}
        style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, zIndex: 0, objectFit: 'cover' }}
        alt="title background"
      />
      <h1 className={classes.h1} style={{ position: 'relative' }}>
        <span
          style={{
            background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Research Company
        </span>
      </h1>
    </div>
  );
};

export default ResearchCompany;
