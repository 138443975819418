import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import useStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';
import MediumOrnament from '../../common/MediumOrnament';
import LayeredRectangle from '../../../../assets/redesignAssets/staticImages/LayeredRectangle.png';
import { Testimonial } from '../../../testimonials/model';

// TODO: This would be better with a transition.  The sudden change is jarring.

interface TestimonialsViewerPageProps {
  testimonials: Testimonial[];
}

const styles = {
  whatInsightersSay: {
    color: 'var(--3, #FFF)',
    fontFamily: 'Crimson Text',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '100%' /* 52px */,
    letterSpacing: -2,
  },
  actualQuoteText: {
    color: 'var(--3, #FFF)',
    fontFamily: 'Crimson Text',
    fontSize: 36,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '100%' /* 70px */,
    letterSpacing: -2,
  },
  singleHeroOverlay: {
    position: 'absolute',
    top: '15%',
    left: '10px',
    display: 'flex',
    gap: '5px',
  },
  textOverlay: {
    position: 'absolute',
    top: '15%',
    right: '10px',
    display: 'flex',
    gap: '5px',
  },
  nameText: {
    color: 'var(--3, #FFF)',
    fontFamily: 'Crimson Text',
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: -1,
  },
  jobTitleText: {
    color: '#BEC3D4',
    fontFamily: 'Rethink Sans, sans-serif',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: -1,
  },
};

const TestimonialViewer: React.FC<TestimonialsViewerPageProps> = (props: TestimonialsViewerPageProps) => {
  const classes = useStyles();

  const { testimonials } = props;

  const [imageIndex, setImageIndex] = useState(0);

  const handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Grid
      container
      style={{
        backgroundImage: `url(${LayeredRectangle})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '600px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={testimonials[imageIndex]?.s3_image_url} alt="Hero" style={{ width: '50%', height: 'auto' }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <MediumOrnament text="Real Stories, Real Impact" />
        </Grid>
        <Grid item xs={12}>
          <Typography style={styles.whatInsightersSay}>What Insighters Say</Typography>
        </Grid>
        <Grid item xs={12} style={{ height: '40%', width: '80%' }}>
          <Typography style={styles.actualQuoteText}>{testimonials[imageIndex]?.quote}</Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={7}>
              <Typography style={styles.nameText}>{testimonials[imageIndex]?.name}</Typography>
              <hr style={{ margin: 0 }} />
              <Typography style={styles.jobTitleText}>{testimonials[imageIndex]?.job_title}</Typography>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
              <button type="button" onClick={handlePrevImage}>
                <NavigateBefore />
              </button>
              <button type="button" onClick={handleNextImage}>
                <NavigateNext />
              </button>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12}>
          <HoverButton text="Explore Our Stories" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestimonialViewer;
