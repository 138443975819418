import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import HoverButton from '../../common/HoverButton';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import MaryFoxBio from '../assets/MaryFoxBio.png';

const InsightersTeamBios: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <MediumOrnament text="Meet Our Team" centerComponent centerText />
      <Typography className={classes.h2}>The Real People Behind The Insighters</Typography>
      <br />
      <Grid container>
        <Grid item md={6} xs={12} style={{ maxHeight: 300 }}>
          <img alt="employee-headshot" src={MaryFoxBio} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        </Grid>
        <Grid item md={6} xs={12} style={{ maxHeight: 300 }}>
          <img alt="employee-headshot" src={MaryFoxBio} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        </Grid>
        <Grid item md={6} xs={12} style={{ maxHeight: 300 }}>
          <img alt="employee-headshot" src={MaryFoxBio} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        </Grid>
        <Grid item md={6} xs={12} style={{ maxHeight: 300 }}>
          <img alt="employee-headshot" src={MaryFoxBio} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        </Grid>
        <Grid item md={3} />
        <Grid item md={6} xs={12} style={{ maxHeight: 300 }}>
          <img alt="employee-headshot" src={MaryFoxBio} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InsightersTeamBios;
