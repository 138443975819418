import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import SectionOrnament from '../../common/SectionOrnament';
import redesignStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';
import EventViewerRow from '../../../eventResources/components/EventViewerRow';
import axios from '../../../utils/axios.utils';

const EventsSection: React.FC = () => {
  const classes = redesignStyles();

  const [eventResources, setEventResources] = useState<any>([]);

  useEffect(() => {
    axios.get('event_resources', { params: { status: 'show' } }).then((response) => {
      let allEventResources = response?.data?.result;
      setEventResources(allEventResources.filter((eventResource: any) => eventResource.show_on_homepage === 'show'));
    });
  }, []);

  return (
    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-around', padding: '10%' }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <SectionOrnament text="Insightful Conversations" />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography className={classes.h1}>Explore our events</Typography>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography className={classes.h3}>Join us and be a catalyst for change in the healthcare industry</Typography>
      </Grid>
      <Grid item xs={12}>
        <EventViewerRow eventResources={eventResources} />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
        <HoverButton text="Check Our Schedule" />
      </Grid>
    </Grid>
  );
};

export default EventsSection;
