import React from 'react';
import { Card, Loading, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import ProjectPhaseForm from '../components/ProjectPhaseForm';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const NewPhase: React.FunctionComponent = () => {
  const [phaseOptionsFetched, setPhaseOptionsFetched] = React.useState<boolean>(false);
  const { phase, fetchPhase, phaseLoading, fetchFormOptions } = React.useContext(ProjectPhasesContext);

  React.useEffect(() => {
    if (!phaseOptionsFetched) {
      fetchFormOptions();
      setPhaseOptionsFetched(true);
    }
  }, [phaseOptionsFetched, fetchPhase, fetchFormOptions]);

  return phaseLoading ? (
    <>
      <Loading />
      <FormSkeleton />
    </>
  ) : (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Phase">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <ProjectPhaseForm phase={phase} />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default NewPhase;
