import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InternalUserForm from '../components/InternalUserForm';
import { InternalUsersContext } from '../contexts/InternalUsersContext';
import BackButton from '../../navigation/components/BackButton';

const EditInternalUser: React.FunctionComponent = () => {
  const { userId } = useParams<Record<string, string>>();
  const [internalUserFetched, setInternalUserFetched] = React.useState<boolean>(false);
  const { internalUser, fetchInternalUser, fetchFormOptions } = React.useContext(InternalUsersContext);

  React.useEffect(() => {
    if (!internalUserFetched) {
      // fetchFormOptions();
      fetchInternalUser(userId);
      setInternalUserFetched(true);
    }
  }, [internalUserFetched, fetchInternalUser, userId, fetchFormOptions]);

  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title={`Update Account Details ${internalUser?.first_name} ${internalUser?.last_name}`}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InternalUserForm internalUser={internalUser} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default EditInternalUser;
