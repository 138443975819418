import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';
import ApprovePaymentForm from '../components/ApprovePaymentForm';
import { PaymentsContext } from '../contexts/PaymentsContext';
import BackButton from '../../navigation/components/BackButton';
import InformationField from '../../form/components/InformationField';

const ApprovePayment: React.FunctionComponent = () => {
  const { paymentId } = useParams<Record<string, string>>();
  const [paymentFetched, setPaymentFetched] = React.useState<boolean>(false);
  const { payment, fetchPayment, paymentsLoading } = React.useContext(PaymentsContext);

  React.useEffect(() => {
    if (!paymentFetched) {
      fetchPayment(paymentId);
      setPaymentFetched(true);
    }
  }, [paymentFetched, fetchPayment, paymentId]);

  return (
    !paymentsLoading && (
      <>
        <BackButton pageAction={false} />
        <Page
          fullWidth
          title={`${payment.description}`}
          additionalMetadata={
            <Grid container spacing={4}>
              <Grid item>
                <InformationField label="Created On" value={payment.created_at} />
              </Grid>
              <Grid item>
                <InformationField label="Project Number" value={payment.project_number} />
              </Grid>
              <Grid item>
                <InformationField label="Project Name" value={payment.project_name} />
              </Grid>
            </Grid>
          }
        >
          <Layout>
            <Layout.Section>
              <Card sectioned>{payment.id ? <ApprovePaymentForm payment={payment} /> : <Loading />}</Card>
            </Layout.Section>
            <Layout.Section secondary />
          </Layout>
        </Page>
      </>
    )
  );
};

export default ApprovePayment;
