import React from 'react';
import { UserContextProvider } from './modules/auth/contexts/UserContext';
import { ProjectsContextProvider } from './modules/projects/contexts/ProjectsContext';
import { SpecialtiesContextProvider } from './modules/specialties/contexts/SpecialtiesContext';
import { InsighterRolesContextProvider } from './modules/insighterRoles/contexts/InsighterRolesContext';
import { ProjectPhasesContextProvider } from './modules/projectPhases/contexts/ProjectPhasesContext';
import { PhaseRespondentsContextProvider } from './modules/projectPhases/contexts/PhaseRespondentsContext';
import { LeadsContextProvider } from './modules/leadGroups/contexts/LeadsContext';
import { ConditionsContextProvider } from './modules/conditions/contexts/ConditionsContext';
import { InsightersContextProvider } from './modules/insighters/contexts/InsightersContext';
import { LeadGroupsContextProvider } from './modules/leadGroups/contexts/LeadGroupsContext';
import { InsighterPaymentsContextProvider } from './modules/insighterPayments/contexts/InsighterPaymentsContext';
import { InsighterNotesContextProvider } from './modules/insighters/contexts/InsighterNotesContext';
import { RegistrationsContextProvider } from './modules/registrations/contexts/RegistrationsContext';
import { OpportunitiesContextProvider } from './modules/opportunities/contexts/OpportunitiesContext';
import { BulkRespondentsContextProvider } from './modules/respondents/contexts/BulkRespondentsContext';
import { RespondentsContextProvider } from './modules/respondents/contexts/RespondentsContext';
import { InsighterFinderContextProvider } from './modules/insighterFinder/contexts/InsighterFinderContext';
import { InterviewsContextProvider } from './modules/interviews/contexts/InterviewsContext';
import { InternalUsersContextProvider } from './modules/internalUsers/contexts/InternalUsersContext';
import { PaymentsContextProvider } from './modules/payments/contexts/PaymentsContext';
import { ExpensesContextProvider } from './modules/expenses/contexts/ExpensesContext';
import { SurveyResponsesContextProvider } from './modules/surveyResponses/contexts/SurveyResponsesContext';
import { DashboardsContextProvider } from './modules/dashboards/contexts/DashboardsContext';
import { InsighterDashboardContextProvider } from './modules/insighterDashboard/contexts/InsighterDashboardContext';
import { InsighterOpportunitiesContextProvider } from './modules/opportunities/contexts/InsighterOpportunitiesContext';
import { InvitationOpportunitiesContextProvider } from './modules/invitationOpportunities/contexts/InvitationOpportunitiesContext';
import { InvitationPartnersContextProvider } from './modules/invitationPartners/contexts/InvitationPartnersContext';
import { InvitationsContextProvider } from './modules/invitations/contexts/InvitationsContext';
import { NavigationContextProvider } from './modules/navigation/contexts/NavigationContext';
import { AdminNavigationContextProvider } from './modules/navigation/contexts/AdminNavigationContext';
import { CalendlyEventContextProvider } from './modules/calendlyEvents/contexts/CalendlyEventContext';
import { InsighterTagsContextProvider } from './modules/insighterTags/contexts/InsighterTagsContext';
import { RecruitmentPartnersContextProvider } from './modules/recruitmentPartners/contexts/RecruitmentPartnersContext';
import { SurveyMappingContextProvider } from './modules/surveyMappings/contexts/SurveyMappingContext';
import { NewslettersContextProvider } from './modules/newsletters/contexts/NewslettersContext';
import { ConditionCategoriesContextProvider } from './modules/conditions/contexts/ConditionCategoriesContext';
import { EthnicitiesContextProvider } from './modules/ethnicities/contexts/EthnicitiesContext';
import { SavedSearchesContextProvider } from './modules/insighterFinder/contexts/SavedSearchesContext';

const ContextsProvider: React.FC = (props) => {
  const { children } = props;

  return (
    <UserContextProvider>
      <NavigationContextProvider>
        <AdminNavigationContextProvider>
          <RegistrationsContextProvider>
            <ProjectsContextProvider>
              <SpecialtiesContextProvider>
                <InsighterRolesContextProvider>
                  <ProjectPhasesContextProvider>
                    <CalendlyEventContextProvider>
                      <PhaseRespondentsContextProvider>
                        <OpportunitiesContextProvider>
                          <InsighterOpportunitiesContextProvider>
                            <DashboardsContextProvider>
                              <InsighterDashboardContextProvider>
                                <BulkRespondentsContextProvider>
                                  <RespondentsContextProvider>
                                    <InsighterFinderContextProvider>
                                      <ConditionsContextProvider>
                                        <InterviewsContextProvider>
                                          <PaymentsContextProvider>
                                            <ExpensesContextProvider>
                                              <SurveyResponsesContextProvider>
                                                <InsighterPaymentsContextProvider>
                                                  <InsighterNotesContextProvider>
                                                    <InternalUsersContextProvider>
                                                      <InsightersContextProvider>
                                                        <InvitationOpportunitiesContextProvider>
                                                          <InvitationPartnersContextProvider>
                                                            <InvitationsContextProvider>
                                                              <InsighterTagsContextProvider>
                                                                <RecruitmentPartnersContextProvider>
                                                                  <SurveyMappingContextProvider>
                                                                    <NewslettersContextProvider>
                                                                      <LeadsContextProvider>
                                                                        <ConditionCategoriesContextProvider>
                                                                          <LeadGroupsContextProvider>
                                                                            <EthnicitiesContextProvider>
                                                                              <SavedSearchesContextProvider>
                                                                                {children}
                                                                              </SavedSearchesContextProvider>
                                                                            </EthnicitiesContextProvider>
                                                                          </LeadGroupsContextProvider>
                                                                        </ConditionCategoriesContextProvider>
                                                                      </LeadsContextProvider>
                                                                    </NewslettersContextProvider>
                                                                  </SurveyMappingContextProvider>
                                                                </RecruitmentPartnersContextProvider>
                                                              </InsighterTagsContextProvider>
                                                            </InvitationsContextProvider>
                                                          </InvitationPartnersContextProvider>
                                                        </InvitationOpportunitiesContextProvider>
                                                      </InsightersContextProvider>
                                                    </InternalUsersContextProvider>
                                                  </InsighterNotesContextProvider>
                                                </InsighterPaymentsContextProvider>
                                              </SurveyResponsesContextProvider>
                                            </ExpensesContextProvider>
                                          </PaymentsContextProvider>
                                        </InterviewsContextProvider>
                                      </ConditionsContextProvider>
                                    </InsighterFinderContextProvider>
                                  </RespondentsContextProvider>
                                </BulkRespondentsContextProvider>
                              </InsighterDashboardContextProvider>
                            </DashboardsContextProvider>
                          </InsighterOpportunitiesContextProvider>
                        </OpportunitiesContextProvider>
                      </PhaseRespondentsContextProvider>
                    </CalendlyEventContextProvider>
                  </ProjectPhasesContextProvider>
                </InsighterRolesContextProvider>
              </SpecialtiesContextProvider>
            </ProjectsContextProvider>
          </RegistrationsContextProvider>
        </AdminNavigationContextProvider>
      </NavigationContextProvider>
    </UserContextProvider>
  );
};

export default ContextsProvider;
