import React from 'react';
import { Card, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InsighterRoleForm from '../components/InsighterRoleForm';
import BackButton from '../../navigation/components/BackButton';

const NewInsighterRole: React.FunctionComponent = () => {
  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Insighter Role">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InsighterRoleForm />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default NewInsighterRole;
