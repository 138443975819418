import React from 'react';
import { Button } from '@shopify/polaris';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import { NewslettersContext } from '../contexts/NewslettersContext';

// This component is not currently in use.  01/23/2023

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 1050,
    },
    modalContent: {
      height: '100%',
      backgroundColor: '#fff',
      border: '1px solid rgba(0,0,0,.2)',
      borderRadius: '0.3rem',
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'auto',
    },
  }),
);

const PastNewslettersList: React.FunctionComponent = () => {
  const { newsletters, fetchNewsletters } = React.useContext(NewslettersContext);
  const [newslettersFetched, setNewslettersFetched] = React.useState(false);
  const [showModal, setShowModal] = React.useState('');
  const classes = useStyles();

  React.useEffect(() => {
    if (!newslettersFetched) {
      fetchNewsletters();
      setNewslettersFetched(true);
    }
  }, [newslettersFetched, fetchNewsletters, setNewslettersFetched]);

  return (
    <>
      <Grid container spacing={3} style={{ padding: '10px' }}>
        {newsletters.map((newsletter, index) => (
          <Grid key={newsletter.url} item flex-direction="column">
            <Grid item>
              <img alt={newsletter.name} src={newsletter.image_url} height="250px" />
            </Grid>
            <Grid item>
              <Button primary onClick={() => setShowModal(newsletter.url)}>
                {`View ${newsletter.name}`}
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {showModal && (
        <div className={classes.modal}>
          <div className="modal-dialog" style={{ maxWidth: '90%', height: '90vh', margin: '1.75rem auto' }}>
            <div className={classes.modalContent}>
              <div style={{ flex: '1 1 auto' }}>
                <iframe
                  title="Newsletter"
                  src={showModal}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ pointerEvents: 'auto' }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', borderTop: '1px solid #dee2e6', padding: '1rem' }}>
                <Button primary onClick={() => setShowModal('')}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PastNewslettersList;
