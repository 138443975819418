import React from 'react';
import Page from '../../shared/components/Page';
import AddNewsletterForm from '../components/AddNewsletterForm';
import PastNewslettersList from '../components/PastNewslettersList';

const NewsLettersDetail: React.FunctionComponent = () => {
  return (
    <Page fullWidth title="Newsletters">
      <div style={{ padding: '10px 10px 20px 10px', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
        <AddNewsletterForm />
      </div>
      <h1 className="Polaris-Heading" style={{ fontSize: 20, margin: '20px 0 20px 0' }}>
        Past Newsletters
      </h1>
      <PastNewslettersList />
    </Page>
  );
};

export default NewsLettersDetail;
