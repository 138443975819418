import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loading, Page, Toast } from '@shopify/polaris';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import OpportunitiesBanner from '../../../assets/opportunities/opportunities-banner.png';
import OpportunityCard from '../components/OpportunityCard';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import { InsighterOpportunitiesContext } from '../contexts/InsighterOpportunitiesContext';
import { UserContext } from '../../auth/contexts/UserContext';
import Footer from '../../navigation/components/Footer';
import BasicFooter from '../../navigation/components/BasicFooter';
import { InsightersContext } from '../../insighters/contexts/InsightersContext';
import HiringCard from '../../static/pages/HiringCard';

const useStyles = makeStyles({
  subheader: {
    fontSize: '20px',
    fontFamily: 'Cooper',
    lineHeight: '28px',
  },
});

interface OpportunitiesListProps {
  isLoading?: boolean;
}

const InsighterOpportunitiesList: React.FunctionComponent<OpportunitiesListProps> = (props) => {
  const { isLoading = false } = props;
  const { impersonatedUserId } = useParams<Record<string, string>>();
  const history = useHistory();
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const { insighterOpportunities, fetchInsighterOpportunities } = React.useContext(InsighterOpportunitiesContext);
  const { insighter, fetchInsighter } = React.useContext(InsightersContext);
  const [insighterOpportunitiesFetched, setInsighterOpportunitiesFetched] = React.useState(false);
  const [insighterFetched, setInsighterFetched] = React.useState(false);
  const [toastActive, setToastActive] = React.useState(false);

  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);

  React.useEffect(() => {
    if (!insighterOpportunitiesFetched) {
      fetchInsighterOpportunities(impersonatedUserId);
      setInsighterOpportunitiesFetched(true);
    }
  }, [insighterOpportunitiesFetched, fetchInsighterOpportunities, impersonatedUserId]);

  React.useEffect(() => {
    if (impersonatedUserId) {
      if (!insighterFetched) {
        fetchInsighter(impersonatedUserId);
        setInsighterFetched(true);
      }
    }
  }, [insighterFetched, fetchInsighter, impersonatedUserId]);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const actualPageMarkup = (
    <div>
      <Page fullWidth>
        <div style={{ minHeight: '70vh' }}>
          <img alt="Upcoming" src={OpportunitiesBanner} width="100%" />
          {!user?.id && (
            <Grid container justifyContent="center">
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <h6 className={classes.subheader}>
                  As an Insighter, you can get paid to share your story, your experience, & your insights.
                </h6>

                <h6 className={classes.subheader}>We believe that healthcare research should include all voices—</h6>

                <h6 className={classes.subheader}>& that yours should be in the room where it happens.</h6>
                <br />

                <h6 className={classes.subheader}>We’re eager to pull up a chair & chat.</h6>

                <h6 className={classes.subheader}>Join us & apply for an open opportunity!</h6>
              </Grid>
            </Grid>
          )}
          {/* <Grid style={{ display: 'flex', justifyContent: 'center' }}>
            <HiringCard />
          </Grid> */}
          <div className="">
            <div className="Polaris-Connected" style={{ margin: '30px auto', width: 800 }}>
              <div className="Polaris-Connected__Item Polaris-Connected__Item--primary" />
            </div>
          </div>
          <Grid container columnSpacing={{ xs: 0, sm: 6 }} rowSpacing={6} justifyContent="center" alignItems="center">
            {insighterOpportunities.map((opportunity, index) => (
              <Grid item lg={6} xs={10} key={index}>
                <OpportunityCard opportunity={opportunity} />
              </Grid>
            ))}
          </Grid>
          <br />
        </div>
      </Page>
      {!user?.id ? <Footer /> : <BasicFooter />}
    </div>
  );

  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
      {impersonatedUserId && (
        <Toast
          onDismiss={() => null}
          content={`Viewing page as ${insighter?.name}`}
          action={{
            content: 'Back',
            onAction: () => history.push(`/admin/insighters/${impersonatedUserId}/view`),
          }}
        />
      )}
    </>
  );
};

export default InsighterOpportunitiesList;
