import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { CalendlyEventContext } from '../contexts/CalendlyEventContext';
import { CalendlyEvent, CalendlyEventFormValues } from '../model';

interface CalendlyEventFormProps {
  calendlyEvent?: CalendlyEvent;
}

const CalendlyEventForm: React.FC<CalendlyEventFormProps> = (props) => {
  const { calendlyEvent } = props;
  const { formError, updateCalendlyEvent, calendlyEventSubmitting } = React.useContext(CalendlyEventContext);

  const [formValues, setFormValues] = React.useState<CalendlyEventFormValues>({});

  React.useEffect(() => {
    setFormValues({
      name: calendlyEvent?.name,
      status: calendlyEvent?.status,
    });
  }, [calendlyEvent]);

  const handleUpdate = () => {
    updateCalendlyEvent(calendlyEvent.id, formValues);
  };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Calendly Event Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Name"
            type="text"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
      </Grid>

      <>
        <Button primary onClick={handleUpdate} loading={calendlyEventSubmitting}>
          Update Calendly Event
        </Button>
        {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
      </>
    </FormLayout>
  );
};

export default CalendlyEventForm;
