import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { HomepageResource } from '../model';
import axios from '../../../utils/axios.utils';
import SectionOrnament from '../../common/SectionOrnament';
import useStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';

interface FeaturedResourcesProps {
  featuredResources: HomepageResource[];
}

const FeaturedResources: React.FC<FeaturedResourcesProps> = (props) => {
  const classes = useStyles();

  let { featuredResources } = props;

  let [currentFeaturedResource, setCurrentFeaturedResource] = useState<HomepageResource | null>(null);
  let [resourceIndex, setResourceIndex] = useState<number>(0);

  useEffect(() => {
    if (featuredResources.length > 0) {
      setCurrentFeaturedResource(featuredResources[resourceIndex]);
    }
  }, [featuredResources, resourceIndex]);

  const handlePrevImage = () => {
    setResourceIndex((prevIndex) => (prevIndex === 0 ? featuredResources.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setResourceIndex((prevIndex) => (prevIndex === featuredResources.length - 1 ? 0 : prevIndex + 1));
  };

  return currentFeaturedResource ? (
    <Grid
      container
      spacing={3}
      style={{ paddingLeft: '15%', paddingRight: '15%', display: 'flex', justifyContent: 'center' }}
    >
      <Grid item xs={12} md={6}>
        <Grid container style={{ position: 'relative', height: '300px', overflow: 'hidden' }}>
          <img
            src={currentFeaturedResource?.s3_image_url}
            alt={currentFeaturedResource?.title}
            style={{ width: '100%', height: '100%', display: 'block', objectFit: 'contain', borderRadius: 15 }}
          />
          <Grid item>
            <Grid className={classes.arrowButtons} style={{ zIndex: 2 }}>
              <button type="button" onClick={handlePrevImage}>
                <NavigateBefore />
              </button>
              <button type="button" onClick={handleNextImage}>
                <NavigateNext />
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid container>
          <Grid item xs={5}>
            <SectionOrnament text="Featured" />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={12} style={{ marginTop: 15 }}>
            <Typography className={classes.h2}>{currentFeaturedResource?.title}</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 15, height: '60px' }}>
            <Typography className={classes.smallText}>{currentFeaturedResource?.thumbnail_description}</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <HoverButton text="Read More" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default FeaturedResources;
