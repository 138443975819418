import React from 'react';
import { Button, Card, ChoiceList, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';

const OpportunityFilterBar: React.FunctionComponent = () => {
  const {
    fetchOpportunities,
    fetchFormOptions,
    categoryFilter,
    setCategoryFilter,
    statusFilter,
    setStatusFilter,
    setFetchParams,
    sortParams,
  } = React.useContext(OpportunitiesContext);

  const [projectOptionsFetched, setProjectOptionsFetched] = React.useState(false);
  const [appliedFiltersDisplay, setAppliedFiltersDisplay] = React.useState<any[]>([]);
  const [queryValue, setQueryValue] = React.useState<string>(sessionStorage.getItem('opportunityFilterQuery') || '');
  const [selectedCategoryFilter, setSelectedCategoryFilter] = React.useState<string[]>(['']);
  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<string[]>(['']);

  React.useEffect(() => {
    if (!projectOptionsFetched) {
      fetchFormOptions();
      setProjectOptionsFetched(true);
    }
  }, [projectOptionsFetched, fetchFormOptions]);

  React.useEffect(() => {
    const appliedFiltersArray = [];

    if (categoryFilter) {
      appliedFiltersArray.push({
        key: 'category',
        label: `Filtered by ${categoryFilter}`,
        onRemove: () => {
          setCategoryFilter('');
          setSelectedCategoryFilter(['']);
        },
      });
    }

    if (statusFilter) {
      appliedFiltersArray.push({
        key: 'project',
        label: `Filtered by ${statusFilter}`,
        onRemove: () => {
          setStatusFilter('');
          setSelectedStatusFilter(['']);
        },
      });
    }

    setAppliedFiltersDisplay([...appliedFiltersArray]);
  }, [categoryFilter, setCategoryFilter, statusFilter, setStatusFilter]);

  const applyFilters = () => {
    const queryFilter = `ransack[query]=${queryValue}`;
    const categoryFilter = `ransack[category_eq]=${selectedCategoryFilter[0]}`;
    const statusFilter = `ransack[status_eq]=${selectedStatusFilter}`;

    sessionStorage.setItem('opportunityFilterQuery', queryValue);
    sessionStorage.setItem('opportunityFilters', `${queryFilter}&${categoryFilter}&${statusFilter}&${sortParams}`);

    setFetchParams(`${queryFilter}&${categoryFilter}&${statusFilter}`);
    fetchOpportunities(`${queryFilter}&${categoryFilter}&${statusFilter}&${sortParams}`);
  };

  const clearFilters = () => {
    setQueryValue('');
    setSelectedCategoryFilter(['']);
    setSelectedStatusFilter(['']);
    setFetchParams('ransack[query]=');
    sessionStorage.removeItem('opportunityFilters');
    sessionStorage.removeItem('opportunityFilterQuery');
    fetchOpportunities();
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={queryValue}
          queryPlaceholder="Filter by opportunity name, project number, project name, or phase name..."
          filters={[
            {
              key: 'category',
              label: 'Category',
              filter: (
                <ChoiceList
                  title="Category"
                  choices={[
                    { label: 'Survey', value: 'Survey' },
                    { label: 'Interview (Virtual)', value: 'Interview (Virtual)' },
                    { label: 'Interview (In-Person)', value: 'Interview (In-Person)' },
                    { label: 'In-House Quiz', value: 'In-House Quiz' },
                    { label: 'In-House Survey', value: 'In-House Survey' },
                    { label: 'In-House Research', value: 'In-House Research' },
                  ]}
                  selected={selectedCategoryFilter}
                  onChange={(value) => setSelectedCategoryFilter(value)}
                />
              ),
              shortcut: true,
            },
            {
              key: 'status',
              label: 'Status',
              filter: (
                <ChoiceList
                  title="Status"
                  titleHidden
                  choices={[
                    { label: 'Active', value: 'active' },
                    { label: 'Inactive', value: 'inactive' },
                    { label: 'Pre-Screen', value: 'prescreen' },
                    { label: 'Fully Booked', value: 'booked' },
                    { label: 'Archived', value: 'archived' },
                  ]}
                  selected={selectedStatusFilter}
                  onChange={(value) => setSelectedStatusFilter(value)}
                />
              ),
              shortcut: true,
            },
          ]}
          appliedFilters={appliedFiltersDisplay}
          onQueryChange={(value) => setQueryValue(value)}
          onQueryClear={() => setQueryValue('')}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filters
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filters</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default OpportunityFilterBar;
