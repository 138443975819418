import React, { useCallback, useState } from 'react';
import { Button, Card, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';

interface OpportunitiesListProps {
  isLoading: boolean;
}

const OpportunitiesList: React.FunctionComponent<OpportunitiesListProps> = (props) => {
  const { isLoading } = props;

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
      grow: 3,
    },
    {
      name: 'Requested By',
      selector: 'requested_by',
      sortable: true,
    },
    {
      name: 'Requested At',
      selector: 'requested_at',
      sortable: true,
    },
    {
      name: 'Actions',
      selector: 'status',
      sortable: true,
      grow: 2,
      cell: () => (
        <>
          <div style={{ marginRight: 10 }}>
            <Button>Reject</Button>
          </div>
          <br />
          <div>
            <Button primary onClick={() => null}>
              Approve
            </Button>
          </div>
        </>
      ),
    },
  ];

  const actualPageMarkup = (
    <Page
      fullWidth
      title="Admin Approvals"
      pagination={{
        hasPrevious: true,
        hasNext: true,
      }}
    >
      <Card>
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/interviews/${row.id}`)}
          columns={columns}
          data={[]}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default OpportunitiesList;
