import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import HeroImageOther from '../assets/AboutLightBulb.png';

const ContentSection: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center', maxWidth: 1200, margin: 'auto' }}>
      <Grid item xs={6}>
        <img src={HeroImageOther} alt="Hero" style={{ width: '100%', height: 'auto' }} />
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <div>
          <div>
            <MediumOrnament text="Unlocking Potential" centerComponent={false} />
            <div>
              <Typography className={classes.h2}>Find Out What Makes Us</Typography>
              <Typography className={classes.h2}>Light Up</Typography>
            </div>
            <div>
              <Typography className={classes.smallText}>
                The Insighters matches you with paid research opportunities. We are part of an established research
                company, Sommer Consulting, that unlocks your insights to make healthcare more accessible for all.
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContentSection;
