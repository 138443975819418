/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Card, TextField, Modal, TextContainer } from '@shopify/polaris';
import { makeStyles, Grid } from '@material-ui/core';
import QRCode from 'qrcode.react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  // TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';
import ButtonWrapper from '../../form/components/ButtonWrapper';
import { InvitationOpportunity } from '../model';
import XIcon from '../../../assets/social/X-icon.png';

const useStyles = makeStyles({
  amountContainer: {
    flexDirection: 'row',
    marginTop: 2,
  },
  container: { marginBottom: '10px' },
  divContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' },
  image: { width: '100%', borderRadius: '6px' },
  imageContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
  shareNetwork: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginRight: 10,
    textAlign: 'center',
  },
  shareButton: {
    cursor: 'pointer',
  },
  inviteeAmount: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 4,
  },
  inviterAmount: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 4,
  },
  invitationTitle: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 600,
    marginBottom: 4,
  },
});

interface SocialShareCardProps {
  invitationOpportunity: InvitationOpportunity;
}

const SocialShareCard: React.FC<SocialShareCardProps> = (props) => {
  const classes = useStyles();

  const { invitationOpportunity } = props;
  const [openLearnMoreModal, setOpenLearnMoreModal] = React.useState(false);
  const [openSampleMessageModal, setOpenSampleMessageModal] = React.useState(false);

  return (
    <div>
      <Modal open={openLearnMoreModal} onClose={() => setOpenLearnMoreModal(false)} title={invitationOpportunity?.name}>
        <Modal.Section>
          <TextContainer>
            <p style={{ whiteSpace: 'break-spaces' }}>{invitationOpportunity.learn_more}</p>
          </TextContainer>
        </Modal.Section>
        <hr style={{ height: 1, backgroundColor: '#ddd', border: 'none' }} />
        <Grid container spacing={1} justify="flex-end" style={{ paddingRight: 15, paddingBottom: 10 }}>
          <Grid item>
            <ButtonWrapper onClick={() => setOpenLearnMoreModal(false)} label="Close" />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openSampleMessageModal}
        onClose={() => setOpenSampleMessageModal(false)}
        title={invitationOpportunity.name}
      >
        <Modal.Section>
          <TextContainer>
            <p style={{ whiteSpace: 'break-spaces' }}>{invitationOpportunity.sample_message}</p>
          </TextContainer>
        </Modal.Section>
        <hr style={{ height: 1, backgroundColor: '#ddd', border: 'none' }} />
        <Grid container spacing={1} justify="flex-end" style={{ paddingRight: 15, paddingBottom: 10 }}>
          <Grid item>
            <Button
              primary
              onClick={() => {
                navigator.clipboard.writeText(invitationOpportunity?.sample_message);
              }}
            >
              Copy Message
            </Button>
          </Grid>
          <Grid item>
            <ButtonWrapper onClick={() => setOpenSampleMessageModal(false)} label="Close" />
          </Grid>
        </Grid>
      </Modal>
      <div className={classes.container}>
        <Card>
          <Grid container>
            <Grid item xs={12} sm={12} md={2}>
              <div className={classes.imageContainer}>
                <img
                  className={classes.image}
                  src={invitationOpportunity?.s3_image_url}
                  alt={`${invitationOpportunity?.name} Graphic`}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card.Section title="Invitation">
                <h1 className={classes.invitationTitle}>{invitationOpportunity?.name}</h1>
                <p>{invitationOpportunity.description}</p>
                <p className={classes.inviterAmount}>
                  <>
                    {invitationOpportunity.inviter_award_type === 'drawing' ? (
                      <>You Could Earn {invitationOpportunity?.default_inviter_amount}</>
                    ) : (
                      <>You Earn: ${invitationOpportunity?.default_inviter_amount || 0}</>
                    )}
                  </>
                </p>
                {invitationOpportunity.default_invitee_amount && invitationOpportunity.default_invitee_amount !== 0 ? (
                  <p className={classes.inviteeAmount}>
                    Invitee Earns: ${invitationOpportunity?.default_invitee_amount}
                  </p>
                ) : (
                  <></>
                )}
                {invitationOpportunity?.learn_more && (
                  <div style={{ marginTop: '8px' }}>
                    <Button onClick={() => setOpenLearnMoreModal(true)}>Learn More</Button>
                  </div>
                )}
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card.Section title="Share link to opportunity">
                <div style={{ marginBottom: '8px' }}>
                  <p className={classes.inviteeAmount}>
                    Invitations Remaining: {invitationOpportunity?.invitations_remaining || '0/0'}
                  </p>
                </div>
                <TextField value={invitationOpportunity?.invitation_url} onChange={() => null} label="" />
                <div style={{ marginTop: '8px' }}>
                  <Button
                    primary
                    onClick={() => {
                      navigator.clipboard.writeText(invitationOpportunity?.invitation_url);
                    }}
                  >
                    Copy Link
                  </Button>
                  {invitationOpportunity?.sample_message && (
                    <>
                      <span style={{ marginLeft: '8px' }} />
                      <Button primary onClick={() => setOpenSampleMessageModal(true)}>
                        Sample Message
                      </Button>
                    </>
                  )}
                </div>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Card.Section title="Share Invitation">
                <div className={classes.shareNetwork}>
                  <FacebookShareButton
                    url={invitationOpportunity?.invitation_url}
                    quote={invitationOpportunity?.social_media_description}
                    className={classes.shareButton}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </div>
                <div className={classes.shareNetwork}>
                  <EmailShareButton
                    url={invitationOpportunity?.sample_message ? '' : invitationOpportunity?.invitation_url}
                    subject={invitationOpportunity?.social_media_title}
                    body={
                      invitationOpportunity?.sample_message
                        ? invitationOpportunity?.sample_message
                        : invitationOpportunity?.social_media_description
                    }
                    className={classes.shareButton}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>
                <div className={classes.shareNetwork}>
                  <LinkedinShareButton
                    url={invitationOpportunity?.invitation_url}
                    title={invitationOpportunity?.social_media_title}
                    summary={invitationOpportunity?.social_media_description}
                    className={classes.shareButton}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
                <div className={classes.shareNetwork}>
                  <TwitterShareButton
                    url={invitationOpportunity?.invitation_url}
                    title={invitationOpportunity?.social_media_title}
                    className={classes.shareButton}
                  >
                    {/* <TwitterIcon size={32} round /> */}
                    <img alt="X" src={XIcon} style={{ height: '30px' }} />
                  </TwitterShareButton>
                </div>

                {invitationOpportunity?.invitation_url && (
                  <div>
                    <QRCode value={invitationOpportunity?.invitation_url} style={{ marginTop: '5px' }} />
                  </div>
                )}
              </Card.Section>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
};

export default SocialShareCard;
