import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { CaseStudy } from '../model';

const styles = {
  conclusion: {
    fontSize: '16px',
    fontFamily: 'Crimson Text',
    wordWrap: 'break-word' as const,
  },
};

const CaseStudyConclusionSection: React.FC<{ caseStudy: CaseStudy }> = ({ caseStudy }) => {
  return (
    <Grid container style={{ paddingLeft: '5%', paddingRight: '5%' }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography style={styles.conclusion}>
          {/* eslint-disable-next-line */}
          <div dangerouslySetInnerHTML={{ __html: caseStudy?.conclusion_text }} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CaseStudyConclusionSection;
