import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import Page from '../../shared/components/Page';
import NewsletterBanner from '../../../assets/newsletter-banner.png';
import NewsLetterSignupForm from '../components/NewsletterSignupForm';
import Footer from '../../navigation/components/Footer';

const useStyles = makeStyles({
  subheader: {
    fontSize: '20px',
    fontFamily: 'Cooper',
    lineHeight: '28px',
  },
});

const NewsLetterSignup: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div>
      <Page fullWidth>
        <img alt="Newsletter" src={NewsletterBanner} width="100%" />

        <Grid container justifyContent="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <h6 className={classes.subheader}>To be the first notified about new opportunities and </h6>
            <h6 className={classes.subheader}>
              stay fully up to date, be sure to <span style={{ fontWeight: 700 }}>subscribe</span> to our
            </h6>
            <h6 className={classes.subheader}> newsletter today!</h6>
          </Grid>
        </Grid>
        <br />
        <div style={{ padding: '10px 10px 20px 10px', borderBottom: '1px solid rgba(0,0,0,.1)' }}>
          <Grid container columnSpacing={{ xs: 0, sm: 6 }} rowSpacing={6} justifyContent="center" alignItems="center">
            <Grid item lg={6} xs={10}>
              <NewsLetterSignupForm />
            </Grid>
          </Grid>
        </div>
      </Page>
      <Footer />
    </div>
  );
};

export default NewsLetterSignup;
