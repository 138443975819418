import React from 'react';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import Logo from '../../../assets/logo.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: '40px',
      margin: '10px 20px',
      lineHeight: '28px',
      display: 'none',
      [theme.breakpoints.up(360)]: {
        display: 'inline-block',
      },
    },
  }),
);

interface DefaultTopNavigationProps {
  mobileNavigationActive: any;
  setMobileNavigationActive: any;
}

const DefaultTopNavigation: React.FC<DefaultTopNavigationProps> = (props) => {
  const { mobileNavigationActive, setMobileNavigationActive } = props;
  const classes = useStyles();

  return (
    <div className="Polaris-TopBar">
      <div className="">
        <Grid container>
          <Grid item>
            <button
              type="button"
              className="Polaris-TopBar__NavigationIcon"
              aria-label="Toggle menu"
              onClick={() => setMobileNavigationActive(true)}
              style={{ marginTop: 7 }}
            >
              <span className="Polaris-Icon">
                <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                  <path d="M19 11H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" />
                </svg>
              </span>
            </button>
          </Grid>
          <Grid item>
            <a href="/">
              <img alt="logo" src={Logo} className={classes.logo} />
            </a>
          </Grid>

          <Grid item>
            <a
              href="/about"
              className="Polaris-Heading Polaris-TopBar__ContextControl"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
                float: 'left',
                width: 'auto',
              }}
            >
              About
            </a>
            <a
              href="/public-opportunities"
              className="Polaris-Heading Polaris-TopBar__ContextControl"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
                float: 'left',
                width: 'auto',
              }}
            >
              Find Opportunities
            </a>
            <a
              href="/meet-the-team"
              className="Polaris-Heading Polaris-TopBar__ContextControl"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
                float: 'left',
                width: 'auto',
              }}
            >
              Meet the Team
            </a>
            <a
              href="https://us06web.zoom.us/webinar/register/WN_4gDh76pESUmBOFMR2jpqSw#/registration"
              className="Polaris-Heading Polaris-TopBar__ContextControl"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
                float: 'left',
                width: 'auto',
              }}
            >
              Watch Our Webinar
            </a>

            {/* <a
              href="/hiring-info"
              className="Polaris-Heading Polaris-TopBar__ContextControl"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
                float: 'left',
                width: 'auto',
              }}
            >
              We&apos;re Hiring!
            </a> */}

            <a
              href="/frequently-asked-questions"
              className="Polaris-Heading Polaris-TopBar__ContextControl"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
                float: 'left',
                width: 'auto',
              }}
            >
              FAQ
            </a>
          </Grid>
        </Grid>
      </div>
      <div className="Polaris-TopBar__Contents">
        <Grid container justifyContent="flex-end">
          <Grid item>
            <a
              href="/login"
              className="Polaris-Heading"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
              }}
            >
              Log In
            </a>
            <a
              href="/sign-up"
              className="Polaris-Heading"
              style={{
                cursor: 'pointer',
                lineHeight: '60px',
                textDecoration: 'none',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Cooper',
                marginRight: 15,
              }}
            >
              Sign Up
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DefaultTopNavigation;
