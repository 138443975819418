import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ApplicationProcessBackground from '../assets/ApplicationProcessBackground.png';
import ApplicationImage from '../assets/ApplicationImage.png';
import HoverButton from '../../common/HoverButton';

const OpportunityOverview: React.FC = (props) => {
  const history = useHistory();

  return (
    <Grid container spacing={4} style={{ paddingLeft: '12%', paddingRight: '12%' }}>
      <Grid
        item
        xs={12}
        onClick={() => history.push('/redesign/opportunities/1')}
        style={{
          backgroundImage: `url(${ApplicationProcessBackground})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          marginBottom: 10,
          cursor: 'pointer',
          padding: 100,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <img
              src={ApplicationImage}
              alt="opportunity"
              style={{ width: '80%', height: 'auto', objectFit: 'cover' }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 600, margin: 'auto' }}>
              <h2
                style={{
                  color: '#3B4255',
                  fontFamily: 'Crimson Text',
                  fontSize: '28px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                  fontWeight: 600,
                  borderBottom: '1px solid #545D78',
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                Application
              </h2>
              <p
                style={{
                  color: '#545D78',
                  fontFamily: 'Rethink Sans, sans-serif',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                }}
              >
                Please answer a few easy questions in this 5-minute application to see if this opportunity is a good fit
                for you!
              </p>
              <p
                style={{
                  color: '#545D78',
                  fontFamily: 'Rethink Sans, sans-serif',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                }}
              >
                If you apply & this opportunity is a good fit, someone from the study will be in touch soon.
              </p>
              <br />
              <p
                style={{
                  color: '#545D78',
                  fontFamily: 'Rethink Sans, sans-serif',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                }}
              >
                Feel free to share this opportunity if you know any other parents of children between 12 and 27 years of
                age.
              </p>
              <br />
              <p
                style={{
                  color: '#545D78',
                  fontFamily: 'Rethink Sans, sans-serif',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                  marginBottom: 10,
                }}
              >
                *Please note, while we wish we could hear from everyone who applies, an interview spot is never
                guaranteed.
              </p>
              <HoverButton text="Apply Now" />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpportunityOverview;
