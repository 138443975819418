import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { BlogPost } from '../model';
import axios from '../../utils/axios.utils';
import siteWideStyles from '../../styles/siteWideStyles';
import BlogPostForm from '../components/BlogPostForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditBlogPostPage: React.FC = () => {
  const { blogPostId } = useParams<{ blogPostId: string }>();
  const history = useHistory();

  const [formValues, setFormValues] = useState<BlogPost>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const editBlogPost = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`blog_post[${key}]`, value);
    });

    try {
      await axios.put(`blog_posts/${blogPostId}`, combinedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      history.push(`/admin/blog-posts`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  const fetchBlogPost = async () => {
    try {
      const response = await axios.get(`blog_posts/${blogPostId}`);
      setFormValues(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (blogPostId) {
      fetchBlogPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogPostId]);

  return (
    <Grid container style={{ padding: '2%' }} spacing={3}>
      <AdminPageHeader title="Edit Blog Post" backButton={{ label: 'Blog Posts', link: '/admin/blog-posts' }} />
      <Grid item xs={12}>
        <BlogPostForm
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setImageFileData={setImageFileData}
        />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => editBlogPost()} style={siteWideStyles.primaryButton}>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditBlogPostPage;
