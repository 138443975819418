import React from 'react';
import HoverButtonArrow from '../../../assets/redesignAssets/HoverButtonArrow.png';
import useStyles from '../constants/redesignStyles';

interface HoverButtonProps {
  text: string;
}

const HoverButton: React.FC<HoverButtonProps> = ({ text }) => {
  const classes = useStyles();

  // When I include the image transition in the makeStyles, the arrow jerks suddenly instead of transitioning smoothly
  // So we're including a seprate styles object for the image for now
  const styles = {
    image: {
      transition: 'all 0.3s ease',
      marginLeft: '8px',
      width: '30px',
      height: '30px',
    },
    imageHover: {
      marginLeft: '-90%', // Move the arrow to the left edge
    },
  };

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <button
      type="button"
      className={classes.button}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ zIndex: 10000, position: 'relative' }}
    >
      <span className={isHovered ? classes.slideTextHover : classes.slideText}>{text}</span>{' '}
      <img
        src={HoverButtonArrow}
        alt="Hover Button Arrow"
        style={isHovered ? { ...styles.image, ...styles.imageHover } : styles.image}
      />{' '}
    </button>
  );
};

export default HoverButton;
