import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';

const LoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Page fullWidth title="">
      <div style={{ marginTop: 100, marginLeft: 20, marginRight: 20 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <h2 className="Polaris-Header-Title" style={{ fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10 }}>
              Admin Log In
            </h2>
            <br />
            <Grid container spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#E7AC34',
                    height: 40,
                    width: 'auto',
                    fontSize: '16px',
                    borderRadius: '4px',
                    marginTop: 8,
                    textTransform: 'capitalize',
                    fontFamily: 'Cooper',
                    minWidth: '120px',
                  }}
                  onClick={() => loginWithRedirect()}
                >
                  Go to Admin Log In
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default LoginPage;
