import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@shopify/polaris';
import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Project } from '../model';
import { Phase } from '../../projectPhases/model';
import { ProjectPhasesContext } from '../../projectPhases/contexts/ProjectPhasesContext';
import phaseColumns from '../../projectPhases/constants/phaseColumns';

//   {
//     name: 'Name',
//     selector: 'name',
//     sortable: true,
//   },
//   {
//     name: 'Insighter Category',
//     selector: 'insighter_category',
//     sortable: true,
//   },
//   {
//     name: 'Interview Category',
//     selector: 'interview_category',
//     sortable: true,
//   },
//   {
//     name: 'Start Date',
//     selector: 'start_date_human',
//     sortable: true,
//   },
//   {
//     name: 'End Date',
//     selector: 'end_date_human',
//     sortable: true,
//   },
//   {
//     name: 'Status',
//     selector: 'status',
//     sortable: true,
//     cell: ({ status }) => (
//       <div
//         style={{
//           fontWeight: 700,
//           textTransform: 'uppercase',
//           fontSize: 14,
//           color: status === 'active' ? 'green' : 'red',
//         }}
//       >
//         {status}
//       </div>
//     ),
//   },
//   {
//     name: 'Homework',
//     selector: 'homework',
//     sortable: true,
//     cell: ({ homework }) => (
//       <div
//         style={{
//           fontWeight: 700,
//           textTransform: 'uppercase',
//           fontSize: 14,
//         }}
//       >
//         {homework ? 'Yes' : 'No'}
//       </div>
//     ),
//   },
// ];

interface ProjectPhasesProps {
  phases: Phase[];
  project: Project;
}

const ProjectPhases: React.FC<ProjectPhasesProps> = (props) => {
  const { project, phases } = props;
  const history = useHistory();
  const [showArchived, setShowArchived] = React.useState(false);
  const { sortPhases } = useContext(ProjectPhasesContext);
  const [isSorting, setIsSorting] = useState(false);

  const toggleSorting = (): void => {
    if (isSorting) {
      sortPhases($('.rdt_TableBody:first').sortable('serialize'));
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  return (
    <>
      <Card sectioned>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Project Phases</h2>
            </div>
            <div className="Polaris-Stack__Item">
              <div className="Polaris-ButtonGroup">
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  <Button primary={!isSorting} onClick={() => toggleSorting()}>
                    {isSorting ? 'Save Order' : 'Sort Phases'}
                  </Button>
                  <span style={{ marginRight: 7 }} />
                  <Button primary onClick={() => setShowArchived(true)}>
                    Show Archived Phases
                  </Button>
                  <span style={{ marginRight: 7 }} />
                  <Button primary url={`/admin/projects/${project.id}/phases/new`}>
                    Add New Phase
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          pointerOnHover
          onRowClicked={(row: Record<string, string>) => {
            history.push(`/admin/phases/${row.id}`);
          }}
          columns={phaseColumns}
          data={showArchived ? phases : phases.filter((phase) => phase.status !== 'archived')}
          striped
          highlightOnHover
          noHeader
        />
      </Card>
    </>
  );
};

export default ProjectPhases;
