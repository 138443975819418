import React from 'react';
import axios from '../../utils/axios.utils';
import { Project } from '../../projects/model';
import { Phase } from '../../projectPhases/model';
import { Opportunity } from '../../opportunities/model';
import { InvitationOpportunity } from '../../invitationOpportunities/model';

interface DashboardsContextInterface {
  dailyAnalyticsData?: Record<string, any>;
  dashboardLoading?: boolean;
  setDashboardLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  datasets?: Record<string, any>;
  demographicAnalytics?: Record<string, any>;
  conditionChoices?: Choices[];
  fetchConditionChoices?: (params: string) => Promise<void>;
  fetchDrillDownData?: (
    firstDemographic: string,
    firstDemographicSubset: string,
    condition: string,
    secondDemographic: string,
  ) => Promise<void>;
  fetchDailyAnalytics?: (selectedDates: any) => Promise<void>;
  fetchDemographicsAnalytics?: () => Promise<void>;
  fetchPhaseAnalytics?: () => Promise<void>;
  fetchSignupAnalytics?: () => Promise<void>;
  fetchSubsetChoices?: (params: string) => Promise<void>;
  fetchUpcomingChangesDashboard?: () => Promise<void>;
  labels?: string[];
  opportunities?: Opportunity[];
  invitationOpportunities?: InvitationOpportunity[];
  phaseAnalyticsData?: Record<string, any>;
  phases?: Phase[];
  projects?: Project[];
  setDatasets?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setLabels?: React.Dispatch<React.SetStateAction<string[]>>;
  signupAnalyticsData?: Record<string, any>;
  subsetChoices?: Choices[];
  fetchProjectSignups?: (params: string) => Promise<void>;
  projectSignupsLoading?: boolean;
  projectSignups?: Project[];
  statusFilter?: string;
  setStatusFilter?: React.Dispatch<React.SetStateAction<string>>;
  totalRecords?: number;
  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;
  filterParams?: string;
  setFilterParams?: React.Dispatch<React.SetStateAction<string>>;
  selectedDates?: any;
  setSelectedDates?: React.Dispatch<React.SetStateAction<any>>;
  sixMonthDate?: () => Date;
}

interface Choices {
  label: string;
  value: string;
}

const DashboardsContext = React.createContext<DashboardsContextInterface>({});
const DashboardsContextConsumer = DashboardsContext.Consumer;

const DashboardsContextProvider: React.FC = ({ children }) => {
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [phases, setPhases] = React.useState<Phase[]>([]);
  const [opportunities, setOpportunities] = React.useState<Opportunity[]>([]);
  const [invitationOpportunities, setInvitationOpportunities] = React.useState<InvitationOpportunity[]>([]);
  const [dashboardLoading, setDashboardLoading] = React.useState<boolean>(false);
  const [demographicAnalytics, setDemographicAnalytics] = React.useState<Record<string, any>>();
  const [subsetChoices, setSubsetChoices] = React.useState<Choices[]>();
  const [conditionChoices, setConditionsChoices] = React.useState<Choices[]>();
  const [labels, setLabels] = React.useState<string[]>();
  const [datasets, setDatasets] = React.useState<Record<string, any>>();
  const [projectSignupsLoading, setProjectSignupsLoading] = React.useState(false);
  const [projectSignups, setProjectSignups] = React.useState<Project[]>([]);
  const [statusFilter, setStatusFilter] = React.useState('');
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [sortParams, setSortParams] = React.useState<string>('sort=project_timeline_overall_start_date%20desc');
  const [filterParams, setFilterParams] = React.useState<string>('ransack[status_eq]=published');

  const sixMonthDate = () => {
    const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();
    const addMonths = (input, months) => {
      const date = new Date(input);
      date.setDate(1);
      date.setMonth(date.getMonth() + months);
      date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)));
      return date;
    };
    return addMonths(new Date(), -6);
  };

  const [selectedDates, setSelectedDates] = React.useState<Record<string, any>>({
    start: sixMonthDate(),
    end: new Date(),
  });

  const [phaseAnalyticsData, setPhaseAnalyticsData] = React.useState<Record<string, any>>({
    activePhasesCount: '0',
    inactivePhasesCount: '0',
    upcomingPhasesCount: '0',
    activePhases: [{}],
  });

  const [signupAnalyticsData, setSignupAnalyticsData] = React.useState<Record<string, any>>({
    signupsWeekCount: '0',
    signupsMonthCount: '0',
    signupsDayCount: '0',
  });

  const [dailyAnalyticsData, setDailyAnalyticsData] = React.useState<Record<string, any>>({
    countValues: [],
    dateValues: [],
  });

  const fetchDemographicsAnalytics = async () => {
    setDashboardLoading(true);
    const response = await axios.get<string, any>(`demographic_analytics`);
    setDemographicAnalytics({
      gender: response?.data?.gender,
      sexuality: response?.data?.sexuality,
      ethnicity: response?.data?.ethnicity,
      veteran_status: response?.data?.veteran_status,
      transgender_identity: response?.data?.transgender_identity,
    });
    setDashboardLoading(false);
  };

  const fetchUpcomingChangesDashboard = async () => {
    setDashboardLoading(true);
    const response = await axios.get<string, any>(`upcoming_changes`);

    setProjects(response?.data?.result?.projects);
    setPhases(response?.data?.result?.phases);
    setOpportunities(response?.data?.result?.opportunities);
    setInvitationOpportunities(response?.data?.result?.invitation_opportunities);

    setDashboardLoading(false);
  };

  const fetchPhaseAnalytics = async () => {
    setDashboardLoading(true);
    const response = await axios.get<string, any>(`phase_analytics`);

    setPhaseAnalyticsData({
      activePhasesCount: response?.data?.active_phases_count,
      inactivePhasesCount: response?.data?.inactive_phases_count,
      upcomingPhasesCount: response?.data?.upcoming_phases_count,
      activePhases: response?.data?.active_phases,
    });

    setDashboardLoading(false);
  };

  const fetchSignupAnalytics = async () => {
    setDashboardLoading(true);
    const response = await axios.get<string, any>(`signup_analytics`);

    setSignupAnalyticsData({
      signupsWeekCount: response?.data?.signups_week_count,
      signupsMonthCount: response?.data?.signups_month_count,
      signupsDayCount: response?.data?.signups_day_count,
    });

    setDashboardLoading(false);
  };

  const fetchDailyAnalytics = async (selectedDates) => {
    console.log('In Fetch function');
    const response = await axios.get<string, any>(`daily_analytics`, {
      params: { start: selectedDates?.start, end: selectedDates?.end },
    });

    setDailyAnalyticsData({ countValues: response?.data?.count_values, dateValues: response?.data?.date_values });
  };

  const fetchSubsetChoices = async (params: string) => {
    const response = await axios.get<string, any>(`drilldown_analytics/filter`, {
      params: { firstDemographic: params },
    });
    setSubsetChoices(response?.data?.subset_choices);
  };

  const fetchConditionChoices = async (params: string) => {
    const response = await axios.get<string, any>(`drilldown_analytics/filter`, {
      params: { firstDemographic: 'Condition Category', firstDemographicSubset: params },
    });
    setConditionsChoices(response?.data?.condition_choices);
  };

  const fetchDrillDownData = async (
    firstDemographic: string,
    firstDemographicSubset: string,
    condition: string,
    secondDemographic: string,
  ) => {
    setDashboardLoading(true);
    const response = await axios.get<string, any>(`drilldown_analytics`, {
      params: {
        firstDemographic,
        firstDemographicSubset,
        condition,
        secondDemographic,
      },
    });
    setLabels(response?.data?.labels);
    setDatasets(response?.data?.datasets);
    setDashboardLoading(false);
  };

  const fetchProjectSignups = async (params = '') => {
    setProjectSignupsLoading(true);

    const response = await axios.get<string, any>(`project_signups?${params}`);

    setProjectSignups(response?.data?.result);
    setStatusFilter(response?.data?.applied_filters?.status?.value);
    setTotalRecords(response?.data?.total_records);
    setProjectSignupsLoading(false);
  };

  return (
    <DashboardsContext.Provider
      value={{
        conditionChoices,
        dailyAnalyticsData,
        dashboardLoading,
        setDashboardLoading,
        datasets,
        demographicAnalytics,
        fetchConditionChoices,
        fetchDrillDownData,
        fetchDailyAnalytics,
        fetchDemographicsAnalytics,
        fetchPhaseAnalytics,
        fetchSignupAnalytics,
        fetchSubsetChoices,
        fetchUpcomingChangesDashboard,
        labels,
        opportunities,
        invitationOpportunities,
        phaseAnalyticsData,
        phases,
        projects,
        setDatasets,
        setLabels,
        signupAnalyticsData,
        subsetChoices,
        fetchProjectSignups,
        projectSignupsLoading,
        projectSignups,
        statusFilter,
        setStatusFilter,
        totalRecords,
        sortParams,
        setSortParams,
        filterParams,
        setFilterParams,
        selectedDates,
        setSelectedDates,
        sixMonthDate,
      }}
    >
      {children}
    </DashboardsContext.Provider>
  );
};

export { DashboardsContextProvider, DashboardsContextConsumer, DashboardsContext };
