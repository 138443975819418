import React from 'react';
import { Loading, Page, Toast } from '@shopify/polaris';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import OpportunitiesBanner from '../../../assets/opportunities/opportunities-banner.png';
import InvitationOpportunityCard from '../components/InvitationOpportunityCard';
import ClosedOpportunityCard from '../components/ClosedOpportunityCard';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import { InsighterOpportunitiesContext } from '../contexts/InsighterOpportunitiesContext';
import Footer from '../../navigation/components/Footer';

const useStyles = makeStyles({
  subheader: {
    fontSize: '20px',
    fontFamily: 'Cooper',
    lineHeight: '28px',
  },
});

interface OpportunitiesListProps {
  isLoading?: boolean;
}

const InsighterOpportunitiesDetail: React.FunctionComponent<OpportunitiesListProps> = (props) => {
  const { opportunityId } = useParams<Record<string, string>>();
  const { isLoading = false } = props;
  const classes = useStyles();
  const { insighterOpportunity, fetchInsighterOpportunity } = React.useContext(InsighterOpportunitiesContext);
  const [insighterOpportunityFetched, setInsighterOpportunityFetched] = React.useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;
  const [toastActive, setToastActive] = React.useState(false);
  const [available, setAvailable] = React.useState(true);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);

  React.useEffect(() => {
    if (!insighterOpportunityFetched) {
      fetchInsighterOpportunity(opportunityId);
      setInsighterOpportunityFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insighterOpportunityFetched]);

  React.useEffect(() => {
    const deadline = insighterOpportunity?.end_date;
    if (deadline && new Date(deadline) > new Date()) {
      setAvailable(true);
    } else {
      setAvailable(false);
    }
  }, [insighterOpportunity]);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const actualPageMarkup = (
    <div>
      <Page fullWidth>
        <img alt="Upcoming" src={OpportunitiesBanner} width="100%" />
        <Grid container justifyContent="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <h6 className={classes.subheader}>
              As an Insighter, you can get paid to share your story, your experience, & your insights.
            </h6>

            <h6 className={classes.subheader}>We believe that healthcare research should include all voices—</h6>

            <h6 className={classes.subheader}>& that yours should be in the room where it happens.</h6>
            <br />

            <h6 className={classes.subheader}>We’re eager to pull up a chair & chat.</h6>

            <h6 className={classes.subheader}>Join us & apply for an open opportunity!</h6>
          </Grid>
        </Grid>
        <div className="">
          <div className="Polaris-Connected" style={{ margin: '30px auto', width: 800 }}>
            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary" />
          </div>
        </div>
        <Grid container columnSpacing={{ xs: 0, sm: 6 }} rowSpacing={6} justifyContent="center" alignItems="center">
          <Grid item lg={6} xs={10}>
            {insighterOpportunity && available ? (
              <InvitationOpportunityCard opportunity={insighterOpportunity} />
            ) : null}
            {insighterOpportunity && !available ? <ClosedOpportunityCard opportunity={insighterOpportunity} /> : null}
          </Grid>
        </Grid>
        <br />
      </Page>
      <Footer />
    </div>
  );

  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InsighterOpportunitiesDetail;
