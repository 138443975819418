import React from 'react';
// import { geoCentroid } from 'd3-geo';
import { ComposableMap, Geographies, Geography, Marker, Annotation } from 'react-simple-maps';
// import { Lightbulb } from '@mui/icons-material';
// src/assets/homepage/lightbulb.png
import Lightbulb from '../../../../assets/homepage/lightbulb.png';

// Try a version with circles (not COVID red) to represent state coverage.
// Keep lightbulbs for cities

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

// Top 30 states by number of Insighters:
// {nil=>7559,
// "CA"=>2216,
// "TX"=>1879,
// "NY"=>1585,
// "FL"=>1246,
// "PA"=>1094,
// "IL"=>1034,
// "GA"=>872,
// "OH"=>853,
// "NC"=>694,
// "MI"=>650,
// "NJ"=>565,
// "MA"=>525,
// "VA"=>516,
// "MD"=>465,
// "WA"=>420,
// "IN"=>387,
// "MN"=>376,
// "TN"=>367,
// "AZ"=>362,
// "KY"=>338,
// "WI"=>335,
// "MO"=>335,
// "AL"=>320,
// "CO"=>317,
// "SC"=>305,
// "OR"=>260,
// "CT"=>223,
// "LA"=>221,
// "WV"=>170}

const markers = [
  { markerOffset: -20, name: 'Los Angeles, CA', coordinates: [-118.2437, 34.0522] },
  { markerOffset: -20, name: 'Houston, TX', coordinates: [-95.3698, 29.7604] },
  { markerOffset: -20, name: 'New York, NY', coordinates: [-74.006, 40.7128] },
  { markerOffset: -20, name: 'Miami, FL', coordinates: [-80.1918, 25.7617] },
  { markerOffset: -20, name: 'Philadelphia, PA', coordinates: [-75.1652, 39.9526] },
  { markerOffset: -20, name: 'Chicago, IL', coordinates: [-87.6298, 41.8781] },
  { markerOffset: -20, name: 'Atlanta, GA', coordinates: [-84.388, 33.749] },
  { markerOffset: -20, name: 'Columbus, OH', coordinates: [-82.9988, 39.9612] },
  { markerOffset: -20, name: 'Raleigh, NC', coordinates: [-78.6382, 35.7796] },
  { markerOffset: -20, name: 'Detroit, MI', coordinates: [-83.0458, 42.3314] },
  { markerOffset: -20, name: 'Newark, NJ', coordinates: [-74.1724, 40.7357] },
  { markerOffset: -20, name: 'Boston, MA', coordinates: [-71.0589, 42.3601] },
  { markerOffset: -20, name: 'Richmond, VA', coordinates: [-77.436, 37.5407] },
  { markerOffset: -20, name: 'Baltimore, MD', coordinates: [-76.6122, 39.2904] },
  { markerOffset: -20, name: 'Seattle, WA', coordinates: [-122.3321, 47.6062] },
  { markerOffset: -20, name: 'Indianapolis, IN', coordinates: [-86.1581, 39.7684] },
  { markerOffset: -20, name: 'Minneapolis, MN', coordinates: [-93.265, 44.9778] },
  { markerOffset: -20, name: 'Knoxville, TN', coordinates: [-83.9207, 35.9606] },
  { markerOffset: -20, name: 'Phoenix, AZ', coordinates: [-112.074, 33.4484] },
  { markerOffset: -20, name: 'Louisville, KY', coordinates: [-85.7585, 38.2527] },
  { markerOffset: -20, name: 'Milwaukee, WI', coordinates: [-87.9065, 43.0389] },
  { markerOffset: -20, name: 'Saint Louis, MO', coordinates: [-90.1994, 38.627] },
  { markerOffset: -20, name: 'Birmingham, AL', coordinates: [-86.8025, 33.5186] },
  { markerOffset: -20, name: 'Denver, CO', coordinates: [-104.9903, 39.7392] },
  { markerOffset: -20, name: 'Columbia, SC', coordinates: [-81.0348, 34.0007] },
  { markerOffset: -20, name: 'Portland, OR', coordinates: [-122.6765, 45.5231] },
  { markerOffset: -20, name: 'New Haven, CT', coordinates: [-72.9279, 41.3083] },
  { markerOffset: -20, name: 'New Orleans, LA', coordinates: [-90.0715, 29.9511] },
  { markerOffset: -20, name: 'Huntington, WV', coordinates: [-82.4452, 38.4192] },
  { markerOffset: -20, name: 'Anchorage, AK', coordinates: [-149.9003, 61.2181] },
  { markerOffset: -20, name: 'Honolulu, HI', coordinates: [-157.8583, 21.3069] },
  { markerOffset: -20, name: 'Boise, ID', coordinates: [-116.2023, 43.615] },
  { markerOffset: -20, name: 'Las Vegas, NV', coordinates: [-115.1398, 36.1699] },
  { markerOffset: -20, name: 'Salt Lake City, UT', coordinates: [-111.891, 40.7608] },
  { markerOffset: -20, name: 'Billings, MT', coordinates: [-108.5007, 45.7833] },
  { markerOffset: -20, name: 'Cheyenne, WY', coordinates: [-104.8202, 41.14] },
  { markerOffset: -20, name: 'Albuquerque, NM', coordinates: [-106.6504, 35.0844] },
  { markerOffset: -20, name: 'Fargo, ND', coordinates: [-96.7898, 46.8772] },
  { markerOffset: -20, name: 'Sioux Falls, SD', coordinates: [-96.7311, 43.5446] },
  { markerOffset: -20, name: 'Omaha, NE', coordinates: [-95.9404, 41.2565] },
  { markerOffset: -20, name: 'Wichita, KS', coordinates: [-97.3301, 37.6872] },
  { markerOffset: -20, name: 'Oklahoma City, OK', coordinates: [-97.5164, 35.4676] },
  { markerOffset: -20, name: 'Des Moines, IA', coordinates: [-93.6091, 41.5868] },
  { markerOffset: -20, name: 'Little Rock, AR', coordinates: [-92.2896, 34.7465] },
  { markerOffset: -20, name: 'Jackson, MS', coordinates: [-90.1848, 32.2988] },
  { markerOffset: -20, name: 'Portland, ME', coordinates: [-70.2553, 43.6591] },
  { markerOffset: -20, name: 'Burlington, VT', coordinates: [-73.2121, 44.4759] },
];

const USMap: React.FC = () => {
  return (
    <ComposableMap projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({ geographies, outline, borders }) => (
          <>
            <Geography geography={outline} fill="#6CAEC3" />
            <Geography geography={borders} fill="none" stroke="#FFF" />
          </>
        )}
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates} id={name}>
          {/* <circle r={7} fill="navy" stroke="#fff" strokeWidth={2} /> */}
          <image
            href={Lightbulb}
            x={-15}
            y={-15}
            height={30}
            width={30}
            // alt="lightbulb"
          />
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default USMap;
