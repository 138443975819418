import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Instagram, LinkedIn, Facebook } from '@material-ui/icons';
import XIcon from '../../../assets/social/X-icon.png';

// Still need a version of the X Logo without the black background

const socialMediaData = [
  {
    name: 'Facebook',
    icon: Facebook,
    link: 'https://www.facebook.com/askyourtargetmarket',
  },
  {
    name: 'LinkedIn',
    icon: LinkedIn,
    link: 'https://www.linkedin.com/company/ask-your-target-market/',
  },
  {
    name: 'Instagram',
    icon: Instagram,
    link: 'https://www.instagram.com/aytm/',
  },
  {
    name: 'XIcon',
    image: XIcon,
    link: 'https://x.com/aytm',
    isImage: true,
  },
];

const SocialMediaLinks: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {socialMediaData.map((socialMediaLink, index) => (
          <div
            key={index}
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: '#72A295',
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '2%',
            }}
          >
            <a href={socialMediaLink.link} target="_blank" rel="noreferrer" style={{ color: 'white' }}>
              {socialMediaLink.isImage ? (
                <img src={socialMediaLink.image} alt={socialMediaLink.name} style={{ width: '16px', height: '16px' }} />
              ) : (
                <socialMediaLink.icon style={{ fontSize: '16px' }} />
              )}
            </a>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default SocialMediaLinks;
