/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles, createStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import PhoneDetails from '../../../assets/homepage/phone.png';
import PhoneDetailsHover from '../../../assets/homepage/phone-details.png';
import InPersonDetails from '../../../assets/homepage/inperson.png';
import InPersonDetailsHover from '../../../assets/homepage/inperson-details.png';
import OnlineDetails from '../../../assets/homepage/online.png';
import OnlineDetailsHover from '../../../assets/homepage/online-details.png';
import ShareIcon from '../../../assets/homepage/share.png';
import EarnIcon from '../../../assets/homepage/earn.png';
import RegisterIcon from '../../../assets/homepage/register.png';
import FindIcon from '../../../assets/homepage/find.png';
import Header from '../../../assets/homepage/homepage-header.gif';
import Footer from '../../navigation/components/Footer';
import HiringCard from './HiringCard';

const useStyles = makeStyles((theme) =>
  createStyles({
    callToAction: {
      fontSize: '24px',
      marginBottom: 10,
      lineHeight: '24px',
      fontFamily: 'Cooper',

      [theme.breakpoints.up(500)]: {
        fontSize: '44px',
        lineHeight: '44px',
      },
    },

    callToActionContainer: {
      marginLeft: '20px',
      marginRight: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      maxWidth: '900px',
    },

    callToActionImageContainer: {
      maxWidth: '125px',

      [theme.breakpoints.up(500)]: {
        maxWidth: '200px',
      },
    },

    callToActionTextContainer: {
      marginRight: '60px',

      [theme.breakpoints.up(500)]: {
        marginRight: '100px',
      },
    },

    platformDescription: {
      textAlign: 'center',
      fontFamily: 'Cooper',
      fontSize: '20px',
      marginBottom: 20,
      lineHeight: '24px',

      [theme.breakpoints.up(500)]: {
        fontSize: '28px',
        lineHeight: '32px',
      },
    },

    readyToEarnImageContainer: {
      maxWidth: '80px',

      [theme.breakpoints.up(500)]: {
        maxWidth: '150px',
      },
    },

    readyToEarnTextContainer: {
      textAlign: 'center',
      marginLeft: '20px',
      marginRight: '20px',

      [theme.breakpoints.up(500)]: {
        marginLeft: '80px',
        marginRight: '80px',
      },
    },

    topIconContainer: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      height: 150,

      [theme.breakpoints.up(500)]: {
        height: 200,
      },

      [theme.breakpoints.up(2000)]: {
        height: 300,
      },
    },

    topIconTitle: {
      fontSize: '28px',
      marginBottom: 10,
      lineHeight: '32px',
      fontFamily: 'Cooper',

      [theme.breakpoints.up(500)]: {
        fontSize: '40px',
        lineHeight: '50px',
      },
    },
  }),
);

const HomePage: React.FunctionComponent = () => {
  const history = useHistory();
  const [phoneHover, setPhoneHover] = React.useState(false);
  const [personHover, setPersonHover] = React.useState(false);
  const [onlineHover, setOnlineHover] = React.useState(false);
  const classes = useStyles();

  return (
    <div
      className="Polaris-Page Polaris-Page--fullWidth"
      style={{ position: 'relative', minHeight: '100vh', padding: 0 }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={8} md={4}>
          <img
            alt="logo"
            src={window.screen.width < 500 ? Logo : Header}
            style={{ width: '100%', margin: '40px 0 -10px' }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item sm={8} xs={10}>
          <br />
          <Grid container>
            <Grid item xs={6} md={3}>
              <Grid container>
                <Grid item xs={6} className={classes.topIconContainer}>
                  <img alt="register" src={RegisterIcon} style={{ width: '100%', margin: '10px 20px 20px' }} />
                </Grid>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <div>
                    <h2 className={classes.topIconTitle}>SIGN UP</h2>
                    <p style={{ fontSize: '15px', color: '#666', fontFamily: 'Montserrat' }}>
                      Complete your profile and preferences in under 5 minutes
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
              <Grid container>
                <Grid item xs={6} className={classes.topIconContainer}>
                  <img alt="globe" src={FindIcon} style={{ width: '100%', margin: '10px 20px 20px' }} />
                </Grid>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <div>
                    <h2 className={classes.topIconTitle}>FIND</h2>
                    <p style={{ fontSize: '15px', color: '#666', fontFamily: 'Montserrat' }}>
                      Phone, in-person & online opportunities
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
              <Grid container>
                <Grid item xs={6} className={classes.topIconContainer}>
                  <img alt="lightbulb" src={ShareIcon} style={{ width: '100%', margin: '10px 20px 20px' }} />
                </Grid>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <div>
                    <h2 className={classes.topIconTitle}>SHARE</h2>
                    <p style={{ fontSize: '15px', color: '#666', fontFamily: 'Montserrat' }}>
                      Your insights - based on healthcare expertise or interests
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
              <Grid container>
                <Grid item xs={6} className={classes.topIconContainer}>
                  <img alt="dollar" src={EarnIcon} style={{ width: '100%', margin: '10px 20px 20px' }} />
                </Grid>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <div>
                    <h2 className={classes.topIconTitle}>EARN</h2>
                    <p style={{ fontSize: '15px', color: '#666', fontFamily: 'Montserrat' }}>
                      Get paid fast - connect through Tremendous Payment Service
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
        </Grid>
      </Grid>
      <br />
      <Grid container justifyContent="center">
        <Grid item xs={10} md={10} lg={8}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item md={4} xs={12} sm={6}>
              <div
                onMouseOver={() => setPhoneHover(true)}
                onFocus={() => setPhoneHover(true)}
                onMouseOut={() => setPhoneHover(false)}
                onBlur={() => setPhoneHover(false)}
              >
                {phoneHover ? (
                  <img alt="PhoneDetailsHover" style={{ width: '100%' }} src={PhoneDetailsHover} />
                ) : (
                  <img alt="PhoneDetails" style={{ width: '100%' }} src={PhoneDetails} />
                )}
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <div
                onMouseOver={() => setPersonHover(true)}
                onFocus={() => setPersonHover(true)}
                onMouseOut={() => setPersonHover(false)}
                onBlur={() => setPersonHover(false)}
              >
                {personHover ? (
                  <img alt="InPersonDetailsHover" style={{ width: '100%' }} src={InPersonDetailsHover} />
                ) : (
                  <img alt="PhoneDetails" style={{ width: '100%' }} src={InPersonDetails} />
                )}
              </div>
            </Grid>
            <Grid item md={4} xs={12} sm={6}>
              <div
                onMouseOver={() => setOnlineHover(true)}
                onFocus={() => setOnlineHover(true)}
                onMouseOut={() => setOnlineHover(false)}
                onBlur={() => setOnlineHover(false)}
              >
                {onlineHover ? (
                  <img alt="OnlineDetailsHover" style={{ width: '100%' }} src={OnlineDetailsHover} />
                ) : (
                  <img alt="Online" style={{ width: '100%' }} src={OnlineDetails} />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container justifyContent="center">
        <Grid item md={12} xs={10}>
          <h6
            style={{
              textAlign: 'center',
              fontFamily: 'Cooper',
              fontSize: '24px',
              marginBottom: 40,
              lineHeight: '28px',
            }}
          >
            Polls & panels • Quick & in-depth surveys • Individual & group interviews
          </h6>
        </Grid>
        <Grid container justifyContent="center">
          <div className={classes.callToActionContainer}>
            <div className={classes.readyToEarnImageContainer}>
              <img alt="dollar" src={EarnIcon} style={{ width: '100%' }} />
            </div>
            <div className={classes.readyToEarnTextContainer}>
              <h6 className={classes.callToAction}>Ready to earn?</h6>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#E7AC34',
                  height: 40,
                  width: 'auto',
                  fontSize: '16px',
                  borderRadius: '4px',
                  marginTop: 5,
                  textTransform: 'capitalize',
                  fontFamily: 'Cooper',
                  color: 'white',
                }}
                onClick={() => history.push('/sign-up')}
              >
                SIGN UP
              </Button>
            </div>
            <div className={classes.readyToEarnImageContainer}>
              <img alt="register" src={RegisterIcon} style={{ width: '100%' }} />
            </div>
          </div>
        </Grid>

        <Grid item xs={10} md={8}>
          <h6
            className={classes.platformDescription}
            style={{
              marginTop: 40,
            }}
          >
            The Insighters® platform matches you with paid research opportunities that are tailored to your insights.
          </h6>

          <h6 className={classes.platformDescription}>
            We make it easier for you to be at the forefront of the industry. Your healthcare story, experience, &
            insights deserve to be heard—whether you use healthcare a lot or a little.
          </h6>

          <h6 className={classes.platformDescription}>Because diverse research is better research.</h6>
        </Grid>
      </Grid>
      <br />
      <Grid container justifyContent="center">
        <div className={classes.callToActionContainer}>
          <div className={classes.callToActionTextContainer}>
            <h6 className={classes.callToAction}>
              Your story.
              <br />
              Your experience.
              <br />
              Your insights.
            </h6>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#E7AC34',
                height: 40,
                width: 'auto',
                fontSize: '16px',
                borderRadius: '4px',
                marginTop: 5,
                textTransform: 'capitalize',
                fontFamily: 'Cooper',
                color: 'white',
              }}
              onClick={() => history.push('/sign-up')}
            >
              SIGN UP
            </Button>
          </div>
          <div className={classes.callToActionImageContainer}>
            <img alt="lightbulb" src={ShareIcon} style={{ width: '100%' }} />
          </div>
        </div>
      </Grid>
      <br />
      {/* <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        <HiringCard />
      </Grid> */}
      <Footer />
    </div>
  );
};

export default HomePage;
