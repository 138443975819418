import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import HoverButton from '../../common/HoverButton';
import useStyles from '../../constants/redesignStyles';
import SectionOrnament from '../../common/SectionOrnament';
import ValuesBox from '../components/ValuesBox';
import FocusGroup1 from '../../../../assets/redesignAssets/staticImages/FocusGroup1.png';

const DrivingMarketResearchSection: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={6}>
        <img src={FocusGroup1} alt="Focus Group" style={{ width: '100%' }} />
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SectionOrnament text="Insights Into Excellence" />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.h2}>How We&apos;re Driving Healthcare Market Research</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.smallText}>
              The Insighters matches you with paid research opportunities. We are part of an established research
              company, Sommer Consulting, that unlocks your insights to make healthcare more accessible for all.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <HoverButton text="Discover Our Story" />
          </Grid>
          <Grid item xs={12}>
            <ValuesBox />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DrivingMarketResearchSection;
