import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../constants/redesignStyles';

const footerLinkData = [
  {
    name: 'Privacy Policy',
    link: 'https://www.facebook.com/askyourtargetmarket',
  },
  {
    name: 'Cookie Policy',
    link: 'https://www.linkedin.com/company/ask-your-target-market/',
  },
  {
    name: 'Terms of Use',
    link: 'https://www.instagram.com/aytm/',
  },
  {
    name: 'Pay Portal Terms',
    link: 'https://twitter.com/aytm',
  },
];

const FooterLinks: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={2}>
        <p className={classes.footerText}>© 2024 The Insighters</p>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={12} md={5} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {footerLinkData.map((socialMediaLink, index) => (
          <a
            href={socialMediaLink.link}
            target="_blank"
            rel="noreferrer"
            className={classes.footerText}
            key={socialMediaLink.name}
          >
            <p>{socialMediaLink.name}</p>
          </a>
        ))}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <p className={classes.footerText}>Webdesign by Digital Silk</p>
      </Grid>
    </Grid>
  );
};

export default FooterLinks;
