/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField, FormControl, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

interface MultiSelectInputProps {
  label: string;
  choices: any[];
  onChange: (value: any) => void;
  value: string[];
  name?: string;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = (props) => {
  const { label, onChange, value, choices, name } = props;

  return (
    <div>
      {choices?.length > 0 && (
        <FormControl sx={{ width: '100%' }}>
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label className="Polaris-Label__Text" htmlFor="formInput">
                {label}
              </label>
            </div>
          </div>
          <Autocomplete
            multiple
            style={{ backgroundColor: '#ddd' }}
            id="conditions-autocomplete"
            value={value}
            onChange={(_event, newValue) => {
              onChange(newValue.map((value) => (typeof value === 'string' ? value : value.label.toString())));
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  key={index}
                  label={typeof option === 'string' ? option : option.label}
                  style={{ fontSize: '1.2rem' }}
                  {...getTagProps({ index })}
                />
              ))
            }
            options={choices}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <li key={option.value} style={{ fontSize: '1.4rem' }} {...props}>
                {option.label}
              </li>
            )}
            renderInput={(params) => <TextField {...params} name={name} />}
          />
        </FormControl>
      )}
    </div>
  );
};

export default MultiSelectInput;
